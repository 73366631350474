import { useState, useEffect } from "react";
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
import { toast } from 'react-toastify';

function TranslationsRow(props) {
    const [translationText, setTranslationText] = useState(props.translation);

    useEffect(() => {
        setTranslationText(props.translation);
    }, [props.translation]);

    const saveTranslation = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_user/backoffice_updateTranslation', {
                tr_id: parseInt(props.translation_id),
                tr_text: translationText
            });
            const obj = await resp.data;
            if (obj.status) {
                toast.success('Processed');
            }
        } catch (error) {
            toast.error('Error processing request');
            console.error(error);
        }
    }

    return (
        <tr>
            <td className="TCenter">{props.translation_id}</td>
            <td>{props.source}</td>
            <td>{props.lang}</td>
            <td>
                <textarea onChange={(e) => setTranslationText(e.target.value)} value={translationText} />
            </td>
            <td>
                <button className="ActionButton BGreen" onClick={saveTranslation}>
                    Save
                </button>
            </td>
        </tr>
    );
}

export default TranslationsRow;
