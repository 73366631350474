import { useState } from 'react';
import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './Codir.css';
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import CACodir from "../../Components/CACodir/CACodir";
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import RevenuesPartner from "../../Components/RevenuesPartner/RevenuesPartner";
import AverageCpmCodir from "../../Components/AverageCpmCodir/AverageCpmCodir";
import SubscribedSitesCodir from "../../Components/SubscribedSitesCodir/SubscribedSitesCodir";
import TopSiteByPlateformCodir from "../../Components/TopSiteByPlateformCodir/TopSiteByPlateformCodir";
import WorldMapCaCodir from "../../Components/WorldMapCaCodir/WorldMapCaCodir";
import CAChart from "../../Components/CAChart/CAChart";
import ImpChart from "../../Components/ImpChart/ImpChart";
import TopSiteCodir from "../../Components/TopSiteCodir/TopSiteCodir";
import PieChartCountryCodir from "../../Components/PieChartCountryCodir/PieChartCountryCodir";
import PieChartPlateformCodir from "../../Components/PieChartPlateformCodir/PieChartPlateformCodir";
import PieChartPartnerCodir from "../../Components/PieChartPartnerCodir/PieChartPartnerCodir";
import PieChartActiveSitesDandelionCodir from "../../Components/PieChartActiveSitesLangCodir/PieChartActiveSitesLangCodir";
import PieChartCAByLangCodir from "../../Components/PieChartCAByLangCodir/PieChartCAByLangCodir";
import PieChartPlateformNoMarginCodir from "../../Components/PieChartPlateformNoMarginCodir/PieChartPlateformNoMarginCodir";
import PieChartCountryNoMarginCodir from "../../Components/PieChartCountryNoMarginCodir/PieChartCountryNoMarginCodir";

const now = new Date();

function Codir() {
  const pageTab = "codir";
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const [selectedDay1, setSelectedDay1] = useState(firstDayOfMonth);
  const [selectedDay2, setSelectedDay2] = useState(now);
  const [selectedDayB1, setSelectedDayB1] = useState(firstDayOfMonth);
  const [selectedDayB2, setSelectedDayB2] = useState(now);

  const applyFilters = function () {
    setSelectedDay1(selectedDayB1);
    setSelectedDay2(selectedDayB2);
  };

  return (
    <div className="Codir GridTemplate12">
      <AuthComponent />
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LateralNavComponent pageTab={pageTab} />
      <HeadLine childClass="Headline GridCol1-13" title="Statistics of the platform" />

      <div className={"ActiveSites GridCol1-13 GridTemplate12 ShadowContainer BWhite P15"}>
        <div className="GridCol1-7">
          <div className="FlexCol">
            <CACodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
          </div>
        </div>
        <div className="FlexCol GridCol7-13">
          <div className="FlexRow FlexWrap">
            <div className="FlexCol BoDateSelector">
              <button className="ActionButton Alternate BRed">
                <i className="fa-solid fa-calendar-days"></i> Date from
              </button>
              <DayPicker
                mode="single"
                selected={selectedDayB1}
                onSelect={date => {
                  setSelectedDayB1(date);
                }}
              />
            </div>
            <div className="FlexCol BoDateSelector">
              <button className="ActionButton Alternate BRed ML10">
                <i className="fa-solid fa-calendar-days"></i> Date to
              </button>
              <DayPicker
                mode="single"
                selected={selectedDayB2}
                onSelect={date => {
                  setSelectedDayB2(date);
                }}
              />
            </div>
            <div className="FlexCol">
              <button className="ActionButton Alternate BRed ML10" onClick={() => applyFilters()}>
                <i className="fa-solid fa-filter"></i> Apply filters
              </button>
            </div>
          </div>
          <p className="MediumFont "><i className="FWBold TRed fa-solid fa-info"></i> Filter currently set from {format(selectedDay1, 'yyyy-MM-dd')} to {format(selectedDay2, 'yyyy-MM-dd')}</p>
        </div>
      </div>

      <CAChart sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <ImpChart sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <RevenuesPartner sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <AverageCpmCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <WorldMapCaCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <SubscribedSitesCodir />
      <PieChartCountryCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')}/>
      <PieChartCountryNoMarginCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')}/>
      <PieChartPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')}/>
      <PieChartPlateformNoMarginCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')}/>
      <PieChartPartnerCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')}/>
      <PieChartActiveSitesDandelionCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <PieChartCAByLangCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <TopSiteCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} childClass={"GridCol7-13"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"ww"} childClass={"GridCol1-7"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"pt"} childClass={"GridCol7-13"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"it"} childClass={"GridCol1-7"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"de"} childClass={"GridCol7-13"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"es"} childClass={"GridCol1-7"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"jp"} childClass={"GridCol7-13"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"us"} childClass={"GridCol1-7"} />
      <TopSiteByPlateformCodir sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} plateform={"ru"} childClass={"GridCol7-13"} />
    </div>
  );
}

export default Codir;
