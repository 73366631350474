import { ToastContainer } from "react-toastify";
import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import ExportTemplate from "../../Components/ExportTemplate/ExportTemplate"

function Exports() {
    const pageTab = "Exports";

    return (
        <div className={"Exports GridTemplate12"}>
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Exports" />
            <ExportTemplate />
        </div>
    );
}

export default Exports;
