import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { useEffect } from "react";
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
import DailyView from "../../Components/WebsitesTracker/DailyView";
import WeeklyView from "../../Components/WebsitesTracker/WeeklyView";
import "./WebsitesTracker.css";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";

function WebsitesTracker() {
    const pageTab = "websites_tracker";

    useEffect(() => {

        (async () => {
            // setIsLoading(true);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_ping`, {
                    params: {

                    }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                }
            } catch (error) {
                console.error(error);
            }
            // setIsLoading(false);
        })();

    }, []);

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <HeadLine childClass="Headline GridCol1-13" title="Websites Tracker" />

            <LateralNavComponent pageTab={pageTab} />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <DailyView />

            <WeeklyView />
        </div>
    )
}

export default WebsitesTracker;