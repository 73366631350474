import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './PendingApproval.css';
import { useState, useEffect } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import axios from "axios";
import PendingApprovalItemComponent from "../../Components/PendingApprovalItemComponent/PendingApprovalItemComponent";
import { ROOT_DOMAIN_API } from "../../Constant";
import SplashLoadComponent from "../../Components/SplashLoadComponent/SplashLoadComponent";

function PendingApproval() {
    const pageTab = "pending_approval";

    const [pendingApprovalList, setPendingApprovalList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/pendingApprovalBank");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setPendingApprovalList(obj.results);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        })();
    }, []);

    return (
        <div className="ManageSites GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Pending Approval" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                {loading ? (
                    <SplashLoadComponent />
                ) : (
                    <>
                        <h2>Pending banking changes</h2>
                        <div className="FlexCol Gap5">
                            {pendingApprovalList.map((row, key) => (
                                <PendingApprovalItemComponent key={key} id={key} data={row} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default PendingApproval;
