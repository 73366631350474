import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import InformationsFormats from "../../Components/InformationsFormats/InformationsFormats";
import InformationsCategories from "../../Components/InformationsCategories/InformationsCategories";
import "./Informations.css";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"


function Informations() {
    const pageTab = "informations";
    const [sButton, setSButton] = useState(1);
    const [informationsListFormats, setInformationsListFormats] = useState([]);
    const [informationsListCategories, setInformationsListCategories] = useState([]);
    const [informationsListSubcategories, setInformationsListSubcategories] = useState([]);


    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getInformations");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    console.log(obj.results);
                    setInformationsListFormats(obj.results.formats);
                    setInformationsListCategories(obj.results.categories);
                    setInformationsListSubcategories(obj.results.subcategories);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <HeadLine childClass="Headline GridCol1-13" title="Informations" />
            <LateralNavComponent pageTab={pageTab} />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <div className="FlexRow Gap5">
                    <button onClick={() => setSButton(1)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 1 ? "SelectedButton" : "")}>
                        Formats
                    </button>
                    <button onClick={() => setSButton(2)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 2 ? "SelectedButton" : "")}>
                        Categories
                    </button>
                </div>
            </div>
            <InformationsFormats informationsList={informationsListFormats} childClass={sButton === 1 ? '' : 'Hide'} />
            <InformationsCategories informationsList={informationsListCategories} informationsListSubcategories={informationsListSubcategories} childClass={sButton === 2 ? '' : 'Hide'} />
        </div>
    )
}

export default Informations;
