import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./TextMailSetup.css";
import {useParams} from "react-router-dom";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { useNavigate } from "react-router-dom";

function TextMailSetup(props) {
    const [lang, setLang] = useState([]);
    const [textMail, setTextMail] = useState([]);
    const [textMailDesc, setTextMailDesc] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const animatedComponents = makeAnimated();
    const [libelle, setLibelle] = useState("");
    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getAdminList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    if (obj.status && obj.results) {
                        const adminList = [];
                        obj.results.forEach((row) => {
                            adminList.push({'value':row.adm_id, 'label':row.adm_name});
                        });

                        setAdminList(adminList);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getTextMailById?id="+id);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setTextMail(obj.results);
                    setLibelle(obj.results.libelle);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getTextMailDescByTextMailId?id="+id);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results.lang);
                    setTextMailDesc(obj.results.result);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    const updateTextMail = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_updateTextMail", {
                'textMailId':id,
                'textMailLibelle':libelle,
                'textMailDesc': textMailDesc,
            });
            const obj = await resp.data;

            if (obj.status) {
                navigate("/TextMailList");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateObject = function (id, content){
        let a = {...textMailDesc};
        a[id].object = content;
        setTextMailDesc(a);
    }

    const updateBody = function (id, content){
        let a = {...textMailDesc};
        a[id].body = content;
        setTextMailDesc(a);
    }

    const updateOtherReceiver = function (id, content){
        let a = {...textMailDesc};
        a[id].other_receiver = content;
        setTextMailDesc(a);
    }

    const updateReplyTo = function (id, content){
        let a = {...textMailDesc};
        a[id].reply_to = content;
        setTextMailDesc(a);
    }

    const updateAdmin = function (id, content){
        let a = {...textMailDesc};
        a[id].admin = content;

        console.log(content);
        console.log(a[id]);

        setTextMailDesc(a);
    }

    useEffect(() => {
        console.log(textMailDesc);
    }, [textMailDesc]);

    return (
        <div className={"TextMailSetup GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>

            <div>
                Libelle : <input type="text" name={"textMailLibelle"} id={"textMailLibelle"} className={"ActionInput P5"}
                                 value={libelle} onChange={(evt) => setLibelle(evt.target.value)}/>
                <input type="hidden" name={"textMailId"} id={"textMailId"} value={textMail.id}/>
            </div>
            <hr/>

            {lang.map((row) => {
                return <div>
                    <div className={"FWBold Underline MB10"}>{row.lang_name}</div>
                    <div>
                        <div className={"MB10"}>
                            <div>Object :</div>
                            <input type="text" name={"textMailObject[" + row.lang_id + "]"}
                                   className={"textMailObject ActionInput P5"} value={textMailDesc[row.lang_id].object}
                                   onChange={(evt) => updateObject(row.lang_id, evt.target.value)}/>
                        </div>
                        <div className={"MB10"}>
                            <div>Body :</div>
                            <textarea rows="8" name={"textMailBody[" + row.lang_id + "]"}
                                      className={"textMailBody ActionInput P5"} value={textMailDesc[row.lang_id].body}
                                      onChange={(evt) => updateBody(row.lang_id, evt.target.value)}></textarea>
                        </div>
                        <div className={"MB10"}>
                            <div>Destinataires Moneytizer :</div>
                            <Select
                                name={"textMailAdmin[" + row.lang_id + "]"}
                                className={"textMailAdmin"}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                value={textMailDesc[row.lang_id].admin}
                                isMulti
                                options={adminList}
                                onChange={(evt) => updateAdmin(row.lang_id, evt)}
                            />
                        </div>
                        <div className={"MB10"}>
                            <div>Autres destinataires (separate with a ",") :</div>
                            <input type="text" name={"textMailOtherReceiver[" + row.lang_id + "]"}
                                   className={"textMailOtherReceiver ActionInput P5"}
                                   value={textMailDesc[row.lang_id].other_receiver}
                                   onChange={(evt) => updateOtherReceiver(row.lang_id, evt.target.value)}/>
                        </div>
                        <div>
                            <div>Répondre à :</div>
                            <input type="text" name={"textMailReplyTo[" + row.lang_id + "]"}
                                   className={"textMailReplyTo ActionInput P5"}
                                   value={textMailDesc[row.lang_id].reply_to}
                                   onChange={(evt) => updateReplyTo(row.lang_id, evt.target.value)}/>
                        </div>
                    </div>
                    <hr/>
                </div>
            })}

            <button id="edit_text_mail" type="button" onClick={updateTextMail} className="btn btn-md btn-success">
                Validate
            </button>
        </div>
    );
}

export default TextMailSetup;