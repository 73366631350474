import axios from "axios";
import { useEffect, useState } from "react";
import { ROOT_DOMAIN_API } from "../../Constant";
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";

function SitePerfPeriod(props) {
    const [loading, setLoading] = useState(true);
    const [sum, setSum] = useState(0);

    useEffect(() => {
        if (!props.siteID) {
            return;
        }

        setLoading(true);

        (async () => {
            try {
                let data = {
                    params: {
                        site_id: props.siteID,
                        date_from: props.sDate,
                        date_to: props.eDate
                    }
                }
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getSumPerfByPeriod", data);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    const sumValue = obj.results.sum;

                    setSum(sumValue);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.sDate, props.eDate, props.siteID]);

    return (
        <div className={"SitePerfPeriod P15 " + props.childClass}>
            {loading ? (
                <SplashLoadComponent />
            ) : (
                <div className="FlexCol ">
                    <div className="FlexCol AlignCenter JustifyBetween">
                        <h3 className="MR10">Revenues for this period</h3>
                        <div className={`FWBold MediumFont TGrey`}>{sum}€</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SitePerfPeriod;
