import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../Constant";
import "react-toastify/dist/ReactToastify.css";

function NewRevshare(props) {
    const [siteId, setSiteId] = useState("");
    const [revshare, setRevshare] = useState("");

    const handleUpdate = async () => {
        if (!siteId || !revshare) {
            toast.error('Please enter both Site ID and Revshare', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        } else if (revshare < 0 || revshare > 100) {
            toast.error('Revshare must be between 0 and 100', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_updateRevshare`, {
                site_id: siteId,
                revshare: revshare
            });

            if (response.data.status) {
                toast.success('Revshare successfully updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Failed to update revshare', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Failed to update revshare:", error);
            toast.error('Failed to update revshare', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={"SitePackSmart GridCol7-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className='MT0 MB5'>Update Revshare</h2>
            <div className="FlexCol">
                <div className="FlexCol MB5">
                    <label>Site ID</label>
                    <input
                        type="text"
                        className="ActionInput P5"
                        placeholder="Enter only one Site ID"
                        name="site_id"
                        value={siteId}
                        onChange={(e) => setSiteId(e.target.value)}
                    />
                </div>
                <div className="FlexCol MB10">
                    <label>Revshare <span className="SmallFont">(%)</span></label>
                    <input
                        type="number"
                        className="ActionInput P5"
                        name="revshare"
                        value={revshare}
                        max={100}
                        min={0}
                        onChange={(e) => setRevshare(e.target.value)}
                    />
                </div>
                <div className="FlexRow">
                    <button className="ActionButton BGreen" onClick={handleUpdate}>Update</button>
                </div>
            </div>
        </div>
    );
}

export default NewRevshare;
