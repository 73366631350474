import { ToastContainer } from "react-toastify";
import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import ToolsLink from "../../Components/ToolsLink/ToolsLink";
import CAChart from "../../Components/CAChart/CAChart";
import ImpChart from "../../Components/ImpChart/ImpChart";
import QuickTools from "../../Components/QuickTools/QuickTools";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import { format } from 'date-fns'

const now = new Date();

function Home() {
  const pageTab = "home";

  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const selectedDay1 = firstDayOfMonth;
  const selectedDay2 = now;

  return (
    <div className={"Home GridTemplate12"}>
      <AuthComponent />
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LateralNavComponent pageTab={pageTab} />
      <HeadLine childClass="Headline GridCol1-13" title="Welcome on the BO3" />
      <CAChart sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')}/>
      <ImpChart sDate={format(selectedDay1, 'yyyy-MM-dd')} eDate={format(selectedDay2, 'yyyy-MM-dd')} />
      <QuickTools />
      <ToolsLink />
    </div>
  );
}

export default Home;
