import "./SiteOptions.css"
import { useState, useEffect } from "react";
import SiteRgpd from "./SiteOptionsComponents/SiteRgpd";
import SiteAdstxt from "./SiteOptionsComponents/SiteAdstxt";
import axios from "axios";
import { ROOT_DOMAIN_API } from '../../Constant';
import SiteQuality from "./SiteOptionsComponents/SiteQuality";
import SiteBlocklist from "./SiteOptionsComponents/SiteBlocklist";
// import SiteRefresh from "./SiteOptionsComponents/SiteRefresh";
import SiteCapping from "./SiteOptionsComponents/SiteCapping";
import SiteConfigAdserver from "./SiteOptionsComponents/SiteConfigAdserver";
import SiteUserPlatform from "./SiteOptionsComponents/UserPlatform";
import SiteConfiant from "./SiteOptionsComponents/SiteConfiant";
import SiteResponsive from "./SiteOptionsComponents/SiteResponsive";
import SiteVuSimilar from "./SiteOptionsComponents/SiteVuSimilar";
import SitePremiumPublisher from "./SiteOptionsComponents/PremiumPublisher";
import SiteNovideo from "./SiteOptionsComponents/SiteNovideo";
import SiteLangdandelion from "./SiteOptionsComponents/SiteLangdandelion";
import SitePrioSsp from "./SiteOptionsComponents/SitePrioSsp";
import SiteCnil from "./SiteOptionsComponents/SiteCnil";
import UserLogoPro from "./SiteOptionsComponents/UserLogoPro";
import SitePubonmobile from "./SiteOptionsComponents/SitePubonmobile";
import SiteProgressbar from "./SiteOptionsComponents/SiteProgressbar";
import UserPopupAdstxtMoneybox from "./SiteOptionsComponents/UserPopupAdstxtMoneybox";
import SiteInterstitialCloseButtonDelay from "./SiteOptionsComponents/InterstitialCloseButtonDelay";
import SiteInterstitialFrequency from "./SiteOptionsComponents/InterstitialFrequency";
import UserExportAdvanced from "./SiteOptionsComponents/UserExportAdvanced";
import SitePassback from "./SiteOptionsComponents/SitePassback";

function SiteOptions(props) {
    // const [isLoading, setIsLoading] = useState(false);
    const [siteOptions, setSiteOptions] = useState([]);

    useEffect(() => {
        const loadSiteOptions = async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                };
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getSiteOptions", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setSiteOptions(obj.results[0]);
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadSiteOptions();
    }, [props.siteID]);

    // useEffect(() => {
    //     if (isLoading) {
    //         document.body.style.cursor = 'wait';
    //     } else {
    //         document.body.style.cursor = 'default';
    //     }
    // }, [isLoading]);

    return (
        <div className={"SiteOptionsContainer GridCol1-13 ShadowContainer P25 BWhite Width100" + props.childClass}>
            <h2 className="MT0">Options</h2>
            <div className="FlexRow">
                <div className="Width100 ">
                    <SiteRgpd
                        siteRgpd={siteOptions.site_cmp}
                        siteId={props.siteID} />
                    <SiteAdstxt
                        siteAdstxt={siteOptions.site_ads_txt}
                        siteId={props.siteID} />
                    <SiteQuality
                        siteSspPass={siteOptions.site_ssp_pass}
                        siteRaisonQualityId={siteOptions.site_raison_quality_id}
                        siteRaisonQualityOther={siteOptions.site_raison_quality_other}
                        siteId={props.siteID} />
                    <SitePrioSsp
                        sitePrioSsp={siteOptions.site_prio_ssp}
                        siteId={props.siteID} />
                    {/* USELESS BUT WE KEEP IT FOR NOW
                    <SiteRefresh
                        siteRefresh={siteOptions.site_refresh}
                        siteId={props.siteID} /> */}
                    <SiteVuSimilar
                        siteVuSimilar={siteOptions.vu_similar}
                        siteId={props.siteID} />
                    <SiteProgressbar
                        siteProgressbar={siteOptions.max_partner_progression_id}
                        siteId={props.siteID} />
                </div>
                <div className="Width100">
                    <SiteBlocklist
                        siteBlocklist={siteOptions.site_blocklist}
                        siteId={props.siteID} />
                    <SiteConfiant
                        siteConfiant={siteOptions.site_confiant}
                        siteId={props.siteID} />
                    <SiteNovideo
                        siteNovideo={siteOptions.site_novideo}
                        siteId={props.siteID} />
                    <SitePubonmobile
                        sitePubonmobile={siteOptions.site_pub_onmobile}
                        siteId={props.siteID} />
                    <SiteCnil
                        siteCnil={siteOptions.site_cnil}
                        siteId={props.siteID} />
                    <SiteLangdandelion
                        siteLangdandelion={siteOptions.site_lang_dandelion}
                        siteId={props.siteID} />
                </div>
                <div className="Width100">
                    <SiteConfigAdserver
                        siteConfigAdserver={siteOptions.site_adserver}
                        siteId={props.siteID} />
                    <SiteResponsive
                        siteResponsive={siteOptions.site_responsive}
                        siteId={props.siteID} />
                    <SiteInterstitialCloseButtonDelay
                        siteInterstitialCloseButtonDelay={siteOptions.site_interstitial_delay_btn}
                        siteId={props.siteID} />
                    <SiteInterstitialFrequency
                        siteInterstitialFrequency={siteOptions.site_capping_interstitial}
                        siteId={props.siteID} />
                    <SiteCapping
                        siteCapping={siteOptions.site_capping}
                        siteId={props.siteID} />
                    <SitePassback
                        sitePassback={siteOptions.site_passback}
                        siteId={props.siteID} />
                </div>
                <div className="Width100">
                    <SiteUserPlatform
                        siteUserPlatform={siteOptions.user_plateform}
                        userId={siteOptions.site_user_id} />
                    <SitePremiumPublisher
                        sitePremiumPublisher={siteOptions.user_premium}
                        userId={siteOptions.site_user_id} />
                    <UserLogoPro
                        userLogoPro={siteOptions.user_logo_pro}
                        userId={siteOptions.site_user_id} />
                    <UserPopupAdstxtMoneybox
                        userPopupAdstxtMoneybox={siteOptions.user_popup_ads_txt_moneybox}
                        userId={siteOptions.site_user_id} />
                    <UserExportAdvanced
                        userExportAdvanced={siteOptions.user_export_advanced}
                        userId={siteOptions.site_user_id} />
                </div>
            </div>
        </div>
    );
}

export default SiteOptions;
