import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import PassbackWaitingRow from './PassbackWaitingRow/PassbackWaitingRow';

function PassbackWaiting({ childClass, onPassbackAccepted }) {
    const [passbackList, setPassbackList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getWaitingPassbackList`);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setPassbackList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const handleAccept = (bid_id) => {
        setPassbackList(passbackList.filter(item => item.bid_id !== bid_id));
        onPassbackAccepted();
    };

    const handleRefuse = (bid_id) => {
        setPassbackList(passbackList.filter(item => item.bid_id !== bid_id));
    };

    const handleUpdate = (bid_id, newBidText) => {
        setPassbackList(passbackList.map(item => 
            item.bid_id === bid_id ? { ...item, bid_text: newBidText } : item
        ));
    };

    return (
        <div className={`PassbackList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder ${childClass}`}>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Site ID</th>
                        <th className='P5'>URL</th>
                        <th className='P5'>Format</th>
                        <th className='P5'>Passback code</th>
                        <th className='P5'>Date</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {passbackList && passbackList.length > 0 ? (
                        passbackList.map((row, key) => (
                            row && (
                                <PassbackWaitingRow 
                                    key={key} 
                                    row={row} 
                                    onAccept={handleAccept} 
                                    onRefuse={handleRefuse} 
                                    onUpdate={handleUpdate} 
                                />
                            )
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className='P15 TCenter'>No waiting passbacks available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default PassbackWaiting;
