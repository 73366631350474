import './WaitingSitesRow.css';
import { useState, useEffect } from "react";
import axios from "axios";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { ROOT_DOMAIN_API } from '../../../Constant';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

function WaitingSitesRow(props) {
    const [quality, setQuality] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState('');
    const [color, setColor] = useState('green');
    const [selectedTheme, setSelectedTheme] = useState('');
    const [putAsideReason, setPutAsideReason] = useState('');
    const [refuseReason, setRefuseReason] = useState('');
    const [doublon, setDoublon] = useState(false);
    const [siteCountry, setSiteCountry] = useState("default");
    const [isLoading, setIsLoading] = useState(false);
    const [isEditingUrl, setIsEditingUrl] = useState(false);
    const [newUrl, setNewUrl] = useState(props.siteUrl || '');

    const admin_name = localStorage.getItem('global_name');
    const adm_id = localStorage.getItem('adm_id');

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    useEffect(() => {
        const calculateTimeElapsed = () => {
            const siteDate = moment.tz(props.siteDate, 'Europe/Paris');
            const now = moment.tz('Europe/Paris');
            const duration = moment.duration(now.diff(siteDate));

            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();

            let elapsed = 'Waiting since: ';
            if (days > 0) elapsed += `${days}d `;
            if (hours > 0 || days > 0) elapsed += `${hours}h `;
            elapsed += `${minutes}min`;

            let color;
            if (days === 0) {
                if (hours > 8) {
                    color = "TRed";
                } else if (hours >= 2) {
                    color = "TOrange";
                } else {
                    color = "TGreen";
                }
            } else {
                color = "TRed";
            }

            setTimeElapsed(elapsed);
            setColor(color);
        };

        calculateTimeElapsed();
        const interval = setInterval(calculateTimeElapsed, 60000);

        return () => clearInterval(interval);
    }, [props.siteDate]);

    const handleValidate = async () => {
        setIsLoading(true);

        if (!siteCountry) {
            toast.error('Please select a country', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_approveSite', {
                site_id: props.siteId,
                user_id: props.userId,
                admin_name: admin_name,
                theme: selectedTheme,
                ssp_pass: quality,
                doublon: doublon,
                site_country: siteCountry
            });
            if (response.data.status) {
                toast.success('Site validated successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                props.onSiteAction(props.siteId);
            } else {
                throw new Error('Validation failed');
            }

            setIsLoading(false);
        } catch (error) {
            toast.error('Failed to validate site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }

        setIsLoading(false);
    };

    const handlePutAside = async () => {
        setIsLoading(true);

        if (!putAsideReason) {
            toast.error('Please select a reason', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_putAsideWebsite', {
                siteId: props.siteId,
                raisonId: putAsideReason,
                adm_id: adm_id
            });
            if (response.data.status) {
                toast.success('Site put aside successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                props.onSiteAction(props.siteId);
            } else {
                throw new Error('Put aside failed');
            }

            setIsLoading(false);
        } catch (error) {
            toast.error('Failed to put aside site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
        setIsLoading(false);
    };

    const handleRefuse = async () => {
        setIsLoading(true);

        if (!refuseReason) {
            toast.error('Please select a reason', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_refuseWebsite', {
                siteId: props.siteId,
                reason: refuseReason,
                doublon: doublon,
                admin_name: admin_name
            });
            if (response.data.status) {
                toast.success('Site refused successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                props.onSiteAction(props.siteId);
                setIsLoading(false);
            } else {
                throw new Error('Refusal failed');
            }

            setIsLoading(false);
        } catch (error) {
            toast.error('Failed to refuse site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
        setIsLoading(false);

    };

    const handleEditUrl = () => {
        setIsEditingUrl(true);
    };

    const handleSaveUrl = async () => {
        try {
            const response = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateSiteUrl', {
                site_id: props.siteId,
                new_url: newUrl
            });
            if (response.data.status) {
                toast.success('URL updated successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsEditingUrl(false);
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            toast.error('Failed to update URL', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    const getDoublonsStatus = () => {
        if (props.doublonsAccepted === "yes" && props.doublonsFraud === 0) {
            return "Duplicate already accepted";
        } else if (props.doublonsAccepted === "no" && props.doublonsRaisonRefus === "purged") {
            return "Duplicate refused because purged";
        } else if (props.doublonsAccepted === "no" && props.doublonsFraud > 0) {
            return "Duplicate refused fraudster"
        } else if (props.doublonsAccepted === "no" && props.doublonsFraud === 0) {
            return "Duplicate refused"
        } else if (props.doublonsAccepted === "yes" && props.doublonsFraud > 0) {
            return "Duplicate already accepted fraudster"
        } else if (props.doublonsAccepted === "undefined") {
            return "Duplicate in waiting list"
        }
        return false;
    };

    const doublonsStatus = getDoublonsStatus();

    useEffect(() => {
        setSelectedTheme(props.siteThemeId)
        setSiteCountry(props.cacIso2);
    }, [props.siteThemeId, props.cacIso2]);

    return (
        <tr className={"WaitingSiteRow VAlignTop"}>
            <td className={'P10 ' + (props.userModeration !== 1 ? 'BSoftRed ' : '') + (props.userFraud > 0 ? 'BGrey' : '')}>
                <div className='FlexCol'>
                    <div className='FlexRow'>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px " + (props.siteChild === 1 ? "BGreen" : "BPink")}>
                            <p className='M0 LightText'>{props.siteChild === 1 ? "Child" : "Parent"}</p>
                        </div>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px ML10 " + (props.userPremium ? "BYellow" : "Hide")}>
                            <p className='M0 LightText'>{props.userPremium ? "Premium" : ""}</p>
                        </div>
                        <div className={"BorderRad3 FWBold P5 FlexRow AlignCenter JustifyCenter WidthFitContent  ML10 " + (color)}>
                            <p className='M0'>{timeElapsed}</p>
                        </div>
                    </div>

                    <div className='FlexRow'>
                        <div className={"BorderRad3 FlexRow AlignCenter JustifyCenter"}>
                            <img
                                className={"MR10 " + (siteCountry ? '' : 'Hide')}
                                src={"https://flagcdn.com/w80/" + (siteCountry ? siteCountry.toLowerCase() : '') + ".png"}
                                srcSet={"https://flagcdn.com/w80/" + (siteCountry ? siteCountry.toLowerCase() : '') + ".png 2x"}
                                width="40"
                                alt={siteCountry} />
                        </div>
                        <div className={"FlexCol"}>
                            <label htmlFor={'v_country' + props.siteId}>Country</label>
                            <select id={'v_country' + props.siteId}
                                className="ActionSelect P5"
                                onChange={(e) => setSiteCountry(e.target.value)}
                                value={siteCountry}>
                                <option value="default" disabled>Choose a country</option>
                                {props.siteCountryList.map(country => {
                                    return <option key={country.cac_id} value={country.cac_iso2}>{country.cac_name}</option>
                                })}
                            </select>
                        </div>

                    </div>
                    <div className="FlexCol Width50 MT10">
                        <div className="FlexRow AlignCenter">
                            <p className="M0">User: </p>
                            <a target="_blank" rel="noreferrer" href={"https://crm.themoneytizer.com/crm_user/" + props.userId} className="ActionLink ML10">{props.userId}</a>
                        </div>
                        <div className="FlexRow AlignCenter MT10">
                            <p className="M0">Site: </p>
                            <p className="M0 ML10">{props.siteId}</p>
                        </div>
                    </div>
                    <div className='FlexRow AlignCenter'>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter TWhite Height25px BBlue WidthPx25"}
                            data-tooltip-id="user_names"
                            data-tooltip-content="Firstname and Lastname">
                            <Tooltip id="user_names" />
                            <i className="fa-solid fa-person"></i>
                        </div>
                        <div className='ML5'>{props.userFirstname + " " + props.userLastname}</div>
                    </div>
                    <div className="FlexRow AlignCenter MT10">
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter TWhite Height25px BRed WidthPx25"}
                            data-tooltip-id="user_mail_tooltip"
                            data-tooltip-content="User email">
                            <Tooltip id="user_mail_tooltip" />
                            <i className="fa-solid fa-envelope"></i>
                        </div>
                        <p className="M0 ML5">{props.userMailValid ? '✅' : '❌'} {props.userMail}</p>
                    </div>
                    <div className="FlexRow AlignCenter MT10">
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter TWhite Height25px BPurple WidthPx25"}
                            data-tooltip-id="user_share_tooltip"
                            data-tooltip-content="Referral email">
                            <Tooltip id="user_share_tooltip" />
                            <i className="fa-solid fa-share"></i>
                        </div>
                        <p className="M0 ML5">{props.sponsorMail ? props.sponsorMail : "No sponsor"}</p>
                    </div>
                    <div className="FlexRow AlignCenter MT10">
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter TWhite Height25px BOrange WidthPx25"}
                            data-tooltip-id="user_website_stats_tooltip"
                            data-tooltip-content="Active website / Accepted website">
                            <Tooltip id="user_website_stats_tooltip" />
                            <i className="fa-solid fa-chart-simple"></i>
                        </div>
                        <p className="M0 ML5">{props.nbSiteActif + " / " + props.nbSiteAccepted}</p>
                    </div>
                    <div className={"FlexRow AlignCenter MT10 " + (!props.vuSimilar ? 'Hide' : '')}>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter TWhite Height25px BRock WidthPx25"}
                            data-tooltip-id="vu_similar_tooltip"
                            data-tooltip-content="VU Similar">
                            <Tooltip id="vu_similar_tooltip" />
                            <i className="fa-solid fa-users-viewfinder"></i>
                        </div>
                        <p className="M0 ML5">{props.vuSimilar} VU/month</p>
                    </div>
                    <div className={"FlexRow AlignCenter MT10 " + (!props.userProsName ? 'Hide' : '')}>
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter TWhite Height25px BYellow WidthPx25"}
                            data-tooltip-id="user_share_tooltip"
                            data-tooltip-content="Prospector">
                            <Tooltip id="user_share_tooltip" />
                            <i className="fa-solid fa-trophy"></i>
                        </div>
                        <p className="M0 ML5">{props.userProsName}</p>
                    </div>
                </div>
            </td>
            <td className={'P15 ' + (props.userModeration !== 1 ? 'BSoftRed ' : '') + (props.userFraud > 0 ? 'BGrey' : '')}>
                <div className="FlexCol" >
                    <div className='FlexRow AlignCenter'>
                        {isEditingUrl ? (
                            <div className="FlexRow AlignCenter">
                                <input
                                    type="text"
                                    value={newUrl}
                                    onChange={(e) => setNewUrl(e.target.value)}
                                    className="ActionInput P5 Width200"
                                />
                                <button onClick={handleSaveUrl} className="ActionButton BGreen TWhite ML5">
                                    Save
                                </button>
                            </div>
                        ) : (
                            <>
                                <a target="_blank" href={newUrl} className="ActionLink MR10" rel="noreferrer">{newUrl}</a>
                                <i className="fa-solid fa-pencil-alt CursorPointer" onClick={handleEditUrl}></i>
                            </>
                        )}
                        <div className={"BorderRad3 P5 FlexRow AlignCenter JustifyCenter ML10 WidthFitContent TWhite " + (props.doublonsAccepted === "yes" ? "BGreen" : props.doublonsAccepted === "no" ? 'BRed' : doublonsStatus ? 'BOrange' : '')}>
                            <p className='M0 LightText'>
                                {doublonsStatus}
                            </p>
                        </div>
                    </div>
                </div>
            </td>
            <td className={'P15 ' + (props.userModeration !== 1 ? 'BSoftRed ' : '') + (props.userFraud > 0 ? 'BGrey' : '')}>
                <p className="M0 MT5 FWBold">Validate</p>
                <div className="FlexRow MT10">
                    <div className="FlexCol">
                        <label htmlFor={'v_theme' + props.siteId}>Theme</label>
                        <select id={'v_theme' + props.siteId}
                            className="ActionSelect P5"
                            onChange={(e) => setSelectedTheme(e.target.value)}
                            value={selectedTheme}>
                            {props.themesList.map(theme => {
                                return <option key={theme.theme_id} value={theme.theme_id}>{theme.theme_name}</option>
                            })}
                        </select>
                    </div>
                    <div className='FlexCol'>
                        <label htmlFor={'v_quality' + props.siteId}>Quality</label>
                        <input id={'v_quality' + props.siteId}
                            className="ActionInput ML5 Width75 P5"
                            type="number"
                            value={quality}
                            onChange={(e) => setQuality(e.target.value)} />
                    </div>
                    <div className='FlexCol'>
                        <label htmlFor={'v_doublon' + props.siteId}>Doublon</label>
                        <input id={'v_doublon' + props.siteId}
                            className="ActionInput ML5 Width75 P5"
                            type="checkbox"
                            checked={doublon}
                            onChange={(e) => setDoublon(e.target.checked)} />
                    </div>
                </div>
                <button className={"ActionButton BGreen TWhite MT5 " + (isLoading ? "disabled" : "")} onClick={handleValidate} >
                    <i className="fa-solid fa-check MR10" />Validate website
                </button>
                <p className="M0 MT20 FWBold">Put aside</p>
                <div className="FlexCol MT10">
                    <label htmlFor={'p_reason' + props.siteId}>Reason</label>
                    <select id={'p_reason' + props.siteId}
                        className="ActionSelect P5"
                        onChange={(e) => setPutAsideReason(e.target.value)}
                        defaultValue={0}>
                        <option value="0" disabled>Choose a reason</option>
                        {props.raisonPutAsideList.map(raison => {
                            return <option key={raison.id} value={raison.id}>{raison.libelle}</option>
                        })}
                    </select>
                </div>
                <button className={"ActionButton BOrange TWhite MT10 " + (isLoading ? "disabled" : "")} onClick={handlePutAside}>
                    <i className="fa-regular fa-hourglass-half MR10" />
                    Put aside website
                </button>
                <p className="M0 MT20 FWBold">Refuse</p>
                <div className='FlexRow MT10'>
                    <div className="FlexCol">
                        <label htmlFor={'r_reason' + props.siteId}>Reason</label>
                        <select id={'r_reason' + props.siteId}
                            className="ActionSelect P5"
                            onChange={(e) => setRefuseReason(e.target.value)}
                            defaultValue={0}>
                            <option value="0" disabled>Choose a reason</option>
                            {props.reasonRefuseList.map(raison => {
                                return <option key={raison.reason_refuse_id} value={raison.reason_refuse_id}>{raison.reason_refuse_name}</option>
                            })}
                        </select>
                    </div>
                    <div className='FlexCol'>
                        <label htmlFor={'v_doublon' + props.siteId} className='ML10'>Doublon</label>
                        <input id={'v_doublon' + props.siteId}
                            className="ActionInput ML5 Width75 P5"
                            type="checkbox"
                            checked={doublon}
                            onChange={(e) => setDoublon(e.target.checked)} />
                    </div>
                </div>
                <button className={"ActionButton BRed TWhite MT5 " + (isLoading ? "disabled" : "")} onClick={handleRefuse}>
                    <i className="fa-solid fa-xmark MR10" />
                    Refuse website
                </button>
            </td>
        </tr>
    );
}

export default WaitingSitesRow;
