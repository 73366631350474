import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function UserExportAdvanced(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [userExportAdvancedValue, setUserExportAdvancedValue] = useState(); 

    useEffect(() => {
        setUserExportAdvancedValue(props.userExportAdvanced);
    }, [props.userExportAdvanced])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpateUserExportAdvanced(userId, userExportAdvancedValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateUserExportAdvanced", { user_id: userId, user_export_advanced_value: userExportAdvancedValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('User Export Advanced updated to ' + userExportAdvancedValue, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="site_user_export_advanced">User Export Advanced</label>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={userExportAdvancedValue}
                    placeholder="Export Advanced"
                    id="site_user_export_advanced"
                    onChange={(e) => setUserExportAdvancedValue(e.target.value)}
                />
                <button className="ActionButton BGreen" onClick={() => udpateUserExportAdvanced(props.userId, userExportAdvancedValue)}>Save</button>
            </div>
        </div>
    );
}

export default UserExportAdvanced;
