import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SiteInterstitialFrequency(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [interstitialFrequencyValue, setInterstitialFrequencyValue] = useState(); 

    useEffect(() => {
        setInterstitialFrequencyValue(props.siteInterstitialFrequency);
    }, [props.siteInterstitialFrequency])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpateInterstitialFrequency(siteId, interstitialFrequencyValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateInterstitialCapping", { site_id: siteId, interstitial_frequency: interstitialFrequencyValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Interstitial Frequency updated to ' + (interstitialFrequencyValue === "1" ? 'always display' : 'display once out of ' + interstitialFrequencyValue), {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="interstitial_frequency">Interstitial : Frequency</label>
            <div className="LightText TGrey">(1 = always display | X = display once out of X)</div>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={interstitialFrequencyValue}
                    placeholder="Frequency"
                    id="interstitial_frequency"
                    onChange={(e) => setInterstitialFrequencyValue(e.target.value)}
                />
                <button className="ActionButton BGreen" onClick={() => udpateInterstitialFrequency(props.siteId, interstitialFrequencyValue)}>Save</button>
            </div>
        </div>
    );
}

export default SiteInterstitialFrequency;
