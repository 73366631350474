import './SiteTags.css';
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import SiteTagsRow from './SiteTagsRow/SiteTagsRow';
import { ROOT_DOMAIN_API } from '../../Constant';
import { toast } from 'react-toastify';

function SiteTags(props) {
    const [siteTagsList, setSiteTagsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTagsList, setAvailableTagsList] = useState([]);

    const loadSiteTags = useCallback(() => {
        (async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                }
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getTags", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setSiteTagsList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.siteID]);

    // Loads tags that can be generated for this site
    const loadAvailableTags = useCallback(() => {
        (async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                }
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getAvailableTags", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setAvailableTagsList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.siteID]);

    useEffect(() => {
        if (!props.siteID) {
            return;
        }
        loadSiteTags();
        loadAvailableTags();
    }, [props.siteID, loadAvailableTags, loadSiteTags]);

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function deleteTag(tagId) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_deleteTag", { tag_id: tagId })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }
                const updatedTags = siteTagsList.filter(tag => tag.tag_id !== tagId);
                setSiteTagsList(updatedTags);
                toast.success('Tag deleted', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    const addTag = async (adId, tagName) => {
        setIsLoading(true);
        try {
            const response = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_addTag", { site_id: props.siteID, ad_id: adId, ad_name: tagName });
            if (!response.data.status) {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                throw new Error('Failed to add the tag');
            }
            loadSiteTags();
            toast.success('Tag added', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            loadAvailableTags();
            props.triggerRefresh();
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={"SiteTags GridCol7-13 ShadowContainer BWhite P25 Width100 BoxSizeBorder " + props.childClass}>
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className='MT0'>Generated tags</h2>
                <div className="dropdown">
                    <button className='ActionButton BRock'>
                        Add tag <i className="fa-solid fa-chevron-down TWhite"></i>
                    </button>
                    <div className="dropdown-content BorderRad6">
                        {availableTagsList.map((row, key) => (
                            <button key={key} onClick={() => addTag(row.ad_id, row.tag_name)}>
                                <span className='TGrey'>{row.ad_id}</span> - {row.tag_name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className='Width100 SiteTagsContainer'>
                {siteTagsList.length !== 0 ?
                    <table className='Width100'>
                        <thead>
                            <tr className="BRed TWhite">
                                <th className='P5'>Tag Id</th>
                                <th className='P5'>Format</th>
                                <th className='P5'>Date</th>
                                <th className='P5'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {siteTagsList.map((row, key) => (
                                <SiteTagsRow key={key}
                                    tagId={row.tag_id}
                                    tagName={row.tag_name}
                                    tagDate={row.tag_date}
                                    onDeleteTag={() => deleteTag(row.tag_id)}
                                />
                            ))}
                        </tbody>
                    </table>
                    :
                    <div className="TCenter Width100">
                        <h5>No generated tags yet</h5>
                        <p>You can generate one below or directly in the Moneybox of this user.</p>
                    </div>
                }
            </div>
        </div>
    );
}

export default SiteTags;
