import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SiteQuality(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [qualityValue, setQualityValue] = useState(props.siteSspPass || '');
    const [raisonQuality, setRaisonQuality] = useState(null);
    const [showCustomInput, setShowCustomInput] = useState(false);
    const [customReason, setCustomReason] = useState(props.siteRaisonQualityOther || null);
    const [siteRaisonQuality, setSiteRaisonQuality] = useState(props.siteRaisonQualityId || '');

    useEffect(() => {
        axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getRaisonQuality")
        .then(response => {
            setRaisonQuality(response.data.results);
        })
        .catch(error => {
            console.error("Failed to fetch quality reasons:", error);
        })
    }, []);

    useEffect(() => {
        setQualityValue(props.siteSspPass || '');
        setSiteRaisonQuality(props.siteRaisonQualityId || '');
        setCustomReason(props.siteRaisonQualityOther || null);
    }, [props.siteSspPass, props.siteRaisonQualityId, props.siteRaisonQualityOther])

    useEffect(() => {
        document.body.style.cursor = isLoading ? 'wait' : 'default';
    }, [isLoading]);

    function updateQuality(siteId, qualityValue, siteRaisonQuality, customReason) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateQuality", { site_id: siteId, site_quality_value: qualityValue, site_raison_quality: siteRaisonQuality, custom_quality_value: customReason })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Quality updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    function handleSelectChange(e) {
        const selectedValue = e.target.value;
        setShowCustomInput(selectedValue === "9");
        setSiteRaisonQuality(selectedValue);
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="site_quality">Quality</label>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={qualityValue}
                    placeholder="Quality"
                    id="site_quality"
                    onChange={(e) => setQualityValue(e.target.value)}
                />
                <select
                    className="ActionInput MR10 P10"
                    id="quality_reason_select"
                    onChange={handleSelectChange}
                    value={siteRaisonQuality}>
                    <option value="" disabled>Select reason</option>
                    {raisonQuality && raisonQuality.map((raison) => (
                        <option key={raison.id} value={raison.id}>{raison.name}</option>
                    ))}
                </select>
                <button
                    className="ActionButton BGreen"
                    onClick={() => updateQuality(props.siteId, qualityValue, siteRaisonQuality, customReason)}
                >Save</button>
            </div>
            {showCustomInput && (
                    <input
                        type="text"
                        placeholder="Enter custom reason"
                        value={customReason}
                        onChange={(e) => setCustomReason(e.target.value)}
                        className="ActionInput P10 Width30 MR10 MT10"
                    />
                )}
            {isLoading && <p>Loading...</p>}
        </div>
    );
}

export default SiteQuality;
