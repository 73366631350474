const plateformColors = {
    "ww": "#0072bb",
    "it": "#026633",
    "es": "#F1BF00",
    "de": "#555555",
    "ru": "#B0E0E6",
    "jp": "#BC002D",
    "pt": "#009739",
    "us": "#1E90FF",
    "pl": "#DA70D6",
    "en": "#DDA0DD",
};

export default plateformColors;
