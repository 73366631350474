import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./RevenuesPartner.css"
import { BarLoader } from "react-spinners";

function RevenuesPartner(props) {
    const [partnersData, setPartnersData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getRevenuesPartner`, {
                    params: {
                        date_from: props.sDate,
                        date_to: props.eDate,
                    }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setPartnersData(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.sDate, props.eDate]);

    return (
        <div className="RevenuesPartner ShadowContainer BWhite GridCol1-7 P15">
            <h2 className="M0 MT5 MB5">Performance Partners</h2>
            <div className="RevenuesPartnerContainer">
                <table className="Width100">
                    <thead>
                        <tr className="BRed TWhite">
                            <th>Partner</th>
                            <th>Revenues</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partnersData.length ? (
                            partnersData.map((partner, index) => (
                                <tr key={index}>
                                    <td className="P5">{partner.partner_name_display}</td>
                                    <td className="P5 TCenter">{parseFloat(partner.revenues)} €</td>
                                </tr>
                            ))
                        ) : <></>}
                    </tbody>
                </table>
                {!partnersData.length ? <div className="FlexRow AlignCenter JustifyCenter MT20"><BarLoader height={8} width={250} color="#f41127" /></div> : <></>}
            </div>
        </div>
    );
}

export default RevenuesPartner;
