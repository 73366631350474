import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from '../../../Constant';

function MagicTagListRow({ row, onRemove }) {
    const handleResolve = async () => {
        try {
            const response = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_setMagicTagResolved", {
                site_id: row.site_id
            });
            if (response.data.status) {
                toast.success('Magic Tag validated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onRemove(row.site_id);
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    const handleRefuse = async () => {
        try {
            const response = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_setMagicTagRefused", {
                site_id: row.site_id
            });
            if (response.data.status) {
                toast.success('Magic Tag refused', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onRemove(row.site_id);
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    return (
        <tr>
            <td className='P15'>{row.site_id}</td>
            <td className='P15'>
                {row.site_url}
                {row.raison_refus === 'adcash' && (
                    <span className='TWhite BOrange P5 SmallFont BorderRad3 ML10'>Adcash</span>
                )}
            </td>
            <td className='P15 TCenter FlexRow JustifyCenter'>
                <Link to={`/options/${row.site_id}`} className="ActionButton BOrange TWhite">
                    <i className="fa-solid fa-gear"></i>
                </Link>
                <Link to={`/magicTagSetup/${row.site_id}`} className="ActionButton ML5 BBlue TWhite">
                    <i className="fa-solid fa-wand-magic-sparkles"></i>
                </Link>
                <button className='ActionButton BGreen TWhite ML5 WidthPx35' onClick={handleResolve}>
                    <i className="fa-solid fa-check"></i>
                </button>
                <button className='ActionButton BRed TWhite ML5 WidthPx35' onClick={handleRefuse}>
                    <i className="fa-solid fa-times"></i>
                </button>
            </td>
        </tr>
    );
}

export default MagicTagListRow;
