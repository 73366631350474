import { useState } from "react";
import { Link } from "react-router-dom";
import "./QuickTools.css";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { toast } from "react-toastify";

function QuickTools() {
    const [siteIDOptions, setSiteIDOptions] = useState("");
    const [siteIDStats, setSiteIDStats] = useState("");
    const [siteIDMoneybox, setSiteIDMoneybox] = useState("");
    const [moneyboxSubdomain, setMoneyboxSubdomain] = useState("www");
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");

    const handleInputChangeMoneybox = (e) => {
        setSiteIDMoneybox(e.target.value);
    };

    const handleInputChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const handleInputChangeUserId = (e) => {
        setUserId(e.target.value);
    };

    const resetFields = () => {
        setSiteIDMoneybox("");
        setEmail("");
        setUserId("");
    };

    const handleMoneyboxAccess = async (siteId) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxToken`, {
                params: { site_id: siteId },
            });

            if (response.data.status && response.data.results) {
                let url = "https://" + moneyboxSubdomain + ".themoneytizer.com/new_manager/login&site_id=" + siteId + "&tbm_hash=" + response.data.results.tbm_hash;
                window.open(url.toString(), '_blank');
                resetFields();
            } else {
                toast.error('Failed to connect to Moneybox', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const getSiteIdForEmail = async () => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteIdForEmail`, {
                params: { email: email },
            });

            if (response.data.status && response.data.results) {
                const siteId = response.data.results;
                handleMoneyboxAccess(siteId);
            } else {
                toast.error('Failed to connect to Moneybox', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error connecting to Moneybox:", error);
            toast.error('Error connecting to Moneybox', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const getSiteIdForUserId = async () => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteIdForUserId`, {
                params: { user_id: userId },
            });

            if (response.data.status && response.data.results) {
                const siteId = response.data.results;
                handleMoneyboxAccess(siteId);
            } else {
                toast.error('Failed to connect to Moneybox', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error connecting to Moneybox:", error);
            toast.error('Error connecting to Moneybox', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleMoneyboxEmailSubmit = () => {
        getSiteIdForEmail();
    };

    const handleMoneyboxUserIdSubmit = () => {
        getSiteIdForUserId();
    };

    return (
        <div className={"QuickTools GridCol1-13 ShadowContainer BWhite P15"}>
            <h2 className="M0 MT5 MB5">Quick Tools</h2>
            <div className="FlexRow">
                <div className="FlexCol">
                    <label htmlFor="moneybox_e_quick_access">Email to Moneybox</label>
                    <div className="FlexRow">
                        <input
                            className="ActionInput"
                            placeholder="Email"
                            id="moneybox_e_quick_access"
                            type="text"
                            value={email}
                            onChange={handleInputChangeEmail} />
                        <div
                            className="ActionButton ML5 BGreen TWhite"
                            onClick={handleMoneyboxEmailSubmit}
                        >
                            <i className="fa-solid fa-piggy-bank"></i>
                        </div>
                    </div>
                </div>
                <div className="FlexCol ML20">
                    <label htmlFor="moneybox_u_quick_access">User ID to Moneybox</label>
                    <div className="FlexRow">
                        <input
                            className="ActionInput"
                            placeholder="User ID"
                            id="moneybox_u_quick_access"
                            type="text"
                            value={userId}
                            onChange={handleInputChangeUserId} />
                        <div
                            className="ActionButton ML5 BGreen TWhite"
                            onClick={handleMoneyboxUserIdSubmit}
                        >
                            <i className="fa-solid fa-piggy-bank"></i>
                        </div>
                    </div>
                </div>
                <div className="FlexCol ML20">
                    <label htmlFor="moneybox_s_quick_access">Site ID to Moneybox</label>
                    <div className="FlexRow">
                        <input
                            className="ActionInput"
                            placeholder="Site ID"
                            id="moneybox_s_quick_access"
                            type="text"
                            value={siteIDMoneybox}
                            onChange={handleInputChangeMoneybox} />
                        <div
                            className="ActionButton ML5 BGreen TWhite"
                            onClick={() => handleMoneyboxAccess(siteIDMoneybox)}
                        >
                            <i className="fa-solid fa-piggy-bank"></i>
                        </div>
                        <select className="ML10" onChange={(evt) => { setMoneyboxSubdomain(evt.target.value) }} value={moneyboxSubdomain}>
                            <option value="www">FR</option>
                            <option value="it">IT</option>
                            <option value="de">DE</option>
                            <option value="jp">JP</option>
                            <option value="us">US</option>
                            <option value="ru">RU</option>
                            <option value="pt">PT</option>
                            <option value="es">ES</option>
                        </select>
                    </div>
                </div>
                <div className="FlexCol ML20">
                    <label htmlFor="options_s_quick_access">Site ID to Options</label>
                    <div className="FlexRow">
                        <input
                            className="ActionInput"
                            onChange={(e) => setSiteIDOptions(e.target.value)}
                            placeholder="Site ID"
                            id="options_s_quick_access"
                            type="text"
                            value={siteIDOptions}
                        />
                        <Link to={`/options/${siteIDOptions}`} className="ActionButton ML5 BOrange TWhite">
                            <i className="fa-solid fa-gear"></i>
                        </Link>
                    </div>
                </div>
                <div className="FlexCol ML20">
                    <label htmlFor="statistics_s_quick_access">Site ID to Statistics</label>
                    <div className="FlexRow">
                        <input
                            className="ActionInput"
                            onChange={(e) => setSiteIDStats(e.target.value)}
                            placeholder="Site ID"
                            id="statistics_s_quick_access"
                            type="text"
                            value={siteIDStats}
                        />
                        <Link to={`/statistics/${siteIDStats}`} className="ActionButton ML5 BBlue TWhite">
                            <i className="fa-solid fa-chart-simple"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuickTools;
