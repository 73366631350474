import axios from "axios";
import { useEffect, useState } from "react";
import { ROOT_DOMAIN_API } from "../../Constant";

function ToolsLink() {
    const [toolsList, setToolsList] = useState([]);

    useEffect(()=>{
        (async () => {
          try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/bo_user/toolsLink");
            const obj = await resp.data;
            if(obj.status&&obj.results){
              setToolsList(obj.results);
            }
          } catch (error) {
              console.error(error);
          }
        })();
    },[])
  return (
    <div className={"ToolsLink GridCol1-13 ShadowContainer BWhite P15"}>
        <h2 className="M0 MT5 MB5">Tools link</h2>
        <ul>
            {toolsList.map((row, key) => {
                return (
                    <li key={key}>
                        <h4 className="M0 MT10">{row.tl_title}</h4>
                        <a className="ActionLink" target="_blank" rel="noreferrer" href={row.tl_link}>{row.tl_link}</a>
                    </li>
                )
            })}
        </ul>
    </div>
  );
}

export default ToolsLink;
