import "./SiteLabel.css"
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from "../../Constant";

function SiteLabel(props) {
    const [inputValue, setInputValue] = useState('');
    const [labels, setLabels] = useState([]);
    const [initialLabels, setInitialLabels] = useState([]);
    const [isAllowedToSave, setIsAllowedToSave] = useState(false);

    useEffect(() => {
        const fetchLabels = async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                };
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/bo_user/labelList", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    const labelNames = obj.results.map(item => item.label_name);
                    setLabels(labelNames);
                    setInitialLabels(labelNames);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (props.siteID) {
            fetchLabels();
        }
    }, [props.siteID]);

    useEffect(() => {
        const hasChanges = labels.length !== initialLabels.length ||
            labels.some((label, index) => label !== initialLabels[index]);
        setIsAllowedToSave(hasChanges);
    }, [labels, initialLabels]);

    const saveLabels = async () => {
        const labelsToAdd = labels.filter(label => !initialLabels.includes(label));
        const labelsToRemove = initialLabels.filter(label => !labels.includes(label));

        try {
            const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/bo_user/labelList',
                { label_to_add: labelsToAdd, label_to_remove: labelsToRemove, site_id: props.siteID });
            const obj = await resp.data;
            if (obj.status) {
                setInitialLabels(labels);
                setIsAllowedToSave(false);
                toast.success('Label(s) successfully updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong, please try again.');
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        const trimmedInput = inputValue.trim();
        if (event.key === 'Enter' && trimmedInput && !labels.includes(trimmedInput)) {
            setLabels([...labels, trimmedInput]);
            setInputValue('');
            setIsAllowedToSave(true);
        }
    };

    const handleRemoveLabel = (labelToRemove) => {
        setLabels(labels.filter(label => label !== labelToRemove));
        setIsAllowedToSave(true);
    };

    return (
        <div className={"SiteLabel GridCol7-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className="MT0">Labels</h2>
            <div className="MB10">
                <input
                    className="ActionInput P10 Width35 MR10"
                    type="text"
                    value={inputValue}
                    placeholder="Enter Label"
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                />

                <p className={(inputValue ? "" : "Hide")}>Press Enter to add "{inputValue}"</p>
            </div>

            <div className="FlexRow Gap5 WrapContent">

                {labels.length !== 0 ? labels.map(label => (
                    <div key={label} className="Width100 BorderRad6 Gap10 BSoftBlue P5 FlexRow AlignCenter JustifyCenter WidthFitContent TGrey Height25px MR5">
                        <div>{label}</div>
                        <button className="ActionButton BRed TWhite" onClick={() => handleRemoveLabel(label)}><i class="fa-solid fa-xmark"></i></button>
                    </div>
                )) :
                    <div className="TCenter Width100">
                        <h5>No label yet</h5>
                        <p>Add a new label by entering it in the input box and pressing Enter.</p>
                    </div>
                }
            </div>

            <div className={"MT10 " + (isAllowedToSave ? "" : "Hide")}>
                <button className={"ActionButton TWhite BGreen "} onClick={() => saveLabels(labels)}><i class="fa-solid fa-floppy-disk"></i> Save Label(s)</button>
            </div>
        </div>
    );
}

export default SiteLabel;
