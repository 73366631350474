import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";

function WeeklyView(props) {
    const adm_id = localStorage.getItem('adm_id');
    const [websitesList, setWebsitesList] = useState([]);
    const [revenuesData, setRevenuesData] = useState({});
    const [nbSspConnected, setNbSspConnected] = useState({});
    const [weeks, setWeeks] = useState([]);
    const [newSiteId, setNewSiteId] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const fetchWebsitesToTrack = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getWebsitesToTrackWeekly`, {
                params: { adm_id: adm_id }
            });
            const obj = response.data;
            if (obj.status && obj.results) {
                setWebsitesList(obj.results);
                const revenues = {};
                const sspData = {};
                let allDates = [];

                const sitePromises = obj.results.map(site => {
                    return Promise.all([
                        axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteEvolutionWeekly`, {
                            params: { site_id: site.site_id, adm_id: adm_id }
                        }),
                        axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteNbSsp`, {
                            params: { site_id: site.site_id }
                        })
                    ]);
                });

                const siteResponses = await Promise.all(sitePromises);

                siteResponses.forEach(([siteData, sspResponse], index) => {
                    const site = obj.results[index];

                    if (siteData.data.status && siteData.data.results.length > 0) {
                        const siteResults = siteData.data.results.map(result => ({
                            ...result,
                            perf_date: new Date(result.perf_date)
                        }));

                        allDates = allDates.concat(siteResults.map(result => result.perf_date));

                        revenues[site.site_id] = {
                            site_url: siteResults[0].site_url,
                            data: siteResults.reduce((acc, row) => {
                                const weekIndex = Math.floor((Math.max(...allDates) - row.perf_date) / (7 * 24 * 60 * 60 * 1000));
                                if (!acc[weekIndex]) {
                                    acc[weekIndex] = { revenue: 0 };
                                }
                                acc[weekIndex].revenue += parseFloat(row.perf_revenues);
                                return acc;
                            }, {})
                        };
                    }

                    if (sspResponse.data.status) {
                        sspData[site.site_id] = sspResponse.data.results;
                    }
                });

                const sortedWeeks = Array.from(new Set(allDates.map(date => Math.floor((Math.max(...allDates) - date) / (7 * 24 * 60 * 60 * 1000)))))
                    .sort((a, b) => b - a)
                    .slice(0, 4);

                setRevenuesData(revenues);
                setNbSspConnected(sspData);
                setWeeks(sortedWeeks);
            }
        } catch (error) {
            console.error("Error fetching websites to track:", error);
        } finally {
            setIsLoading(false);
        }
    }, [adm_id]);

    useEffect(() => {
        fetchWebsitesToTrack();
    }, [adm_id, fetchWebsitesToTrack]);

    const handleAddSite = async () => {
        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_addWebsiteTracking`, {
                site_id: newSiteId,
                period: 'weekly',
                adm_id: adm_id
            });

            if (response.data.status) {
                toast.success('Site added successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setNewSiteId("");
                fetchWebsitesToTrack();
            } else {
                toast.error('Failed to add site', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleInputChange = (e) => {
        setNewSiteId(e.target.value);
    };

    const deleteWebsiteTracking = async (siteId) => {
        try {
            const resp = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_deleteWebsiteTracking`, {
                site_id: siteId,
                adm_id: adm_id
            });

            const obj = resp.data;
            if (obj.status) {
                setWebsitesList(prevWebsitesList => prevWebsitesList.filter(site => site.site_id !== siteId));
                toast.success('Tracking removed for this site', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error deleting website tracking:", error);
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const calculateChange = (current, previous) => {
        if (!previous) return null;
        const change = ((current - previous) / previous) * 100;
        return change.toFixed(1);
    };

    const renderRevenueRow = (siteId, weekIndex, prevWeekIndex) => {
        const data = revenuesData[siteId]?.data[weekIndex] || null;
        const prevData = revenuesData[siteId]?.data[prevWeekIndex] || null;

        if (!data) {
            return <td className='P15'>N/A</td>;
        }

        const revenueChange = calculateChange(data.revenue, prevData?.revenue);

        return (
            <td className='P15'>
                <span className="revenue">
                    {data.revenue.toFixed(2)}€
                    {revenueChange && (
                        <span className={revenueChange >= 0 ? "TGreen" : "TRed"}>
                            ({revenueChange}%)
                        </span>
                    )}
                </span>
            </td>
        );
    };

    return (
        <div className={"WeeklyView GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className="MT0">Weekly View</h2>
            <div className="FlexRow MB10">
                <input
                    type="text"
                    value={newSiteId}
                    onChange={handleInputChange}
                    placeholder="Enter site ID"
                    className="P10 MR10 ActionInput"
                />
                <button onClick={handleAddSite} className="P15 BBlue TWhite ActionButton">
                    Add to list
                </button>
            </div>
            {isLoading ? (
                <SplashLoadComponent />
            ) : (
                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>Site URL</th>
                            <th className="P5">SSP Connected</th>
                            <th className='P5'>Revenues 4 weeks ago</th>
                            <th className='P5'>Revenues 3 weeks ago</th>
                            <th className='P5'>Revenues last week</th>
                            <th className='P5'>This week</th>
                            <th className="P5">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {websitesList.map((site, key) => (
                            <tr key={key}>
                                <td className='P15 FlexRow JustifyBetween AlignBaseline'>
                                    {revenuesData[site.site_id]?.site_url || 'N/A'}
                                    {revenuesData[site.site_id] && (
                                        <Link className="BOrange TWhite ActionButton" to={"/options/" + site.site_id}><i className="fa-solid fa-gear"></i></Link>
                                    )}
                                </td>
                                <td className='P15 TCenter'>{nbSspConnected[site.site_id] || "N/A"}</td>
                                {weeks.map((week, index) => renderRevenueRow(site.site_id, week, weeks[index + 1]))}
                                <td className='P15 TCenter'>
                                    <button className='ActionButton BRed TWhite' onClick={() => deleteWebsiteTracking(site.site_id)}><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default WeeklyView;
