import axios from "axios";
import "./FormatPerfChart.css";
import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, BarElement } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from "../../Constant";
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, BarElement);

function FormatPerfChart(props) {
    const [graphData, setGraphData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chartType, setChartType] = useState("Bar");

    useEffect(() => {
        if (!props.format) {
            return;
        }

        setLoading(true);

        (async () => {
            try {
                const data = {
                    params: {
                        site_id: props.siteID,
                        s_date: props.sDate,
                        e_date: props.eDate,
                        format: props.format
                    }
                };
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/revenuesBySiteDateFormat`, data);
                const obj = resp.data;
                if (obj.status && obj.results) {
                    const labels = obj.results.map(row => row.sp_date);
                    const perfData = obj.results.map(row => row.perf);

                    const datasets = [{
                        label: "Performance",
                        lineTension: 0.3,
                        data: perfData,
                        borderColor: "#3498db",
                        backgroundColor: "#3498db",
                        yAxisID: 'y',
                    }];

                    setGraphData({ labels, datasets });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.format, props.sDate, props.eDate, props.siteID]);

    const toggleChartType = () => {
        setChartType(prevType => (prevType === "Bar" ? "Line" : "Bar"));
    };

    return (
        <div className="FormatPerfChart Width50 ShadowContainer BWhite P15">
            <div className="FlexRow JustifyBetween AlignCenter">
                <h2 className="M0 MT5 MB5">Revenues {props.formatName}</h2>
                <button onClick={toggleChartType} className="ActionButton BRock TWhite HeightFitContent">
                    {chartType === "Bar" ? <i className="fa-solid fa-chart-line"></i> : <i className="fa-solid fa-chart-column"></i>}
                </button>
            </div>
            <div className="InnerFormatPerfChart">
                {loading ? (
                    <SplashLoadComponent />
                ) : (
                    graphData && (
                        chartType === "Bar" ? (
                            <Bar
                                data={graphData}
                                height={300}
                                options={{ maintainAspectRatio: false, responsive: true }}
                            />
                        ) : (
                            <Line
                                data={graphData}
                                height={300}
                                options={{ maintainAspectRatio: false, responsive: true }}
                            />
                        )
                    )
                )}
            </div>
        </div>
    );
}

export default FormatPerfChart;
