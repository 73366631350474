import './AcceptedSites.css';
import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import AcceptedSitesRow from './AcceptedSitesRow/AcceptedSitesRow';
import ReactPaginate from 'react-paginate';
import { ROOT_DOMAIN_API } from '../../Constant';

function AcceptedSites(props) {
    const [acceptedSitesList, setAcceptedSitesList] = useState([]);
    const [query, setQuery] = useState("");
    const [queryDisplay, setQueryDisplay] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const limit = 20;
    const debounceTimeout = useRef(null);

    const loadAcceptedSite = useCallback(async () => {
        try {
            const payload = {
                params: {
                    q: query || "",
                    page: currentPage,
                    limit: limit,
                }
            };
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/acceptedSites`, payload);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setAcceptedSitesList(obj.results.payload);
                setPageCount(Math.ceil(obj.results.amount / limit));
            }
        } catch (error) {
            console.error(error);
        }
    }, [query, currentPage]);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (queryDisplay.length >= 3 || queryDisplay.length === 0) {
                setQuery(queryDisplay);
                setCurrentPage(0); 
            }
        }, 300);
    }, [queryDisplay]);

    useEffect(() => {
        loadAcceptedSite();
    }, [query, currentPage, loadAcceptedSite]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected);
    }

    return (
        <div className={`AcceptedSites GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder ${props.childClass}`}>
            <input
                type="text"
                className="ActionInput P10 Width35 MB10"
                onChange={(e) => setQueryDisplay(e.target.value)}
                placeholder='Search for a website...'
            />
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Details</th>
                        <th className='P5'>Url</th>
                        <th className='P5'>Approval</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {acceptedSitesList.map((row, key) => (
                        <AcceptedSitesRow
                            key={key}
                            userPremium={row.user_premium}
                            siteUrl={row.site_url}
                            userId={row.site_user_id}
                            adsTxt={row.site_ads_txt}
                            theme={row.theme}
                            country={row.site_country}
                            validationDate={row.date_validation}
                            validationAuthor={row.site_account_validation}
                            raisonRefus={row.raison_refus}
                            refusedDate={row.site_refused_date}
                            refusedAuthor={row.site_refused_admin}
                            siteActif={row.site_actif}
                            siteFraud={row.site_fraud}
                            userFraud={row.user_fraud}
                            userSuspended={row.user_suspended}
                            userFirstname={row.user_firstname}
                            userLastname={row.user_lastname}
                            userMailValid={row.user_mail_valid}
                            nbSiteActif={row.nb_site_actif}
                            nbSiteAccepted={row.nb_site_accepted}
                            cacIso2={row.cac_iso2}
                            siteChild={row.site_child}
                            siteId={row.site_id}
                            adminRoles={row.roles}
                            picture={row.adm_picture}
                            name={row.adm_name}
                            admId={row.adm_id}
                            sponsorMail={row.user_parrain_email}
                            userMail={row.user_mail}
                            siteCmp={row.site_cmp}
                        />
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                className='Pagination'
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </div>
    );
}

export default AcceptedSites;
