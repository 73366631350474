import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";

function DailyView(props) {
    const adm_id = localStorage.getItem('adm_id');
    const [websitesList, setWebsitesList] = useState([]);
    const [revenuesData, setRevenuesData] = useState({});
    const [nbSspConnected, setNbSspConnected] = useState({});
    const [dates, setDates] = useState([]);
    const [newSiteId, setNewSiteId] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const fetchWebsitesToTrack = useCallback(async () => {
        setIsLoading(true); 
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getWebsitesToTrackDaily`, {
                params: { adm_id: adm_id }
            });
            const obj = response.data;
            if (obj.status && obj.results) {
                setWebsitesList(obj.results);
                const revenues = {};
                const sspData = {};
                const datesSet = new Set();
    
                for (const site of obj.results) {
                    const [siteData, sspResponse] = await Promise.all([
                        axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteEvolutionDaily`, {
                            params: { site_id: site.site_id, adm_id: adm_id }
                        }),
                        axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteNbSsp`, {
                            params: { site_id: site.site_id }
                        })
                    ]);
    
                    if (siteData.data.status && siteData.data.results[0]?.site_url) {
                        revenues[site.site_id] = {
                            site_url: siteData.data.results[0].site_url,
                            data: siteData.data.results.reduce((acc, row) => {
                                datesSet.add(row.perf_date);
                                acc[row.perf_date] = {
                                    revenue: row.perf_revenues,
                                    cpm: row.cpm,
                                    rpm: row.rpm,
                                    date: row.perf_date
                                };
                                return acc;
                            }, {})
                        };
                    }
    
                    if (sspResponse.data.status) {
                        sspData[site.site_id] = sspResponse.data.results;
                    }
                }
    
                setRevenuesData(revenues);
                setNbSspConnected(sspData);
                setDates(Array.from(datesSet).sort((a, b) => new Date(a) - new Date(b)));
            }
        } catch (error) {
            console.error("Error fetching websites to track:", error);
        } finally {
            setIsLoading(false);
        }
    }, [adm_id]);
    

    useEffect(() => {
        fetchWebsitesToTrack();
    }, [adm_id, fetchWebsitesToTrack]);

    const handleAddSite = async () => {
        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_addWebsiteTracking`, {
                site_id: newSiteId,
                period: 'daily',
                adm_id: adm_id
            });

            if (response.data.status) {
                toast.success('Site added successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setNewSiteId("");
                fetchWebsitesToTrack(); 
            } else {
                toast.error('Failed to add site', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleInputChange = (e) => {
        setNewSiteId(e.target.value);
    };

    const deleteWebsiteTracking = async (siteId) => {
        try {
            const resp = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_deleteWebsiteTracking`, {
                site_id: siteId,
                adm_id: adm_id
            });

            const obj = resp.data;
            if (obj.status) {
                setWebsitesList(prevWebsitesList => prevWebsitesList.filter(site => site.site_id !== siteId));
                toast.success('Tracking removed for this site', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error deleting website tracking:", error);
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const calculateChange = (current, previous) => {
        if (!previous) return null;
        const change = ((current - previous) / previous) * 100;
        return change.toFixed(1);
    };

    const renderRevenueRow = (siteId, date, prevDate) => {
        const data = revenuesData[siteId]?.data[date] || null;
        const prevData = revenuesData[siteId]?.data[prevDate] || null;

        if (!data) {
            return <td className='P5'>N/A</td>;
        }

        const revenueChange = calculateChange(data.revenue, prevData?.revenue);
        const cpmChange = calculateChange(data.cpm, prevData?.cpm);
        const rpmChange = calculateChange(data.rpm, prevData?.rpm);

        return (
            <td className='P5'>
                <span className="revenue">
                    {data.revenue}€
                    {revenueChange && (
                        <span className={revenueChange >= 0 ? "TGreen" : "TRed"}>
                            ({revenueChange}%)
                        </span>
                    )}
                </span>
                <div>
                    <small>CPM: {data.cpm} {cpmChange && (
                        <span className={cpmChange >= 0 ? "TGreen" : "TRed"}>
                            ({cpmChange}%)
                        </span>
                    )}</small>
                </div>
                <div>
                    <small>RPM: {data.rpm} {rpmChange && (
                        <span className={rpmChange >= 0 ? "TGreen" : "TRed"}>
                            ({rpmChange}%)
                        </span>
                    )}</small>
                </div>
            </td>
        );
    };

    return (
        <div className={"DailyView GridCol1-13 ShadowContainer P15 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className="MT0">Daily View</h2>
            <div className="FlexRow MB10">
                <input
                    type="text"
                    value={newSiteId}
                    onChange={handleInputChange}
                    placeholder="Enter site ID"
                    className="P10 MR10 ActionInput"
                />
                <button onClick={handleAddSite} className="P15 BBlue TWhite ActionButton">
                    Add to list
                </button>
            </div>
            {isLoading ? (
                <SplashLoadComponent />
            ) : (
                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>Site URL</th>
                            {dates.map(date => (
                                <th className='P5' key={date}>Revenues<br />{date}</th>
                            ))}
                            <th className="P5">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {websitesList.map((site, key) => (
                            <tr key={key}>
                                <td className='P5 FlexCol'>
                                    <div className="FlexRow JustifyBetween AlignBaseline">
                                    {revenuesData[site.site_id]?.site_url || 'N/A'}
                                    {revenuesData[site.site_id] && (
                                        <Link className="BOrange TWhite ActionButton" to={"/options/" + site.site_id}><i className="fa-solid fa-gear"></i></Link>
                                    )}
                                        </div>
                                    <div>{"SSP connected: " + nbSspConnected[site.site_id] || "N/A"}</div>
                                </td>
                                {dates.map((date, index) => renderRevenueRow(site.site_id, date, dates[index - 1]))}
                                <td className='P5 TCenter'>
                                    <button className='ActionButton BRed TWhite' onClick={() => deleteWebsiteTracking(site.site_id)}><i className="fa-solid fa-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
    
}

export default DailyView;
