import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './OverviewPlateform.css';
import {useEffect, useState} from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { toast, ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import {format} from "date-fns";
import {DayPicker} from "react-day-picker";
import { Link } from "react-router-dom";

function OverviewPlateform() {
    const pageTab = "overview_plateform";
    const [selectedDay1, setSelectedDay1] = useState(new Date());
    const [selectedDay2, setSelectedDay2] = useState(new Date());

    const [selectedDayF1, setSelectedDayF1] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [selectedDayF2, setSelectedDayF2] = useState(format(new Date(), 'yyyy-MM-dd'));

    const [adsTxtVersion, setAdsTxtVersion] = useState([])
    const [filterAdsTxtVersion, setFilterAdsTxtVersion] = useState("all_v");
    const [filterUrl, setFilterUrl] = useState("");
    const [filterUserID, setFilterUserID] = useState("");
    const [filterPlateform, setFilterPlateform] = useState(["all_p"]);
    const [filterCMP, setFilterCMP] = useState("all_cmp");
    const [filterVU, setFilterVU] = useState("all_vu");
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterOrderBy, setFilterOrderBy] = useState("site_id");
    const [filterOrderByValue, setFilterOrderByValue] = useState("desc");
    const [offsetPage, setOffsetPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);

    useEffect(() => {
        setSelectedDayF1(format(selectedDay1, 'yyyy-MM-dd'));
        setSelectedDayF2(format(selectedDay2, 'yyyy-MM-dd'));
    }, [selectedDay1, selectedDay2])

    const [plateformList, setPlateformList] = useState([]);

    const [siteList, setSiteList] = useState([]);

    const updateSiteStatus = function(type, value){
        console.log(type, value);
        if(!value){
            setFilterStatus(null);
            return
        }

        if(type==="active"){
            setFilterStatus(1);
            return;
        }

        setFilterStatus(0);
    }

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API+"/manage/v1/bo_user/bo_getAllPlateform");
                const obj = resp.data;
                setPlateformList((Object.keys(obj.results).map((key) => obj.results[key].plateform)).filter((row) => {return row}));
            } catch (error) {
                console.error(error);
            }
        })();

        (async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API+"/manage/v1/bo_user/bo_getAdsTxtVersion");
                const obj = resp.data;
                setAdsTxtVersion(Object.keys(obj.results).map((key) => obj.results[key].version));
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const updateOrderBy = function(type){
        if(filterOrderBy===type){
            if(filterOrderByValue==="ASC"){
                setFilterOrderByValue("DESC");
            } else {
                setFilterOrderByValue("ASC");
            }
            applyFilters();
            return;
        }

        setFilterOrderBy(type);
        setFilterOrderByValue("ASC");
        applyFilters();
    }

    const handleMoneyboxAccess = async (siteID) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxToken`, {
                params: { site_id: siteID },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&site_id=" + siteID + "&tbm_hash=" + response.data.results.tbm_hash
                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox ', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error while requesting token:", error);
        }
    };

    const updatePagination = function(action){
        if(offsetPage<=0&&action===-1){
            setOffsetPage(0);
            return;
        }

        if(offsetPage>=(maxPage-1)&&action===1){
            setOffsetPage(maxPage-1);
            return;
        }

        setOffsetPage(offsetPage+action);

        applyFilters();
    }

    const applyFilters = function(){
        (async () => {
            try {
                const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_user/bo_getOverviewPlateform",
                    {
                        filterAdstxt: filterAdsTxtVersion,
                        filterUrl: filterUrl,
                        filterUserID: filterUserID,
                        filterStartDate: selectedDayF1,
                        filterEndDate: selectedDayF2,
                        filterCMP: filterCMP,
                        filterPlateform: filterPlateform,
                        filterVU: filterVU,
                        filterStatus: filterStatus,
                        filterOrderBy: filterOrderBy,
                        filterOrderByValue: filterOrderByValue,
                        offset: offsetPage*25
                    });
                const obj = resp.data;
                setSiteList(obj.results)
                setMaxPage(Math.ceil(obj.pagination.total / 25))
            } catch (error) {
                console.error(error);
            }
        })();
    }

    return (
        <div className="CronExecuted GridTemplate12 OverviewPlateform">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Overview Plateform" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <h2>Overview Plateform</h2>
                <div className="FlexRow MB20">
                    <div className="FlexCol BoDateSelector">
                        <button className="ActionButton Alternate BRed">
                        <i className="fa-solid fa-calendar-days"></i> Date from
                        </button>
                        <DayPicker
                            mode="single"
                            selected={selectedDay1}
                            onSelect={date => {
                            setSelectedDay1(date);
                            }}
                        />
                    </div>
                    <div className="FlexCol BoDateSelector">
                        <button className="ActionButton Alternate BRed ML10">
                        <i className="fa-solid fa-calendar-days"></i> Date to
                        </button>
                        <DayPicker
                            mode="single"
                            selected={selectedDay2}
                            onSelect={date => {
                            setSelectedDay2(date);
                            }}
                        />
                    </div>
                    <div className="FlexCol ML10">
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween">
                            <label htmlFor="input_active">Active</label>
                            <input onClick={(e) => updateSiteStatus("active", e.target.checked)} checked={filterStatus===1} id="input_active" type="checkbox"/>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_inactive">Inactive</label>
                            <input onClick={(e) => updateSiteStatus("inactive", e.target.checked)} checked={filterStatus===0} id="input_inactive" type="checkbox"/>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_cmp">CMP Status</label>
                            <select id="input_cmp" className="FormSelect ActionInput ML10" onChange={(e) => setFilterCMP(e.target.value)}>
                                <option value="all_cmp">All CMP Status</option>
                                <option value="2">2</option>
                                <option value="1">1</option>
                                <option value="no_cmp">0</option>
                                <option value="-1">-1</option>
                            </select>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_ads_txt">Ads.txt</label>
                            <select id="input_ads_txt" className="FormSelect ActionInput ML10" onChange={(e) => setFilterAdsTxtVersion(e.target.value)}>
                                <option value="all_v">All versions</option>
                                {adsTxtVersion.map((r, k) => {
                                    return <option key={k} value={r}>{r}</option>
                                })}
                            </select>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_vu">VU Amount</label>
                            <select id="input_vu" className="FormSelect ActionInput ML10" onChange={(e) => setFilterVU(e.target.value)}>
                                <option value="no_filter">No filter</option>
                                <option value="equal_0">0</option>
                                <option value="between_0_50k">0 - 50k</option>
                                <option value="between_50k_100k">50k - 100k</option>
                                <option value="between_100k_500k">100k - 500k</option>
                                <option value="more_500k">+500k</option>
                            </select>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_plateform">Plateform</label>
                            <select id="input_plateform" className="FormSelect ActionInput ML10" onChange={(e) => setFilterPlateform([e.target.value])}>
                                <option value="all_p">All plateforms</option>
                                {plateformList.map((r, k) => {
                                    return <option key={k} value={r}>{r.toUpperCase()}</option>
                                })}
                            </select>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_site">Site ID / URL</label>
                            <input id="input_site" className="ActionInput P5 ML10" type="text" value={filterUrl} onChange={(e) => setFilterUrl(e.target.value)}/>
                        </div>
                        <div className="FlexRow AlignTop HeightFitContent JustifyBetween MT5">
                            <label htmlFor="input_user">User ID</label>
                            <input id="input_user" className="ActionInput P5" type="text" onChange={(e) => {setFilterUserID(e.target.value);}}/>
                        </div>
                        <button onClick={() => {applyFilters();}} className="ActionButton Alternate BRed MT10">
                            Apply filters
                        </button>
                    </div>
                </div>
                <table className="Width100">
                    <thead>
                        <tr className="BRed TWhite">
                            <th>ID&nbsp;<i onClick={() => updateOrderBy("site_id")}className={"FilterOverview fa-solid " + (filterOrderBy==="site_id"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>Site&nbsp;<i onClick={() => updateOrderBy("site_url")}className={"FilterOverview fa-solid " + (filterOrderBy==="site_url"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>Actions</th>
                            <th>Date&nbsp;<i onClick={() => updateOrderBy("site_date_validation")}className={"FilterOverview fa-solid " + (filterOrderBy==="site_date_validation"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>VU&nbsp;<i onClick={() => updateOrderBy("vu_similar")}className={"FilterOverview fa-solid " + (filterOrderBy==="vu_similar"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>Plateform&nbsp;<i onClick={() => updateOrderBy("plateform")}className={"FilterOverview fa-solid " + (filterOrderBy==="plateform"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>User&nbsp;<i onClick={() => updateOrderBy("user_id")}className={"FilterOverview fa-solid " + (filterOrderBy==="user_id"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>Mail&nbsp;<i onClick={() => updateOrderBy("user_mail")}className={"FilterOverview fa-solid " + (filterOrderBy==="user_mail"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>Ads.txt&nbsp;<i onClick={() => updateOrderBy("ads_txt")}className={"FilterOverview fa-solid " + (filterOrderBy==="ads_txt"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>CMP&nbsp;<i onClick={() => updateOrderBy("cmp")}className={"FilterOverview fa-solid " + (filterOrderBy==="cmp"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>SSP</th>
                            <th>Format</th>
                            <th>Perf&nbsp;<i onClick={() => updateOrderBy("perf")}className={"FilterOverview fa-solid " + (filterOrderBy==="perf"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                            <th>Margin&nbsp;<i onClick={() => updateOrderBy("perf_margin")}className={"FilterOverview fa-solid " + (filterOrderBy==="perf_margin"?(filterOrderByValue==="DESC" ? "fa-sort-down": "fa-sort-up"): "fa-sort")}></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {siteList.map((r,k) => {
                            return <tr className={r.site_actif ? "BSoftGreen" : "BSoftRed"}>
                                <td>
                                    {r.site_id}
                                </td>
                                <td>
                                    <div className="Tooltip">
                                        <Link className="TruncateText" target="_blank" to={r.site_url}>{r.site_url}</Link>
                                        <span class="Tooltiptext">{r.site_url}</span>
                                    </div>
                                </td>
                                <td className="P5">
                                    <button className="ActionButton BOrange TWhite" onClick={() => window.open(("https://bo.themoneytizer.com/options/"+r.site_id), '_blank')}><i className="fa-solid fa-gear"></i></button>
                                    <button className="ActionButton BGreen TWhite ML5" onClick={() => handleMoneyboxAccess(r.site_id)}><i className="MR5 fa-solid fa-piggy-bank"></i></button>
                                </td>
                                <td>
                                    {r.site_date_validation}
                                </td>
                                <td>
                                    {r.vu_similar}
                                </td>
                                <td>
                                    {r.user_plateform}
                                </td>
                                <td>
                                    {r.user_id}
                                </td>
                                <td>
                                    <div className="Tooltip">
                                        <div className="TruncateText" target="_blank" to={r.user_mail}>{r.user_mail}</div>
                                        <span class="Tooltiptext">{r.user_mail}</span>
                                    </div>
                                </td>
                                <td>
                                    {r.site_ads_txt}
                                </td>
                                <td>
                                    {r.site_cmp}
                                </td>
                                <td>
                                    {r.nb_ssp}
                                </td>
                                <td>
                                    {r.tag}
                                </td>
                                <td>
                                    {r.perf}
                                </td>
                                <td>
                                    {r.perf_margin}
                                </td>
                            </tr>
                        })}
                        <tr></tr>
                    </tbody>
                </table>
                <div className="FlexRow AlignCenter JustifyCenter MT20">
                    <button onClick={() => updatePagination(-1)} className="ActionButton BRed"><i className="fa-solid fa-arrow-left"></i></button>
                    <div className="P10">{offsetPage + 1} / {maxPage}</div>
                    <button onClick={() => updatePagination(1)} className="ActionButton BRed"><i className="fa-solid fa-arrow-right"></i></button>
                </div>
            </div>
        </div>
    );
}

export default OverviewPlateform;