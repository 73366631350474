import { useState, useEffect } from "react";

function formatDate(date) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

function RefusedNotices(props) {
    const [refusedNoticesList, setRefusedNoticesList] = useState([]);

    useEffect(() => {
        setRefusedNoticesList(props.noticesList);
    }, [props.noticesList]);

    return (
        <div className={"RefusedNotices GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>User ID</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>Start date</th>
                        <th className="P5">Refused date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        refusedNoticesList
                            .filter(row => {
                                return row.status === 2;
                            })
                            .map((row, key) => {
                                const startDate = new Date(row.start_date);
                                const refusedDate = new Date(row.validation_date);
                                return (
                                    <tr key={key}>
                                        <td className='P15'>{row.user_id}</td>
                                        <td className='P15'>{row.user_mail}</td>
                                        <td className='P15'>{formatDate(startDate)}</td>
                                        <td className="P15">{formatDate(refusedDate)}</td>
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default RefusedNotices;
