import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from "../../Constant";
import { BarLoader
    
 } from "react-spinners";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function AverageCpmCodir(props) {
    const [graphData, setGraphData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [averageCpm, setAverageCpm] = useState(0);

    const colorsList = useMemo(() => ["#3498db"], []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getRevenuesImpressionsByDate`, {
                    params: {
                        date_from: props.sDate,
                        date_to: props.eDate,
                    }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    const datasets = [
                        {
                            label: 'CPM',
                            lineTension: 0.3,
                            data: [],
                            borderColor: colorsList[0],
                            backgroundColor: colorsList[0],
                            yAxisID: 'y',
                        }
                    ];
                    const labels = [];
                    let totalCpm = 0;

                    obj.results.forEach(row => {
                        const cpm = parseFloat((row.revenues / row.impressions) * 1000);
                        labels.push(row.sp_date.substring(5));
                        datasets[0].data.push(cpm);
                        totalCpm += cpm;
                    });

                    setGraphData({
                        labels,
                        datasets
                    });

                    const avgCpm = totalCpm / obj.results.length;
                    setAverageCpm(avgCpm.toFixed(2));
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        })();
    }, [colorsList, props.sDate, props.eDate]);

    return (
        <div className="AverageCpmCodir GridCol7-13 ShadowContainer BWhite P15">
            <h2 className="M0 MT5 MB5">CPM</h2>
            {loading ? (
                <BarLoader height={8} width={230} color="#f41127" />
            ) : (
                <>
                    <div className="InnerCAChart">
                        {graphData !== null ? (
                            <Line
                                data={graphData}
                                height={300}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    scales: {
                                        y: {
                                            min: 0.08,
                                            position: 'left',
                                            grid: {
                                                drawOnChartArea: true,
                                            },
                                        }
                                    }
                                }}
                            />
                        ) : ''}
                    </div>
                    <h2 className="M0 MT20 MB5">Average CPM</h2>
                    <span className="BigFont TGrey FWBold">{averageCpm} €</span>
                </>
            )}
        </div>
    );
}

export default AverageCpmCodir;
