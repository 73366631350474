import { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../../Constant";
import { Link } from 'react-router-dom';

function PassbackWaitingRow({ row, onUpdate, onRefuse, onAccept }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [bidText, setBidText] = useState(row.bid_text);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleSave = async () => {
        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_updatePassback`, {
                bid_id: row.bid_id,
                bid_text: bidText,
                adm_id: localStorage.getItem('adm_id')
            });
            if (response.data.status) {
                onUpdate(row.bid_id, bidText);
                togglePopup();
                toast.success('Passback successfully updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    const handleRefuse = async () => {
        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_refusePassback`, {
                bid_id: row.bid_id,
                adm_id: localStorage.getItem('adm_id')
            });
            if (response.data.status) {
                onRefuse(row.bid_id);
                toast.success('Passback successfully refused', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    const handleAccept = async () => {
        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_acceptPassback`, {
                bid_id: row.bid_id,
                adm_id: localStorage.getItem('adm_id')
            });
            if (response.data.status) {
                onAccept(row.bid_id);
                toast.success('Passback successfully accepted', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    return (
        <>
            <tr>
                <td className='P15'>
                    <div className='FlexRow JustifyBetween AlignBaseline'>
                        {row.site_id}
                        <Link className="BOrange TWhite ActionButton ML10" to={`/options/${row.site_id}`}><i className="fa-solid fa-gear"></i></Link>
                    </div>
                </td>
                <td className='P15'>{row.site_url}</td>
                <td className='P15'>{row.tag_name}</td>
                <td className='P15 SmallFont OverflowAuto OverflowWrapBreakWord WhiteSpacePreWrap MaxWidthPx600 FontCode FWBold'>{row.bid_text}</td>
                <td className='P15 TCenter'>{row.bid_date}</td>
                <td className='P15'>
                    <div className='FlexRow AlignCenter JustifyBetween'>
                        <button className='ActionButton BOrange TWhite MR10' onClick={togglePopup}>
                            <i className="fa-solid fa-pencil"></i>
                        </button>
                        <button className='ActionButton BGreen TWhite MR10' onClick={handleAccept}>
                            <i className="fa-solid fa-check"></i>
                        </button>
                        <button className='ActionButton BRed TWhite MR10' onClick={handleRefuse}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </td>
            </tr>
            {isPopupOpen && (
                <div className='overlay'>
                    <div className='popup'>
                        <textarea
                            className='FullWidth Height300px BoxSizeBorder P10 BorderRad10'
                            value={bidText}
                            onChange={(e) => setBidText(e.target.value)}
                        />
                        <button className='ActionButton BGreen TWhite MR10' onClick={handleSave}>
                            Save
                        </button>
                        <button className='ActionButton BBlack TWhite' onClick={togglePopup}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default PassbackWaitingRow;
