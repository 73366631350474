import './WorkersList.css';
import { useState, useEffect } from "react";
import axios from "axios";
import WorkersRow from "./WorkersRow/WorkersRow";
import { ROOT_DOMAIN_API } from '../../Constant';

function EditWorkers() {
    const [workersList, setWorkersList] = useState([]);
    const [v, setV] = useState(0);
    const [rolesList, setRolesList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_admin/workersList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    if (obj.status && obj.results) {
                        setWorkersList(obj.results)
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [v]);

    useEffect(() => {
        (async () => {
          try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/bo_admin/RolesList");
            const obj = await resp.data;
            if(obj.status&&obj.results){setRolesList(obj.results);}
          } catch (error) {
              console.error(error);
          }
        })();
      }, [])

    const hotReload = function () {
        setV(Date.now());
    }

    return (
        <div className="WorkersList GridTemplate12 GridCol1-13 ShadowContainer P25 BWhite">
            {workersList.map((row, key) => {
                return <WorkersRow key={key} hotReload={hotReload} adminRoles={row.roles} picture={row.adm_picture} name={row.adm_name} admId={row.adm_id} rolesList={rolesList} />
            })}
        </div>
    );
}

export default EditWorkers;
