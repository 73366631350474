import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import TopPerformance from "../../Components/CreamOfTheCream/TopPerformance";
import { useState, useEffect } from "react";
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
import "./CreamOfTheCream.css";
import TopSiteRevenues from "../../Components/CreamOfTheCream/TopSiteRevenues";
import TopSiteRevenuesByPlatform from "../../Components/CreamOfTheCream/TopSiteRevenuesByPlatform";
import SplashLoadComponent from "../../Components/SplashLoadComponent/SplashLoadComponent";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"

function CreamOfTheCream() {
    const pageTab = "cream_of_the_cream";
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [CAByPlateform, setCAByPlateform] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Set date by default D-15 and D-2
    useEffect(() => {
        const dateFromDefault = new Date();
        dateFromDefault.setDate(dateFromDefault.getDate() - 15);
        setDateFrom(dateFromDefault);

        const dateToDefault = new Date();
        dateToDefault.setDate(dateToDefault.getDate() - 2);
        setDateTo(dateToDefault);
    }, []);

    useEffect(() => {
        if (dateFrom && dateTo) {
            (async () => {
                setIsLoading(true);
                try {
                    const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getCAByPlateform`, {
                        params: {
                            date_from: dateFrom.toISOString().split('T')[0],
                            date_to: dateTo.toISOString().split('T')[0],
                        }
                    });
                    const obj = await resp.data;
                    if (obj.status && obj.results) {
                        setCAByPlateform(obj.results);
                    }
                } catch (error) {
                    console.error(error);
                }
                setIsLoading(false);
            })();
        }
    }, [dateFrom, dateTo]);

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <HeadLine childClass="Headline GridCol1-13" title="Cream of the Cream" />
            <h3 className="GridCol1-13">From {dateFrom ? dateFrom.toLocaleDateString('fr-FR') : ''} to {dateTo ? dateTo.toLocaleDateString('fr-FR') : ''}</h3>

            <LateralNavComponent pageTab={pageTab} />

            {isLoading && <div className="GridCol1-13"><SplashLoadComponent /></div>}
            <TopPerformance flag="🌍" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} />
            <TopSiteRevenues flag="📈" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} />
            <TopSiteRevenuesByPlatform platform="ww" flag="🇫🇷" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol1-7"} />
            <TopSiteRevenuesByPlatform platform="us" flag="🇺🇸" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol7-13"} />
            <TopSiteRevenuesByPlatform platform="it" flag="🇮🇹" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol1-7"} />
            <TopSiteRevenuesByPlatform platform="es" flag="🇪🇸" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol7-13"} />
            <TopSiteRevenuesByPlatform platform="de" flag="🇩🇪" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol1-7"} />
            <TopSiteRevenuesByPlatform platform="pt" flag="🇵🇹🇧🇷" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol7-13"} />
            <TopSiteRevenuesByPlatform platform="ru" flag="🇷🇺" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol1-7"} />
            <TopSiteRevenuesByPlatform platform="jp" flag="🇯🇵" dateFrom={dateFrom} dateTo={dateTo} CAByPlateform={CAByPlateform} childClass={"GridCol7-13"} />
        </div>
    )
}

export default CreamOfTheCream;