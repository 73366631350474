import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './PerfPartners.css';
import { useState, useEffect } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { DayPicker } from 'react-day-picker';
import {format} from "date-fns";

function PerfPartners() {
    const pageTab = "perf_partners";
    const [cron_ssp, setCronSsp] = useState([]);
    const [date, setDate] = useState([]);
    const [total_by_day, setTotalByDay] = useState([]);

    const start_date = new Date().setDate(new Date().getDate() - 10);
    const end_date = new Date().setDate(new Date().getDate() - 2);

    const [dayPickerStart, setDayPickerStart] = useState(false);
    const [selectedDayStart, setSelectedDayStart] = useState(format(start_date, 'yyyy-MM-dd'));
    const [sD1Formatted, setSD1Formatted] = useState(format(start_date, 'yyyy-MM-dd'));

    const [dayPickerEnd, setDayPickerEnd] = useState(false);
    const [selectedDayEnd, setSelectedDayEnd] = useState(format(end_date, 'yyyy-MM-dd'));
    const [sD2Formatted, setSD2Formatted] = useState(format(end_date, 'yyyy-MM-dd'));

    const toggleDayPickerStart = function(){
        if(dayPickerStart){
            setDayPickerStart(false);
        } else {
            setDayPickerStart(true);
        }
    }

    const toggleDayPickerEnd = function(){
        if(dayPickerEnd){
            setDayPickerEnd(false);
        } else {
            setDayPickerEnd(true);
        }
    }

    useEffect(() => {
        setSD1Formatted(format(new Date(selectedDayStart), 'yyyy-MM-dd'));
        setSD2Formatted(format(new Date(selectedDayEnd), 'yyyy-MM-dd'));
        setDayPickerStart(false);
        setDayPickerEnd(false);

        (async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API+"/manage/v1/bo_user/bo_partnersPerformance?date_from="+format(new Date(selectedDayStart), 'yyyy-MM-dd')+"&date_to="+format(new Date(selectedDayEnd), 'yyyy-MM-dd'));
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setCronSsp(obj.results.cron_ssp);
                    setDate(obj.results.date);
                    setTotalByDay(obj.results.total_by_day);
                }
            } catch (error) {
                console.error(error);
            }
        })();

    }, [selectedDayStart, selectedDayEnd])

    return (
        <div className="PerfPartners GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Performance Partners" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <h2>Performance Partners</h2>

                <div className="FlexRow MB20">
                    <div className="FlexCol MR10">
                        <button className="ActionButton BRed" onClick={() => toggleDayPickerStart()}>
                            <i className="fa-solid fa-calendar"></i>&nbsp;From {sD1Formatted}</button>
                        <DayPicker
                            format="M/D/YYYY"
                            className={"DayPickerObject " + (dayPickerStart ? '' : 'Hide')}
                            mode="single"
                            selected={selectedDayStart}
                            onSelect={setSelectedDayStart}
                        />
                    </div>
                    <div className="FlexCol">
                        <button className="ActionButton BRed" onClick={() => toggleDayPickerEnd()}>
                            <i className="fa-solid fa-calendar"></i>&nbsp;To {sD2Formatted}</button>
                        <DayPicker
                            format="M/D/YYYY"
                            className={"DayPickerObject " + (dayPickerEnd ? '' : 'Hide')}
                            mode="single"
                            selected={selectedDayEnd}
                            onSelect={setSelectedDayEnd}
                        />
                    </div>
                </div>

                <table className="PartnersPerfTable">
                    <thead>
                    <tr>
                        <th></th>
                        {date.length > 0 && date.map((row, key) => (
                            <th>{row.date}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {cron_ssp.length > 0 && cron_ssp.map((row) => (
                        <tr>
                            {row.length > 0 && row.map((row2, key2) => (
                                <td className={"P5 " + (row2.total === 0 ? 'no_total' : (row2.pourcentage >= 100 ? 'more_100_percent' : (row2.pourcentage <= -40 ? 'less_40_percent' : '')))}>
                                    {!isNaN(row2.total) ? (row2.total > 0 ? row2.total + (row2.pourcentage <= -40 || row2.pourcentage >= 100 ? " (" + row2.pourcentage + ")%" :"") : "") : row2.total}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td></td>
                        {total_by_day.length > 0 && total_by_day.map((row, key) => (
                            <td className={'FWBold'}>{row.total}</td>
                        ))}
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default PerfPartners;
