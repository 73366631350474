import { useEffect } from 'react';
import './PendingApprovalItemComponent.css';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from '../../Constant';

function PendingApprovalItemComponent(props) {
  const [currentData, setCurrentData] = useState({});
  const [pendingData, setPendingData] = useState({});
  const [v, setV] = useState("");

  useEffect(() => {
    if (props.data.abc_current_data) {
      setCurrentData(JSON.parse(props.data.abc_current_data));
    }
    if (props.data.abc_current_data) {
      setPendingData(JSON.parse(props.data.abc_pending_data));
    }
  }, [props.data])


  const openImg = function (base64ImageData) {
    var contentType = "image/jpeg";
    if (base64ImageData.includes("data:image/png;base64,")) {
      contentType = "image/png";
    }

    if (base64ImageData.includes("data:image/jpeg;base64,")) {
      contentType = "image/jpeg";
    }

    base64ImageData = base64ImageData.replace('data:image/png;base64,', '');
    base64ImageData = base64ImageData.replace('data:image/jpeg;base64,', '');

    console.log(base64ImageData);

    const byteCharacters = atob(base64ImageData);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
  }

  const updatePendingApproval = function (status, abc_id) {
    (async () => {
      const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/updatePendingApproval',
        { status: status, abc_id: parseInt(abc_id) });
      const obj = await resp.data;
      if (obj.status) {
        setV("Hide");
        if (status) {
          toast.success('✅ You have successfully accepted the request to change informations.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success('❌ You have successfully refused the request to change informations.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    })();

  }

  return (
    <div className={"PendingApprovalItemComponent ShadowContainer FlexRow MT15 " + v + " " + props.childClass}>
      <button className='ActionButton BBlue TWhite Height25px Width10' onClick={() => window.open(("https://crm.themoneytizer.com/crm_user/" + currentData.user_id), '_blank')}>
        <i class="fa-solid fa-book"></i>
      </button>
      <div className='FlexCol ML20 Width30'>
        <p className='MT0 FWBold MediumFont'>✅ Current informations</p>
        <span>Firstname</span>
        <span className={currentData.user_firstname === pendingData.firstname ? "Identical" : "Mismatch"}>
          {currentData.user_firstname ?? ""}
        </span>
        <span>Lastname</span>
        <span className={currentData.user_lastname === pendingData.lastname ? "Identical" : "Mismatch"}>
          {currentData.user_lastname ?? ""}
        </span>
        <span>Area phone</span>
        <span className={currentData.user_area_code_phone === pendingData.area_code_country_phone ? "Identical" : "Mismatch"}>
          {currentData.user_area_code_phone ?? ""}
        </span>
        <span>Phone</span>
        <span className={currentData.user_phone === pendingData.phone ? "Identical" : "Mismatch"}>
          {currentData.user_phone ?? ""}
        </span>
        <span>Adress</span>
        <span className={currentData.user_adress === pendingData.adress ? "Identical" : "Mismatch"}>
          {currentData.user_adress ?? ""}
        </span>
        <span>ZIP</span>
        <span className={currentData.user_zip === pendingData.cp ? "Identical" : "Mismatch"}>
          {currentData.user_zip ?? ""}
        </span>
        <span>City</span>
        <span className={currentData.user_city === pendingData.city ? "Identical" : "Mismatch"}>
          {currentData.user_city ?? ""}
        </span>
        <span>Country</span>
        <span className={currentData.user_country === pendingData.country ? "Identical" : "Mismatch"}>
          {currentData.user_country ?? ""}
        </span>
        <span>Denomination sociale</span>
        <span className={currentData.user_denomination_sociale === pendingData.denomination_sociale ? "Identical" : "Mismatch"}>
          {currentData.user_denomination_sociale ?? ""}
        </span>
        <span>Company name</span>
        <span className={currentData.user_entreprise === pendingData.entreprise ? "Identical" : "Mismatch"}>
          {currentData.user_entreprise ?? ""}
        </span>
        <span>SIREN</span>
        <span className={currentData.user_siren === pendingData.siren ? "Identical" : "Mismatch"}>
          {currentData.user_siren ?? ""}
        </span>
        <span>TVA</span>
        <span className={currentData.user_tva === pendingData.tva ? "Identical" : "Mismatch"}>
          {currentData.user_tva ?? ""}
        </span>
        <span>Paypal</span>
        <span className={currentData.user_paypal_mail === pendingData.paypal ? "Identical" : "Mismatch"}>
          {currentData.user_paypal_mail ?? ""}
        </span>
        <span>Capitalist</span>
        <span className={currentData.user_capitalist === pendingData.capitalist ? "Identical" : "Mismatch"}>
          {currentData.user_capitalist ?? ""}
        </span>
        <span>Bank account holder name</span>
        <span className={currentData.user_bank_name === pendingData.tit_compt ? "Identical" : "Mismatch"}>
          {currentData.user_bank_name ?? ""}
        </span>
        <span>Bank adress</span>
        <span className={currentData.user_bank_adressbank === pendingData.adress_bank ? "Identical" : "Mismatch"}>
          {currentData.user_bank_adressbank ?? ""}
        </span>
        <span>Bank city</span>
        <span className={currentData.user_bank_citybank === pendingData.city_bank ? "Identical" : "Mismatch"}>
          {currentData.user_bank_citybank ?? ""}
        </span>
        <span>Bank ZIP</span>
        <span className={currentData.user_bank_zipbank === pendingData.zip_bank ? "Identical" : "Mismatch"}>
          {currentData.user_bank_zipbank ?? ""}
        </span>
        <span>Bank country</span>
        <span className={currentData.user_bank_countrybank === pendingData.country_bank ? "Identical" : "Mismatch"}>
          {currentData.user_bank_countrybank ?? ""}
        </span>
        <span>Bank name</span>
        <span className={currentData.user_bank_namebank === pendingData.nom_bank ? "Identical" : "Mismatch"}>
          {currentData.user_bank_namebank ?? ""}
        </span>
        <span>Swift</span>
        <span className={currentData.user_bank_bic === pendingData.swift ? "Identical" : "Mismatch"}>
          {currentData.user_bank_bic ?? ""}
        </span>
        <span>Iban</span>
        <span className={pendingData.iban ? (pendingData.iban.includes("*") ? "Identical" : (currentData.user_bank_iban === pendingData.iban ? "Identical" : "Mismatch")) : (currentData.user_bank_iban === pendingData.iban ? "Identical" : "Mismatch")}>
          {currentData.user_bank_iban ?? ""}
        </span>
        <span>Inter Bank name</span>
        <span className={currentData.user_bank_namebank_inter === pendingData.nom_bank_inter ? "Identical" : "Mismatch"}>
          {currentData.user_bank_namebank_inter ?? ""}
        </span>
        <span>Inter Bank country</span>
        <span className={currentData.user_bank_countrybank_inter === pendingData.country_bank_inter ? "Identical" : "Mismatch"}>
          {currentData.user_bank_countrybank_inter ?? ""}
        </span>
        <span>Inter Bank city</span>
        <span className={currentData.user_bank_citybank_inter === pendingData.city_bank_inter ? "Identical" : "Mismatch"}>
          {currentData.user_bank_citybank_inter ?? ""}
        </span>
        <span>Inter Swift</span>
        <span className={currentData.user_bank_bic_inter === pendingData.swift_inter ? "Identical" : "Mismatch"}>
          {currentData.user_bank_bic_inter ?? ""}
        </span>
        <span>Inter Iban</span>
        <span className={pendingData.iban_inter ? (pendingData.iban_inter.includes("*") ? "Identical" : (currentData.user_bank_iban_inter === pendingData.iban_inter ? "Identical" : "Mismatch")) : (currentData.user_bank_iban_inter === pendingData.iban_inter ? "Identical" : "Mismatch")}>
          {currentData.user_bank_iban_inter ?? ""}
        </span>
      </div>
      <div className='FlexCol ML20 Width30'>
        <p className='MT0 FWBold MediumFont'>⌛ Pending informations</p>
        <span>Firstname</span>
        <span className={currentData.user_firstname === pendingData.firstname ? "Identical" : "Mismatch"}>
          {pendingData.firstname ?? ""}
        </span>
        <span>Lastname</span>
        <span className={currentData.user_lastname === pendingData.lastname ? "Identical" : "Mismatch"}>
          {pendingData.lastname ?? ""}
        </span>
        <span>Area phone</span>
        <span className={currentData.user_area_code_phone === pendingData.area_code_country_phone ? "Identical" : "Mismatch"}>
          {pendingData.area_code_country_phone ?? ""}
        </span>
        <span>Phone</span>
        <span className={currentData.user_phone === pendingData.phone ? "Identical" : "Mismatch"}>
          {pendingData.phone ?? ""}
        </span>
        <span>Adress</span>
        <span className={currentData.user_adress === pendingData.adress ? "Identical" : "Mismatch"}>
          {pendingData.adress ?? ""}
        </span>
        <span>ZIP</span>
        <span className={currentData.user_zip === pendingData.cp ? "Identical" : "Mismatch"}>
          {pendingData.cp ?? ""}
        </span>
        <span>City</span>
        <span className={currentData.user_city === pendingData.city ? "Identical" : "Mismatch"}>
          {pendingData.city ?? ""}
        </span>
        <span>Country</span>
        <span className={currentData.user_country === pendingData.country ? "Identical" : "Mismatch"}>
          {pendingData.country ?? ""}
        </span>
        <span>Denomination sociale</span>
        <span className={currentData.user_denomination_sociale === pendingData.denomination_sociale ? "Identical" : "Mismatch"}>
          {pendingData.denomination_sociale ?? ""}
        </span>
        <span>Company name</span>
        <span className={currentData.user_entreprise === pendingData.entreprise ? "Identical" : "Mismatch"}>
          {pendingData.entreprise ?? ""}
        </span>
        <span>SIREN</span>
        <span className={currentData.user_siren === pendingData.siren ? "Identical" : "Mismatch"}>
          {pendingData.siren ?? ""}
        </span>
        <span>TVA</span>
        <span className={currentData.user_tva === pendingData.tva ? "Identical" : "Mismatch"}>
          {pendingData.tva ?? ""}
        </span>
        <span>Paypal</span>
        <span className={currentData.user_paypal_mail === pendingData.paypal ? "Identical" : "Mismatch"}>
          {pendingData.paypal ?? ""}
        </span>
        <span>Capitalist</span>
        <span className={currentData.user_capitalist === pendingData.capitalist ? "Identical" : "Mismatch"}>
          {pendingData.capitalist ?? ""}
        </span>
        <span>Bank account holder name</span>
        <span className={currentData.user_bank_name === pendingData.tit_compt ? "Identical" : "Mismatch"}>
          {pendingData.tit_compt ?? ""}
        </span>
        <span>Bank adress</span>
        <span className={currentData.user_bank_adressbank === pendingData.adress_bank ? "Identical" : "Mismatch"}>
          {pendingData.adress_bank ?? ""}
        </span>
        <span>Bank city</span>
        <span className={currentData.user_bank_citybank === pendingData.city_bank ? "Identical" : "Mismatch"}>
          {pendingData.city_bank ?? ""}
        </span>
        <span>Bank ZIP</span>
        <span className={currentData.user_bank_zipbank === pendingData.zip_bank ? "Identical" : "Mismatch"}>
          {pendingData.zip_bank ?? ""}
        </span>
        <span>Bank country</span>
        <span className={currentData.user_bank_countrybank === pendingData.country_bank ? "Identical" : "Mismatch"}>
          {pendingData.country_bank ?? ""}
        </span>
        <span>Bank name</span>
        <span className={currentData.user_bank_namebank === pendingData.nom_bank ? "Identical" : "Mismatch"}>
          {pendingData.nom_bank ?? ""}
        </span>
        <span>Swift</span>
        <span className={currentData.user_bank_bic === pendingData.swift ? "Identical" : "Mismatch"}>
          {pendingData.swift ?? ""}
        </span>
        <span>Iban</span>
        <span className={pendingData.iban ? (pendingData.iban.includes("*") ? "Identical" : (currentData.user_bank_iban === pendingData.iban ? "Identical" : "Mismatch")) : (currentData.user_bank_iban === pendingData.iban ? "Identical" : "Mismatch")}>
          {pendingData.iban ? (pendingData.iban.includes("*") ? (currentData.user_bank_iban ?? "") : (pendingData.iban ?? "")) : ""}
        </span>
        <span>Inter Bank name</span>
        <span className={currentData.user_bank_namebank_inter === pendingData.nom_bank_inter ? "Identical" : "Mismatch"}>
          {pendingData.nom_bank_inter ?? ""}
        </span>
        <span>Inter Bank country</span>
        <span className={currentData.user_bank_countrybank_inter === pendingData.country_bank_inter ? "Identical" : "Mismatch"}>
          {pendingData.country_bank_inter ?? ""}
        </span>
        <span>Inter Bank city</span>
        <span className={currentData.user_bank_citybank_inter === pendingData.city_bank_inter ? "Identical" : "Mismatch"}>
          {pendingData.city_bank_inter ?? ""}
        </span>
        <span>Inter Swift</span>
        <span className={currentData.user_bank_bic_inter === pendingData.swift_inter ? "Identical" : "Mismatch"}>
          {pendingData.swift_inter ?? ""}
        </span>
        <span>Inter Iban</span>
        <span className={pendingData.iban_inter ? (pendingData.iban_inter.includes("*") ? "Identical" : (currentData.user_bank_iban_inter === pendingData.iban_inter ? "Identical" : "Mismatch")) : (currentData.user_bank_iban_inter === pendingData.iban_inter ? "Identical" : "Mismatch")}>
          {pendingData.iban_inter ? (pendingData.iban_inter.includes("*") ? (currentData.user_bank_iban_inter ?? "") : (pendingData.iban_inter ?? "")) : ""}
        </span>
      </div>
      <div className='FlexCol ML20 Width30'>
        <p className='MT0 FWBold MediumFont'>🪪 Identity proof</p>
        <img className="ProofPicture" src={props.data.abc_file_base64} alt="Proof" />
        <button className='ActionButton BBlue TWhite Height25px MT10' onClick={() => openImg(props.data.abc_file_base64)}>
          Show in full screen
        </button>
        <p className='LightText M0 MT5'>User ID: {currentData.user_id}</p>
        <p className='LightText M0'>Date: {props.data.abc_creation_datetime}</p>
        <div className='FlexRow'>
          <button onClick={() => { updatePendingApproval(1, props.data.abc_id) }} className='ActionButton BGreen TWhite Height25px MT10'>
            <i className="fa-solid fa-check"></i>
          </button>
          <button onClick={() => { updatePendingApproval(0, props.data.abc_id) }} className='ActionButton BRed TWhite Height25px MT10 ML10'>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PendingApprovalItemComponent;
