import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../Constant";
import "react-toastify/dist/ReactToastify.css";

function ImportQuality(props) {
    const [ids, setIds] = useState("");
    const [raisonQualityList, setRaisonQualityList] = useState([]);
    const [raisonQuality, setRaisonQuality] = useState("");

    useEffect(() => {
        fetchRaisonQuality();
    }, []);

    const fetchRaisonQuality = async () => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getListRaisonQuality`);
            
            if (response.data.status) {
                setRaisonQualityList(response.data.results);
            } else {
                toast.error('Failed to fetch Raison Quality list', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Failed to fetch Raison Quality list:", error);
            toast.error('Failed to fetch SSP list', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleParse = async () => {
        const idsArray = ids
            .split(/[\s,]+/) 
            .map(id => id.trim()) 
            .filter(id => id.length > 0); 

        const requestData = {
            ids: idsArray,
            raison_quality: raisonQuality
        };

        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_importQuality`, requestData);

            if (response.data.status) {
                toast.success('Site quality -1 imported successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Failed to import Site quality -1', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Failed to import Site quality -1:", error);
            toast.error('Failed to import Site quality -1', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={"ExportTemplate GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className='MT0 MB5'>New Export or Template</h2>
            <div className="FlexCol">
                <div className="FlexCol MB5">
                    <label>Export Name</label>
                    <input type="text" 
                            className="ActionInput P5 WidthPx185" 
                            value={ids} 
                            onChange={(e) => setIds(e.target.value)}
                            placeholder="My custom export" />
                </div>
                <div className="FlexRow Gap10">
                    <div className="FlexCol MB10">
                        <label>Fields</label>
                        <select
                            className="ActionInput P5"
                            name="raisonQuality"
                            value={raisonQuality}
                            onChange={(e) => setRaisonQuality(e.target.value)}
                        >
                            <option value="" disabled>Select Fields</option>
                            {raisonQualityList.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="FlexRow Gap10">
                    <div className="FlexCol MB10">
                        <label>Where</label>
                        <button className="ActionButton BGrey">+</button>
                    </div>
                </div>
            </div>
            <div className="FlexRow">
                <button className="ActionButton BGreen" onClick={handleParse}>Parse</button>
            </div>
        </div>
    );
}

export default ImportQuality;
