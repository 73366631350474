import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SiteInterstitialCloseButtonDelay(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [interstitialCloseButtonDelayValue, setInterstitialCloseButtonDelayValue] = useState(); 

    useEffect(() => {
        setInterstitialCloseButtonDelayValue(props.siteInterstitialCloseButtonDelay);
    }, [props.siteInterstitialCloseButtonDelay])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpateInterstitialCloseButtonDelay(siteId, interstitialCloseButtonDelayValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateInterstitialCloseButtonDelay", { site_id: siteId, intersitial_close_button_delay: interstitialCloseButtonDelayValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Interstitial Close Button Delay updated to ' + (interstitialCloseButtonDelayValue === "0" ? 'no delay' : '5s delay'), {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="intersitial_close_button_delay">Interstitial : Close Button Delay</label>
            <div className="LightText TGrey">(1 = 5s delay | 0 = no delay)</div>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={interstitialCloseButtonDelayValue}
                    placeholder="Delay"
                    id="intersitial_close_button_delay"
                    onChange={(e) => setInterstitialCloseButtonDelayValue(e.target.value)}
                />
                <button className="ActionButton BGreen" onClick={() => udpateInterstitialCloseButtonDelay(props.siteId, interstitialCloseButtonDelayValue)}>Save</button>
            </div>
        </div>
    );
}

export default SiteInterstitialCloseButtonDelay;
