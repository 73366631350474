import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./BusinessFinderSetup.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';

function BusinessFinderSetup(props) {
    const [userId, setUserId] = useState("");
    const [lastname, setLastname] = useState("");
    const [firstname, setFirstname] = useState("");
    const [email, setEmail] = useState("");
    const [revshare, setRevshare] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [nbMonth, setNbMonth] = useState("");
    const [currency, setCurrency] = useState("");
    const [site, setSite] = useState([]);

    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        console.log(id);

        if(id === "0"){
            return;
        }

        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getBusinessFinderById?id="+id);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    console.log(obj.results);
                    setUserId(obj.results.bf_user_id);
                    setLastname(obj.results.user_lastname);
                    setFirstname(obj.results.user_firstname);
                    setEmail(obj.results.user_mail);
                    setRevshare(obj.results.ib_revshare);
                    setStartDate(obj.results.bf_start_date);
                    setEndDate(obj.results.bf_end_date);
                    setCurrency(obj.results.user_currency);
                    setNbMonth(obj.results.bf_nb_month);
                    setSite(obj.results.siteList);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    const loadOptions = (inputValue) => {
        if(!inputValue){
            return null;
        }

        return axios(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_getSiteList?q="+inputValue)
            .then(res => res.data)
            .then(data => data.results)
    };

    const handleSelection = value => {
        setSite(value);
    }

    const updateBusinessFinder = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_updateBusinessFinder", {
                'BusinessFinderId':id,
                'BusinessFinderUserId': userId,
                'BusinessFinderFirstname':firstname,
                'BusinessFinderLastname': lastname,
                'BusinessFinderEmail': email,
                'BusinessFinderRevshare': revshare,
                'BusinessFinderStartDate': startDate,
                'BusinessFinderEndDate': endDate,
                'BusinessFinderNbMonth': nbMonth,
                'BusinessFinderCurrency': currency,
                'BusinessFinderSite': site,
            });
            const obj = await resp.data;

            if (obj.status) {
                navigate("/BusinessFinderList");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={"BusinessFinderSetup GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className={"GridCol1-7 Width100"}>
                <input type="hidden" name={"BusinessFinderId"} id={"BusinessFinderId"} value={id}/>

                <div className="MB10">
                    User ID :
                    <input type="text" name={"BusinessFinderUserId"} id={"BusinessFinderUserId"}
                           className={"ActionInput P5"}
                           value={userId} onChange={(evt) => setUserId(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Lastname :
                    <input type="text" name={"BusinessFinderLastname"} id={"BusinessFinderLastname"}
                           className={"ActionInput P5"}
                           value={lastname} onChange={(evt) => setLastname(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Firstname :
                    <input type="text" name={"BusinessFinderFirstname"} id={"BusinessFinderFirstname"}
                           className={"ActionInput P5"}
                           value={firstname} onChange={(evt) => setFirstname(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Email :
                    <input type="text" name={"BusinessFinderEmail"} id={"BusinessFinderEmail"}
                           className={"ActionInput P5"}
                           value={email} onChange={(evt) => setEmail(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Revshare :
                    <input type="text" name={"BusinessFinderRevshare"} id={"BusinessFinderRevshare"}
                           className={"ActionInput P5"}
                           value={revshare} onChange={(evt) => setRevshare(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Currency :
                    <input type="radio" name={"BusinessFinderCurrency"} id={"BusinessFinderCurrencyEuro"}
                           className={"ActionInput P5"}
                           value="€"
                           checked={currency === "€"}
                           onChange={(evt) => setCurrency(evt.target.value)}/>
                    <label htmlFor={"BusinessFinderCurrencyEuro"} className={"MR10"}>Euro</label>
                    <input type="radio" name={"BusinessFinderCurrency"} id={"BusinessFinderCurrencyDollar"}
                           className={"ActionInput P5"}
                           value="$"
                           checked={currency === "$"}
                           onChange={(evt) => setCurrency(evt.target.value)}/>
                    <label htmlFor={"BusinessFinderCurrencyDollar"}>Dollar</label>
                </div>

                <div className="MB10">
                    Contract Start Date :
                    <input type="text" name={"BusinessFinderStartDate"} id={"BusinessFinderStartDate"}
                           className={"ActionInput P5"}
                           value={startDate} onChange={(evt) => setStartDate(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Contract End Date :
                    <input type="text" name={"BusinessFinderEndDate"} id={"BusinessFinderEndDate"}
                           className={"ActionInput P5"}
                           value={endDate} onChange={(evt) => setEndDate(evt.target.value)}/>
                </div>

                <div className="MB10">
                    Contract Duration (in month) :
                    <input type="text" name={"BusinessFinderNbMonth"} id={"BusinessFinderNbMonth"}
                           className={"ActionInput P5"}
                           value={nbMonth} onChange={(evt) => setNbMonth(evt.target.value)}/>
                </div>
            </div>
            <div className={"GridCol7-13 Width100"}>
                <div>Destinataires Moneytizer :</div>
                <AsyncSelect
                    className="BusinessFinderListSiteId"
                    cacheOptions
                    defaultOptions
                    value={site}
                    isMulti
                    getOptionLabel={e => {return "["+e.site_id+"] "+ e.site_url} }
                    getOptionValue={e => e.site_id}
                    loadOptions={loadOptions}
                    onChange={handleSelection}
                />
            </div>

            <div className={"EditBusiness"}>
                <button id="edit_business_finder" type="button" onClick={updateBusinessFinder}
                        className="ActionButton BGreen">
                    Validate
                </button>
            </div>
        </div>
    );
}

export default BusinessFinderSetup;