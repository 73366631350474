import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import { useState, useEffect } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import MagicTagSetup from "../../Components/MagicTag/MagicTagSetup/MagicTagSetup"
import SiteHeader from "../../Components/SiteHeader/SiteHeader"
import SiteTags from "../../Components/SiteTags/SiteTags";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"

function MagicTagSetupPage() {
    const pageTab = "magic_tag_setup";

    let { id } = useParams();

    const [siteID, setSiteID] = useState(false)
    const [refreshFlag, setRefreshFlag] = useState(false);

    const triggerRefresh = () => {
        setRefreshFlag(prev => !prev);
    };

    useEffect(() => {
        setSiteID(id);
    }, [id]);

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Setup Magic Tag" />
            <SiteHeader siteID={siteID} />
            <MagicTagSetup siteID={siteID} refreshFlag={refreshFlag} />
            <SiteTags siteID={siteID} triggerRefresh={triggerRefresh} />
        </div>
    );
}

export default MagicTagSetupPage;
