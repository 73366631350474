import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./FaqCategorySetup.css";
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";

function FaqCategorySetup(props) {
    const [lang, setLang] = useState([]);
    const [faqCategory, setFaqCategory] = useState([]);
    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getFaqCategoryDescByFaqCategoryId?id="+id);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results.lang);
                    setFaqCategory(obj.results.faqCategory);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    const updateFaqCategory = async (event) => {
        event.preventDefault();

        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            };

            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_updateFaqCategory", {
                'faqCategoryId':id,
                'faqCategory': faqCategory,
            }, config);
            const obj = await resp.data;

            console.log(obj);

            if (obj.status) {
                navigate("/faqList");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateName = function (id, content){
        let a = {...faqCategory};
        a[id].libelle = content;
        setFaqCategory(a);
    }

    const updateNameSEO = function (id, content){
        let a = {...faqCategory};
        a[id].libelle_seo = content;
        setFaqCategory(a);
    }

    const updateDescription = function (id, content){
        let a = {...faqCategory};
        a[id].description = content;
        setFaqCategory(a);
    }

    const updateImage = function (id, content){
        let a = {...faqCategory};
        a[id].img = content;
        setFaqCategory(a);
    }

    const updateStatus = function (id, content){
        let a = {...faqCategory};
        a[id].status = content;
        setFaqCategory(a);
    }

    useEffect(() => {
        console.log(faqCategory);
    }, [faqCategory]);

    return (
        <div className={"FaqCategorySetup GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <form onSubmit={updateFaqCategory}>
                <div>
                    {lang.map((row) => {
                        return <div>
                            <div className={"FWBold Underline MB10"}>{row.lang_name}</div>
                            <div>
                                <div className={"MB10"}>
                                    <div>Name :</div>
                                    <input type="text" name={"faqCategoryName[" + row.lang_id + "]"}
                                           className={"faqCategoryName ActionInput P5"}
                                           value={faqCategory[row.lang_id].libelle}
                                           onChange={(evt) => updateName(row.lang_id, evt.target.value)}/>
                                </div>
                                <div className={"MB10"}>
                                    <div>Name SEO :</div>
                                    <input type="text" name={"faqCategoryNameSEO[" + row.lang_id + "]"}
                                           className={"faqCategoryNameSEO ActionInput P5"}
                                           value={faqCategory[row.lang_id].libelle_seo}
                                           onChange={(evt) => updateNameSEO(row.lang_id, evt.target.value)}/>
                                </div>
                                <div className={"MB10"}>
                                    <div>Description :</div>
                                    <input type="text" name={"faqCategoryDescription[" + row.lang_id + "]"}
                                           className={"faqCategoryDescription ActionInput P5"}
                                           value={faqCategory[row.lang_id].description}
                                           onChange={(evt) => updateDescription(row.lang_id, evt.target.value)}/>
                                </div>
                                <div className={"MB10"}>
                                    <div>Image :</div>
                                    <input type="file" name={"faqCategoryFileImage[" + row.lang_id + "]"}
                                           className={"faqCategoryFileImage ActionInput P5"}
                                           onChange={(evt) => updateImage(row.lang_id, evt.target.files[0])}/>
                                    <input type="hidden" name={"faqCategoryImage[" + row.lang_id + "]"}
                                           className={"faqCategoryImage ActionInput P5"}
                                           value={faqCategory[row.lang_id].img}/>
                                </div>
                                <div className={"MB10"}>
                                    <div>Status :</div>
                                    <input type="radio" name={"faqCategoryStatus[" + row.lang_id + "]"}
                                           id={"faqCategoryStatusOnline" + row.lang_id}
                                           className={"ActionInput P5"}
                                           value={1}
                                           checked={faqCategory[row.lang_id].status === 1}
                                           onChange={(evt) => updateStatus(row.lang_id, parseInt(evt.target.value))}/>
                                    <label htmlFor={"faqCategoryStatusOnline" + row.lang_id} className={"MR10"}>Online</label>
                                    <input type="radio" name={"faqCategoryStatus[" + row.lang_id + "]"}
                                           id={"faqCategoryStatusOffline" + row.lang_id}
                                           className={"ActionInput P5"}
                                           value={0}
                                           checked={faqCategory[row.lang_id].status === 0}
                                           onChange={(evt) => updateStatus(row.lang_id, parseInt(evt.target.value))}/>
                                    <label htmlFor={"faqCategoryStatusOffline" + row.lang_id}
                                           className={"MR10"}>Offline</label>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    })}
                </div>

                <button id="edit_faq_category" type="submit" className="btn btn-md btn-success">
                    Validate
                </button>
            </form>
        </div>
    );
}

export default FaqCategorySetup;