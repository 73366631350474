import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import React, { useState, useEffect } from "react";
import './Notices.css';
import PendingNotices from "../../Components/PendingNotices/PendingNotices";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { ToastContainer } from "react-toastify";
import ValidatedNotices from "../../Components/ValidatedNotices/ValidatedNotices";
import RefusedNotices from "../../Components/RefusedNotices/RefusedNotices";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"

function Notices() {
    const pageTab = "notices";
    const [sButton, setSButton] = useState(1);
    const [noticesList, setNoticesList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getNotices");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setNoticesList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HeadLine childClass="Headline GridCol1-13" title="Notices" />
            <LateralNavComponent pageTab={pageTab} />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <div className="FlexRow Gap5">
                    <button onClick={() => setSButton(1)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 1 ? "SelectedButton" : "")}>
                        Pending Notices
                    </button>
                    <button onClick={() => setSButton(2)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 2 ? "SelectedButton" : "")}>
                        Validated Notices
                    </button>
                    <button onClick={() => setSButton(3)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 3 ? "SelectedButton" : "")}>
                        Refused Notices
                    </button>
                </div>
            </div>
            <PendingNotices noticesList={noticesList} childClass={sButton === 1 ? '' : 'Hide'} />
            <ValidatedNotices noticesList={noticesList} childClass={sButton === 2 ? '' : 'Hide'} />
            <RefusedNotices noticesList={noticesList} childClass={sButton === 3 ? '' : 'Hide'} />
        </div>
    )
}

export default Notices;
