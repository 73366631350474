import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { toast } from "react-toastify";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function formatDate(date) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function PendingNotices(props) {
    const adm_id = localStorage.getItem('adm_id');
    const [pendingNoticesList, setPendingNoticesList] = useState([]);
    const [activeNoticeId, setActiveNoticeId] = useState(null);
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        setPendingNoticesList(props.noticesList);
    }, [props.noticesList]);

    useEffect(() => {
        if (!activeNoticeId) {
            setGraphData(null);
            return;
        }

        const fetchGraphData = async () => {
            try {
                const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getUserRevenueAndCPMByNotice`, {
                    params: {
                        notice_id: activeNoticeId
                    }
                });
                const obj = response.data;
                if (obj.status && obj.results) {
                    const labels = obj.results.map(row => row.sp_date);
                    const cpmData = obj.results.map(row => row.cpm);
                    const revenueData = obj.results.map(row => row.revenue);

                    setGraphData({
                        labels,
                        datasets: [
                            {
                                label: "CPM",
                                data: cpmData,
                                borderColor: "rgb(255, 127, 14)",
                                backgroundColor: "rgb(255, 127, 14)",
                                yAxisID: 'y1',
                            },
                            {
                                label: "Revenues",
                                data: revenueData,
                                borderColor: "rgb(31, 119, 180)",
                                backgroundColor: "rgb(31, 119, 180)",
                                yAxisID: 'y2',
                            },
                        ]
                    });
                }
            } catch (error) {
                console.error("Error fetching graph data:", error);
            }
        };

        fetchGraphData();
    }, [activeNoticeId]);

    const updateNotice = async (noticeId, status) => {
        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_updateNotice`, {
                notice_id: noticeId,
                status: status,
                adm_id: adm_id
            });
            const obj = response.data;
            if (obj.status) {
                toast.success('Notice updated successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setPendingNoticesList(pendingNoticesList.filter(notice => notice.id !== noticeId));
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("There was an error updating the notice:", error);
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const getSiteIdForUserId = async (user_id) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteIdForUserId`, {
                params: { user_id: user_id },
            });

            if (response.data.status && response.data.results) {
                const siteId = response.data.results;
                handleMoneyboxAccess(siteId);
            } else {
                toast.error('Failed to connect to Moneybox', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error connecting to Moneybox:", error);
            toast.error('Error connecting to Moneybox', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleMoneyboxAccess = async (siteId) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxToken`, {
                params: { site_id: siteId },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&site_id=" + siteId + "&tbm_hash=" + response.data.results.tbm_hash;
                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleMoneyboxUserIdSubmit = (user_id) => {
        getSiteIdForUserId(user_id);
    };

    const toggleStatistics = (noticeId) => {
        if (activeNoticeId !== noticeId) {
            setGraphData(null);
            setActiveNoticeId(noticeId);
        } else {
            setActiveNoticeId(null);
        }
    };

    return (
        <div className={"PendingNotices GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'></th>
                        <th className='P5'>User ID</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>Start date</th>
                        <th className='P5'>Delay (day)</th>
                        <th className='P5'>End date</th>
                        <th className="P5">Shortcuts</th>
                        <th className="P5">Validation</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pendingNoticesList
                            .filter(row => {
                                return row.status === 0;
                            })
                            .map((row, key) => {
                                const startDate = new Date(row.start_date);
                                const endDate = addDays(startDate, row.delay_day);
                                return (
                                    <>
                                        <tr key={row.id}>
                                            <td className='P15 TCenter'>
                                                <button
                                                    className="ActionButton ML5 BBlue Height30px MWidthMaxContent"
                                                    onClick={() => toggleStatistics(row.id)}
                                                >
                                                    <i className={`fa-solid ${activeNoticeId === row.id ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                                    &nbsp;See Statistics
                                                </button>
                                            </td>
                                            <td className='P15'>{row.user_id}</td>
                                            <td className='P15'>{row.user_mail}</td>
                                            <td className='P15'>{formatDate(startDate)}</td>
                                            <td className='P15'>{row.delay_day}</td>
                                            <td className='P15'>{formatDate(endDate)}</td>
                                            <td className='P15 TCenter FlexRow'>
                                                <button className="ActionButton ML5 BGreen Height30px" onClick={() => handleMoneyboxUserIdSubmit(row.user_id)}>
                                                    <i className="fa-solid fa-piggy-bank"></i>
                                                </button>
                                                <button
                                                    className="ActionButton ML5 BBlue Height30px"
                                                    onClick={() => window.open("https://crm.themoneytizer.com/crm_user/" + row.user_id, "_blank", "noopener noreferrer")}
                                                >
                                                    <i className="fa-solid fa-database"></i>
                                                </button>
                                            </td>
                                            <td className='P15 TCenter'>
                                                <button
                                                    className="ActionButton ML5 BGreen Height30px WidthPx30"
                                                    onClick={() => updateNotice(row.id, 1)}
                                                >
                                                    <i className="fa-solid fa-check"></i>
                                                </button>
                                                <button
                                                    className="ActionButton ML5 BRed Height30px WidthPx30"
                                                    onClick={() => updateNotice(row.id, 2)}
                                                >
                                                    <i className="fa-solid fa-xmark"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        {activeNoticeId === row.id && (
                                            <tr key={`${row.id}-stats`}>
                                                <td colSpan="8" className="P15">
                                                    <div className="ChartPlaceholder">
                                                        {graphData ? (
                                                            <Line
                                                                data={graphData}
                                                                height={300}
                                                                options={{
                                                                    maintainAspectRatio: false,
                                                                    responsive: true,
                                                                    scales: {
                                                                        x: {
                                                                            title: {
                                                                                display: true,
                                                                                text: 'Date'
                                                                            }
                                                                        },
                                                                        y1: {
                                                                            type: 'linear',
                                                                            display: true,
                                                                            position: 'right',
                                                                            title: {
                                                                                display: true,
                                                                                text: 'CPM'
                                                                            }
                                                                        },
                                                                        y2: {
                                                                            type: 'linear',
                                                                            display: true,
                                                                            position: 'left',
                                                                            title: {
                                                                                display: true,
                                                                                text: 'Revenues'
                                                                            },
                                                                            grid: {
                                                                                drawOnChartArea: false,
                                                                            },
                                                                        },
                                                                    }
                                                                }}
                                                            />
                                                        ) : 'Loading chart...'}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                );
                            })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default PendingNotices;
