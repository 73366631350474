import { useState, useEffect } from "react";

function formatDate(date) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function ValidatedNotices(props) {
    const [validatedNoticesList, setValidatedNoticesList] = useState([]);

    useEffect(() => {
        setValidatedNoticesList(props.noticesList);
    }, [props.noticesList]);

    return (
        <div className={"ValidatedNotices GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>User ID</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>Start date</th>
                        <th className='P5'>Delay (day)</th>
                        <th className='P5'>End date</th>
                        <th className="P5">Validation date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        validatedNoticesList
                            .filter(row => {
                                return row.status === 1;
                            })
                            .map((row, key) => {
                                const startDate = new Date(row.start_date);
                                const endDate = addDays(startDate, row.delay_day);
                                const validationDate = new Date(row.validation_date);
                                return (
                                    <tr key={key}>
                                        <td className='P15'>{row.user_id}</td>
                                        <td className='P15'>{row.user_mail}</td>
                                        <td className='P15'>{formatDate(startDate)}</td>
                                        <td className='P15'>{row.delay_day}</td>
                                        <td className='P15'>{formatDate(endDate)}</td>
                                        <td className="P15">{formatDate(validationDate)}</td>
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ValidatedNotices;
