import './AddAdmin.css';
import { useState } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from '../../Constant';
axios.defaults.withCredentials = true;


function AddAdmin(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const addNewWorker = async () => {
        if(!email||email.length<3){
            return;
        }

        if(!name||name.length<3){
            return;
        }

        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_admin/bo_addWorker", {
                email: email,
                name: name
            });
            const obj = await resp.data;
            if (obj.status && obj.results) {
                if (obj.status && obj.results) {
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={"AddAdmin GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2>Add a new worker</h2>
            <div className='FlexRow'>
                <input type="text" value={email} onChange={(e) => {setEmail(e.target.value)}} className="ActionInput P10" placeholder="Email"/>
                <input type="text" value={name} onChange={(e) => {setName(e.target.value)}} className="ActionInput P10 ML5" placeholder="Name"/>
                <button onClick={() => {addNewWorker()}} className="ActionButton BRed ML10">Add worker</button>
            </div>
        </div>
    );
}

export default AddAdmin;
