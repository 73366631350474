import './LateralNavComponent.css';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ROOT_DOMAIN_API } from '../../Constant';


function LateralNavComponent(props) {
  const [toggleLateralNavBar, setToggleLateralNavBar] = useState(false);
  const [countPassback, setCountPassback] = useState(0);
  
  const handleToggleLateralNavBar = function () {
    if (toggleLateralNavBar) {
      setToggleLateralNavBar(false);
      return;
    }
    setToggleLateralNavBar(true);
  }

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getCountWaitingPassback`);
        const obj = await resp.data;
        if (obj.status && obj.results) {
          setCountPassback(obj.results.passback_nb);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div>
      <nav className={"LateralNavbar " + (toggleLateralNavBar ? "" : "Hide")}>
        <div className="CloseContainer">
          <button onClick={handleToggleLateralNavBar}><i className="fa-solid fa-xmark"></i></button>
        </div>
        <ul>
          <li className={props.pageTab === "home" ? "Highlight" : ""}>
            <Link to="/"><i className="fa-solid fa-link"></i> Tools & links</Link>
          </li>
          <li className={props.pageTab === "manage_websites" ? "Highlight" : ""}>
            <Link to="/websites"><i className="fa-solid fa-file-invoice-dollar"></i> Manage websites</Link>
          </li>
          <li className={props.pageTab === "notices" ? "Highlight" : ""}>
            <Link to="/notices"><i className="fa-solid fa-ban"></i> Notices</Link>
          </li>
          <li className={props.pageTab === "websites_tracker" ? "Highlight" : ""}>
            <Link to="/websitesTracker"><i className="fa-solid fa-chart-line"></i> Websites Tracker</Link>
          </li>
          <li className={props.pageTab === "cream_of_the_cream" ? "Highlight" : ""}>
            <Link to="/creamOfTheCream"><i className="fa-solid fa-trophy"></i> Cream of the Cream</Link>
          </li>
          <li className={props.pageTab === "passback" ? "Highlight" : ""}>
            <div className='FlexRow AlignCenter'>
              <Link to="/passback"><i className="fa-solid fa-undo"></i> Passback</Link>
              {countPassback > 0 &&
                  <div
                      className='BRed P5 ML10 SmallFont TWhite WidthPx10 Height10px FlexRow JustifyCenter AlignCenter BorderRad50'>
                    {countPassback}
                  </div>
              }
            </div>
          </li>
          <li className={props.pageTab === "codir" ? "Highlight" : ""}>
            <Link to="/codir"><i className="fa-solid fa-building-columns"></i> Codir</Link>
          </li>
          <li className={props.pageTab === "overview_plateform" ? "Highlight" : ""}>
            <Link to="/overviewPlateform"><i className="fa-solid fa-earth-americas"></i> Overview Plateform</Link>
          </li>
          <li className={props.pageTab === "imports" ? "Highlight" : ""}>
            <Link to="/imports"><i className="fa-solid fa-file-import"></i> Imports</Link>
          </li>
          <li className={props.pageTab === "manage_team" ? "Highlight" : ""}>
            <Link to="/editWorkers"><i className="fa-solid fa-mug-hot"></i> Manage team</Link>
          </li>
          <li className={props.pageTab === "pending_approval" ? "Highlight" : ""}>
            <Link to="/pendingApproval"><i className="fa-solid fa-gavel"></i> Pending Approval</Link>
          </li>
          <li className={props.pageTab === "informations" ? "Highlight" : ""}>
            <Link to="/informations"><i className="fa-solid fa-bookmark"></i> Informations</Link>
          </li>
          <li className={props.pageTab === "ad_reporter" ? "Highlight" : ""}>
            <Link to="/adReporter"><i className="fa-solid fa-exclamation-triangle"></i> Ad Reporter</Link>
          </li>
          <li className={props.pageTab === "magic_tag_list" ? "Highlight" : ""}>
            <Link to="/magicTagList"><i className="fa-solid fa-wand-magic-sparkles"></i> Magic Tag</Link>
          </li>
          <li className={props.pageTab === "tools" ? "Highlight" : ""}>
            <Link to="/tools"><i className="fa-solid fa-wrench"></i> Tools</Link>
          </li>
          <li className={props.pageTab === "translations" ? "Highlight" : ""}>
            <Link to="/translations"><i className="fa-solid fa-comment-dots"></i> Translations</Link>
          </li>
          <li className={props.pageTab === "perfPartners" ? "Highlight" : ""}>
            <Link to="/perfPartners"><i className="fa-solid fa-chart-simple"></i> Perf Partners</Link>
          </li>
          <li className={props.pageTab === "TextMail" ? "Highlight" : ""}>
            <Link to="/TextMailList"><i className="fa-solid fa-envelope"></i> Text Mail</Link>
          </li>
          <li className={props.pageTab === "BusinessFinder" ? "Highlight" : ""}>
            <Link to="/businessFinderList"><i className="fa-solid fa-briefcase"></i> Business Finder</Link>
          </li>
          <li className={props.pageTab === "Guide" ? "Highlight" : ""}>
            <Link to="/guideList"><i className="fa-solid fa-book"></i> Guide</Link>
          </li>
          <li className={props.pageTab === "Faq" ? "Highlight" : ""}>
            <Link to="/faqList"><i className="fa-solid fa-circle-question"></i> FAQ</Link>
          </li>
          <li className={props.pageTab === "Notification" ? "Highlight" : ""}>
            <Link to="/notificationList"><i className="fa-solid fa-bell"></i> Notification</Link>
          </li>
          <li className={props.pageTab === "logout" ? "Highlight" : ""}>
            <Link to="/logout"><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</Link>
          </li>
        </ul>
      </nav>
      <button onClick={handleToggleLateralNavBar} className={"BurgerMenu " + (toggleLateralNavBar ? "Hide" : "")}><i
          className="fa-solid fa-bars"></i></button>
    </div>
  );
}

export default LateralNavComponent;
