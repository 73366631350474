import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './CronExecuted.css';
import {useEffect, useState} from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { Chart } from "react-google-charts";
import {format} from "date-fns";
import {DayPicker} from "react-day-picker";

function CronExecuted() {
    const pageTab = "cron_executed";
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);

    const ssp_date = new Date().setDate(new Date().getDate() - 2);

    const [dayPickerSSP, setDayPickerSSP] = useState(false);
    const [selectedDaySSP, setSelectedDaySSP] = useState(format(ssp_date, 'yyyy-MM-dd'));
    const [sD1Formatted, setSD1Formatted] = useState(format(ssp_date, 'yyyy-MM-dd'));

    const toggleDayPickerSSP = function(){
        if(dayPickerSSP){
            setDayPickerSSP(false);
        } else {
            setDayPickerSSP(true);
        }
    }

    useEffect(() => {
        setSD1Formatted(format(new Date(selectedDaySSP), 'yyyy-MM-dd'));
        setDayPickerSSP(false);

        (async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API+"/manage/v1/bo_user/bo_cronExecuted?cron_date="+format(new Date(selectedDaySSP), 'yyyy-MM-dd'));
                const obj = resp.data;
                if (obj.status && obj.results) {
                    console.log(obj.results);

                    var lines = [];
                    for (var i = 0; i < obj.results.length; i++){
                        var libelle = obj.results[i].libelle;
                        var status = obj.results[i].status;
                        var start_date = new Date(obj.results[i].start_year, obj.results[i].start_month - 1, obj.results[i].start_day, obj.results[i].start_hour, obj.results[i].start_minute, obj.results[i].start_second);
                        var end_date = new Date(obj.results[i].end_year, obj.results[i].end_month - 1, obj.results[i].end_day, obj.results[i].end_hour, obj.results[i].end_minute, obj.results[i].end_second);

                        var duration = (end_date.getTime() - start_date.getTime()) / 1000;
                        var hours = parseInt( duration / 3600 ) % 24;
                        var minutes = parseInt( duration / 60 ) % 60;
                        var seconds = duration % 60;

                        var tooltip = '<div style="width: 300px; padding: 15px; font-size: 14px; color: black;"><div class="ggl-tooltip"><span style="font-weight: bold;">' +
                            libelle + '</span></div><div style="border: 1px black solid; margin: 10px 0;"></div><div class="ggl-tooltip"><span>' +
                            format(new Date(start_date), 'HH:mm:ss') + '</div><div class="ggl-tooltip"><span>' +
                            format(new Date(end_date), 'HH:mm:ss') + '</span></div>' +
                            '<div class="ggl-tooltip"><span style="font-weight: bold;">Duration: </span>' +
                            hours + 'h ' + minutes + 'm ' + seconds + 's </div>';

                        lines.push([
                            libelle,
                            status,
                            tooltip,
                            start_date,
                            end_date,
                        ]);
                    }

                    const columns = [
                        { type: "string", id: "Cron tasks" },
                        { type: "string", id: "Error cron tasks" },
                        { type: "string", role: "tooltip" },
                        { type: "date", id: "Start" },
                        { type: "date", id: "End" },
                    ];

                    const rows = lines;

                    setData([columns, ...rows]);
                    setOptions({
                        allowHtml: true,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [selectedDaySSP]);

    return (
        <div className="CronExecuted GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Cron Executed" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <h2>Cron Executed</h2>

                <div className="FlexRow MB20">
                    <div className="FlexCol MR10">
                        <button className="ActionButton BRed" onClick={() => toggleDayPickerSSP()}>
                            <i className="fa-solid fa-calendar"></i>&nbsp;From {sD1Formatted}</button>
                        <DayPicker
                            format="M/D/YYYY"
                            className={"DayPickerObject " + (dayPickerSSP ? '' : 'Hide')}
                            mode="single"
                            selected={selectedDaySSP}
                            onSelect={setSelectedDaySSP}
                        />
                    </div>
                </div>

                <Chart chartType="Timeline" data={data} options={options} width="100%" height="400px"/>
            </div>
        </div>
    );
}

export default CronExecuted;