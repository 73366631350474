import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './AdReporter.css';
import { useState } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import AdReportList from "../../Components/AdReportList/AdReportList";
import AdReportUser from "../../Components/AdReportUser/AdReportUser";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"


function AdReporter() {
    const pageTab = "ad_reporter";
    const [sButton, setSButton] = useState(1);

    return (
        <div className="GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Ad Reporter administration" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <div className="FlexRow Gap5">
                    <button onClick={() => setSButton(1)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 1 ? "SelectedButton" : "")}>
                        Ad Report List
                    </button>
                    <button onClick={() => setSButton(2)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 2 ? "SelectedButton" : "")}>
                        Manage Users
                    </button>
                </div>
            </div>
            <AdReportList childClass={sButton === 1 ? '' : 'Hide'} />
            <AdReportUser childClass={sButton === 2 ? '' : 'Hide'} />
        </div>
    );
}

export default AdReporter;
