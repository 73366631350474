import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './ManageSites.css';
import { useState } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import WaitingSites from "../../Components/WaitingSites/WaitingSites";
import AcceptedSites from "../../Components/AcceptedSites/AcceptedSites";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import RefusedSites from "../../Components/RefusedSites/RefusedSites";
import PutAsideSites from "../../Components/PutAsideSites/PutAsideSites";

function ManageSites() {
    const pageTab = "manage_websites";
    const [sButton, setSButton] = useState(2);

    return (
        <div className="ManageSites GridTemplate12">
            <AuthComponent />

            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Manage sites" />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <div className="FlexRow Gap5">
                    <button onClick={() => setSButton(1)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 1 ? "SelectedButton" : "")}>
                        Waiting sites
                    </button>
                    <button onClick={() => setSButton(2)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 2 ? "SelectedButton" : "")}>
                        Accepted sites
                    </button>
                    <button onClick={() => setSButton(3)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 3 ? "SelectedButton" : "")}>
                        Put Aside
                    </button>
                    <button onClick={() => setSButton(4)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 4 ? "SelectedButton" : "")}>
                        Refused sites
                    </button>
                </div>
            </div>
            <WaitingSites childClass={sButton === 1 ? '' : 'Hide'} />
            <AcceptedSites childClass={sButton === 2 ? '' : 'Hide'} />
            <PutAsideSites childClass={sButton === 3 ? '' : 'Hide'} />
            <RefusedSites childClass={sButton === 4 ? '' : 'Hide'} />
        </div>
    );
}

export default ManageSites;
