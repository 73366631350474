import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../Constant";
import "react-toastify/dist/ReactToastify.css";

function SiteWaitingList(props) {
    const [siteIds, setSiteIds] = useState("");

    const handleUpdate = async () => {
        const siteIdsArray = siteIds.split(",").map(id => parseInt(id.trim(), 10)).filter(id => !isNaN(id));

        if (siteIdsArray.length === 0) {
            toast.error('Please enter valid Site IDs', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_addSiteBackOnWaitingList`, {
                site_ids: siteIdsArray,
            });

            if (response.data.status) {
                if (response.data.results === "success") {
                    toast.success('Sites successfully added to Waiting Sites', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error('Failed to update sites', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding sites to Waiting List:", error);
            toast.error('An error occurred while adding sites to Waiting List', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={"SiteWaitingList GridCol1-7 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className='MT0 MB5'>Put Site ID back on Waiting Sites</h2>
            <div className="FlexCol">
                <div className="FlexCol MB10">
                    <label>Site IDs</label>
                    <input
                        type="text"
                        className="ActionInput P5"
                        name="site_id_pack"
                        value={siteIds}
                        placeholder="Enter Site IDs separated by commas"
                        onChange={(e) => setSiteIds(e.target.value)}
                    />
                </div>
                <div className="FlexRow">
                    <button className="ActionButton BGreen" onClick={handleUpdate}>Add to Waiting Sites</button>
                </div>
            </div>
        </div>
    );
}

export default SiteWaitingList;
