import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
axios.defaults.withCredentials = true;

function AuthComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_ping`);
        const { data } = response;

        if (data.error !== "GENERIC_SUCCESS") {
          navigate('/login/', { replace: true });
        } else {
          const { username, adm_id } = data.results || {};
          localStorage.setItem('global_name', username);
          localStorage.setItem('adm_id', adm_id);
        }
      } catch (error) {
        console.error(error);
        navigate('/login/', { replace: true });
      }
    };

    checkAuth();

    const intervalId = setInterval(checkAuth, 240000); // 4 min

    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <div className="Auth"></div>
  );
}

export default AuthComponent;
