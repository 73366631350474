import "./SitePartnersState.css"
import { useState, useEffect } from "react";
import axios from "axios";
import SitePartnerStateRow from "./SitePartnerStateRow/SitePartnerStateRow";
import { ROOT_DOMAIN_API } from "../../Constant";
import { toast } from "react-toastify";
import { BarLoader } from "react-spinners";

function SitePartnersState(props) {
    const [partnersState, setPartnersState] = useState([]);
    const [availablePartnerState, setAvailablePartnerState] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);


    useEffect(() => {
        setAvailablePartnerState([
            {
                id: -2,
                name: "Stand By"
            },
            {
                id: -1,
                name: "Pending Form"
            },
            {
                id: 0,
                name: "Pending"
            },
            {
                id: 1,
                name: "Accepted"
            },
            {
                id: 2,
                name: "Refused"
            }
        ])

        const fetchPartnersState = async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                };
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_partnersStateList", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setPartnersState(obj.results);

                }
            } catch (error) {
                console.error(error);
            }
        };

        if (props.siteID) {
            fetchPartnersState();
        }
    }, [props.siteID]);

    function updatePartnerState(siteId, partnerName, partnerState) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updatePartnerState", { site_id: siteId, partner_name: partnerName, partner_state: partnerState })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to update the state');
                }

                const updatedPartnersState = partnersState.map(partner => {
                    if (partner.partner_name === partnerName) {
                        var partNameObj = 'part_' + partnerName;
                        partner[partNameObj] = partnerState;
                    }
                    return partner;
                });
                setPartnersState(updatedPartnersState);
                toast.success('Partner state updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    const getClassByValue = (value) => {
        const classMap = {
            '2': 'BVerySoftRed',
            '-1': 'BSoftBlue',
            '0': 'BSoftYellow',
            '-2': 'BSoftRock',
            '1': 'BSoftGreen'
        };
        return classMap[value] || '';
    };

    return (
        <div className={"SitePartnersState GridCol1-7 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className="MT0">Partners State</h2>
            <div className="FlexRow Gap10 MB10">
                <div className="BVerySoftRed BorderRad3 Px10 Py5">Refused</div>
                <div className="BSoftBlue BorderRad3 Px10 Py5">Pending Form</div>
                <div className="BSoftYellow BorderRad3 Px10 Py5">Pending</div>
                <div className="BSoftRock BorderRad3 Px10 Py5">Stand By</div>
                <div className="BSoftGreen BorderRad3 Px10 Py5">Accepted</div>
            </div>
            <div className="FlexColumn P5 SitePartnersStateContainer" >
                {partnersState.length !== 0 ? partnersState.map((row, key) => {
                    return <SitePartnerStateRow key={key}
                        partnerName={row.partner_name}
                        partnerNameDisplay2={row.partner_name_display2}
                        backgroundColorState={getClassByValue(row['part_' + row.partner_name])}
                        siteId={props.siteID}
                        availablePartnerState={availablePartnerState}
                        updatePartnerState={updatePartnerState} />
                }) :
                    <BarLoader height={8} color="#f41127" />
                }
            </div>
        </div>
    );
}

export default SitePartnersState;
