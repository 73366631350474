import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { ROOT_DOMAIN_API } from "../../Constant";
import { BarLoader } from "react-spinners";

function CACodir(props) {
    const [loading, setLoading] = useState(true);
    const [ca, setCa] = useState({ revenue: 0, revenue_private: 0 });
    const [activeSites, setActiveSites] = useState(0);
    const [totalSites, setTotalSites] = useState(0);


    useEffect(() => {
        const fetchActiveSites = async () => {
            setLoading(true);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_activeSites`);
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setActiveSites(obj.results.activeSites);
                    setTotalSites(obj.results.totalSites);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
  
        fetchActiveSites();
    }, []);
  

    const fetchCa = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getCaByDate`, {
                params: {
                    date_from: props.sDate,
                    date_to: props.eDate,
                }
            });
            const obj = response.data;
            if (obj.status && obj.results) {
                setCa(obj.results);
            }
        } catch (error) {
            console.error("Error fetching CA data:", error);
        } finally {
            setLoading(false);
        }
    }, [props.sDate, props.eDate]);

    useEffect(() => {
        fetchCa();
    }, [fetchCa]);

    const formatNumber = (num) => {
        return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const getActivePercentage = function() {
        if (totalSites === 0) return 0;
        return ((activeSites / totalSites) * 100).toFixed(2);
    };

    return (
        <div className="GridCol1-13 FlexCol JustifyBetween Gap20">
            <div className={"CACodir Width75 ShadowContainer BRed P20 TWhite"}>
                <h2 className="M0 MT5 MB5">CA for this period</h2>
                {loading ? (
                    <BarLoader height={8} color="#f5f5f5" />
                ) : (
                    <span className="MediumFont TWhite FWBold">{formatNumber(ca.revenue_private)} €</span>
                )}
            </div>
            <div className={"CACodir Width75 ShadowContainer BLightWhite P20"}>
                <h2 className="M0 MT5 MB5">Gross Margin</h2>
                {loading ? (
                    <BarLoader height={8} color="#f41127" />
                ) : (
                    <span className="MediumFont TGrey FWBold">{formatNumber(ca.revenue_private - ca.revenue)} €</span>
                )}
            </div>
            <div className={"CACodir Width75 ShadowContainer BLightWhite P20"}>
                <h2 className="M0 MT5 MB5">Margin Rate</h2>
                {loading ? (
                    <BarLoader height={8} color="#f41127" />
                ) : (
                    <span className="MediumFont TGrey FWBold">
                        {ca.revenue_private !== 0 ? ((ca.revenue_private - ca.revenue) / ca.revenue_private * 100).toFixed(2).replace('.', ',') : 0} %
                    </span>
                )}
            </div>
            <div className={"CACodir Width75 ShadowContainer BLightWhite P20"}>
                <h2 className="M0 MT5 MB5">Active websites J-2</h2>
                {loading ? (
                    <BarLoader height={8} color="#f41127" />
                ) : (
                    <>
                        <span className="MediumFont TGrey FWBold">{activeSites} / {totalSites}</span><br/>
                        <span className="MediumFont TGrey FWBold">{getActivePercentage()}% active website</span>
                    </>
                )}
            </div>
        </div>
    );
}

export default CACodir;
