import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import React from "react";
import { ToastContainer } from "react-toastify";
import TextMailSetup from "../../Components/TextMail/TextMailSetup"
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import "./TextMailSetup.css"

function TextMailSetupPage() {
    const pageTab = "TextMail";

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HeadLine childClass="Headline GridCol1-13" title="Text Mail" />
            <LateralNavComponent pageTab={pageTab} />
            <TextMailSetup />
        </div>
    )
}

export default TextMailSetupPage;