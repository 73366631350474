import { ToastContainer } from "react-toastify";
import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import ImportSsp from "../../Components/ImportSsp/ImportSsp";

import ImportQuality from "../../Components/ImportQuality/ImportQuality"
import ImportNoAdserver from "../../Components/ImportNoAdserver/ImportNoAdserver";

function Imports() {
    const pageTab = "imports";

    return (
        <div className={"Imports GridTemplate12"}>
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title="Imports" />
            <ImportSsp />
            <ImportQuality />
            <ImportNoAdserver />
    </div>
    );
}

export default Imports;
