import './SitePartnerStateRow.css';
import 'react-tooltip/dist/react-tooltip.css'


function SitePartnerStateRow(props) {
    return (
        <div key={props.partnerName} className={`BorderRad6 MB10 P10 FlexRow JustifyBetween TBlack Height25px ${props.backgroundColorState}`}>
            <div>{props.partnerNameDisplay2}</div>
            <div className='dropdown'>
                <button className="ActionButton BOrange">Edit state <i className="fa-solid fa-chevron-down TWhite"></i></button>
                <div className="dropdown-content BorderRad6">
                    {props.availablePartnerState.map((row, key) => {
                        return <button key={key} onClick={() => props.updatePartnerState(props.siteId, props.partnerName, row.id)}><span className='TGrey'>{row.id}</span> - {row.name}</button>
                    })}
                </div>
            </div>

        </div>
    );
}

export default SitePartnerStateRow;
