import { useState, useEffect } from "react";

function InformationsFormats(props) {
    const [informationsList, setInformationsList] = useState([]);
    const [informationsListSubcategories, setInformationsListSubcategories] = useState([]);


    useEffect(() => {
        setInformationsList(props.informationsList);
        setInformationsListSubcategories(props.informationsListSubcategories);
    }, [props.informationsList, props.informationsListSubcategories]);

    return (
        <div className={"InformationsCategories ShadowContainer GridCol1-13 GridTemplate12 P25 BWhite " + props.childClass}>
            <div className="GridCol1-7 ">
                <h2 className="">Categories</h2>
                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>ID</th>
                            <th className='P5'>Category</th>
                            <th className='P5'>Nuggad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            informationsList
                                .map((row, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className='P15'>{row.theme_id}</td>
                                            <td className='P15'>{row.theme_name}</td>
                                            <td className='P15'>{row.theme_nugg}</td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
            <div className="GridCol7-13">
            <h2 className="">Sub Categories</h2>

                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>ID</th>
                            <th className='P5'>Sub Category</th>
                            <th className='P5'>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            informationsListSubcategories
                                .map((row, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className='P15'>{row.subtheme_id}</td>
                                            <td className='P15'>{row.subtheme_name}</td>
                                            <td className='P15'>{row.theme_name}</td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default InformationsFormats;
