import './WaitingSites.css';
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import WaitingSitesRow from './WaitingSitesRow/WaitingSitesRow';
import { ROOT_DOMAIN_API } from '../../Constant';

const platforms = ['ww', 'us', 'pt', 'es', 'it', 'jp', 'de', 'ru'];

function WaitingSites(props) {
    const [waitingSitesList, setWaitingSitesList] = useState([]);
    const [themesList, setThemesList] = useState([]);
    const [siteCountryList, setSiteCountryList] = useState([]);
    const [raisonPutAsideList, setRaisonPutAsideList] = useState([]);
    const [reasonRefuseList, setReasonRefuseList] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);

    useEffect(() => {
        async function fetchData() {
            if (!waitingSitesList.length && !themesList.length && !siteCountryList.length && !raisonPutAsideList.length && !reasonRefuseList.length) {
                try {
                    const sitesResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/waitingSites");
                    const themesResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/themesList");
                    const siteCountryResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_siteCountryList");
                    const raisonPutAsideResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getRaisonPutAside");
                    const reasonRefuseResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getReasonRefuse");
                    if (sitesResponse.data.status && themesResponse.data.status && siteCountryResponse.data.status && raisonPutAsideResponse.data.status && reasonRefuseResponse.data.status) {
                        setWaitingSitesList(sitesResponse.data.results);
                        setThemesList(themesResponse.data.results);
                        setSiteCountryList(siteCountryResponse.data.results);
                        setRaisonPutAsideList(raisonPutAsideResponse.data.results);
                        setReasonRefuseList(reasonRefuseResponse.data.results);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchData();
    }, [themesList, waitingSitesList, siteCountryList, raisonPutAsideList, reasonRefuseList]);

    const handleSiteAction = (siteId) => {
        setWaitingSitesList(prevSites => prevSites.filter(site => site.site_id !== siteId));
    };

    const handlePlatformChange = useCallback((platform) => {
        setSelectedPlatforms(prevPlatforms =>
            prevPlatforms.includes(platform)
                ? prevPlatforms.filter(p => p !== platform)
                : [...prevPlatforms, platform]
        );
    }, []);

    const sitesCountByPlatform = platforms.reduce((acc, platform) => {
        acc[platform] = waitingSitesList.filter(site => site.user_plateform === platform).length;
        return acc;
    }, {});

    const filteredSitesList = selectedPlatforms.length > 0
        ? waitingSitesList.filter(site => selectedPlatforms.includes(site.user_plateform))
        : waitingSitesList;

    return (
        <div className={"WaitingSites GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className='FlexCol MB10'>
                <div className='FWBold'>Filters</div>
                <div className='FlexRow'>
                    {platforms.map(platform => (
                        <label key={platform} className='MR20 FlexRow'>
                            <input
                                type='checkbox'
                                checked={selectedPlatforms.includes(platform)}
                                onChange={() => handlePlatformChange(platform)}
                            />
                            {platform} ({sitesCountByPlatform[platform] || 0})
                        </label>
                    ))}
                </div>
            </div>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Details</th>
                        <th className='P5'>Url</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredSitesList.map((row) => (
                        <WaitingSitesRow
                            key={row.site_id}
                            userPremium={row.user_premium}
                            userMailValid={row.user_mail_valid}
                            nbSiteActif={row.nb_site_actif}
                            nbSiteAccepted={row.nb_site_accepted}
                            cacIso2={row.cac_iso2}
                            userId={row.user_id}
                            siteChild={row.site_child}
                            siteId={row.site_id}
                            siteUrl={row.site_url}
                            adminRoles={row.roles}
                            picture={row.adm_picture}
                            name={row.adm_name}
                            admId={row.adm_id}
                            sponsorMail={row.user_parrain_email}
                            userMail={row.user_mail}
                            siteDate={row.site_date}
                            themesList={themesList}
                            siteThemeId={row.site_theme}
                            userFirstname={row.user_firstname}
                            userLastname={row.user_lastname}
                            siteCountryList={siteCountryList}
                            raisonPutAsideList={raisonPutAsideList}
                            reasonRefuseList={reasonRefuseList}
                            doublonsAccepted={row.doublons_accepted}
                            doublonsRaisonRefus={row.doublons_raison_refus}
                            doublonsFraud={row.doublons_fraud}
                            userProsName={row.user_pros_name}
                            userModeration={row.user_moderation}
                            userFraud={row.user_fraud}
                            vuSimilar={row.vu_similar}
                            onSiteAction={handleSiteAction}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default WaitingSites;
