import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SiteProgressbar(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [progressbarValue, setProgressbarValue] = useState();
    const [progressBarOptions, setProgressBarOptions] = useState([]);
    
    const optionsWithCumulativeProgression = progressBarOptions.reduce((acc, option) => {
        const lastSum = acc.length > 0 ? acc[acc.length - 1].cumulativeProgression : 0;
        const newSum = lastSum + parseFloat(option.progression, 2);
        acc.push({ ...option, cumulativeProgression: newSum });
        return acc;
    }, []);

    useEffect(() => {
        axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getProgressBarOptions")
            .then(response => {
                setProgressBarOptions(response.data.results);
            })
            .catch(error => {
                console.error("Failed to fetch progress bar options:", error);
            })

    }, []);

    useEffect(() => {
        setProgressbarValue(props.siteProgressbar || '');
    }, [props.siteProgressbar])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpateProgressbar(siteId, progressbarValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateProgressbar", { site_id: siteId, progressbar_value: progressbarValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Progressbar updated to ' + progressbarValue, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    function handleSelectChange(e) {
        const selectedValue = e.target.value;
        setProgressbarValue(selectedValue);
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="site_progressbar">Progress Bar</label>
            <div className="FlexRow">
                <select
                    className="ActionInput MR10 P10"
                    id="quality_reason_select"
                    onChange={handleSelectChange}
                    value={progressbarValue}>
                    <option value="" disabled>Select progression</option>
                    {optionsWithCumulativeProgression.map((option) => (
                        <option key={option.id} value={option.id}>{option.jour} ({option.cumulativeProgression.toFixed(2)}%)</option>
                    ))}
                </select>
                <button className="ActionButton BGreen" onClick={() => udpateProgressbar(props.siteId, progressbarValue)}>Save</button>
            </div>
        </div>
    );
}

export default SiteProgressbar;
