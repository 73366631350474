import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import { useState, useEffect, useCallback } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import axios from "axios";
import { format, subDays } from 'date-fns';
import FormatPerfChart from "../../Components/FormatPerfChart/FormatPerfChart";
import FormatCPMChart from "../../Components/FormatCPMChart/FormatCPMChart";
import FormatVisibilityChart from "../../Components/FormatVisibilityChart/FormatVisibilityChart";
import FormatFillRateChart from "../../Components/FormatFillRateChart/FormatFillRateChart";
import SitePerfChart from "../../Components/SitePerfChart/SitePerfChart";
import SiteCPMChart from "../../Components/SiteCPMChart/SiteCPMChart";
import FormatViewsChart from "../../Components/FormatViewsChart/FormatViewsChart";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import { ROOT_DOMAIN_API } from "../../Constant";
import SiteGlobalPerf from "../../Components/SiteGlobalPerf/SiteGlobalPerf";
import PerfSsp from "../../Components/PerfSsp/PerfSsp";
import SiteHeader from "../../Components/SiteHeader/SiteHeader";
import SitePerfPeriod from "../../Components/SitePerfPeriod/SitePerfPeriod";

function Statistics() {
    const pageTab = "statistics_websites";
    const [siteID, setSiteID] = useState(null);
    const now = new Date();
    const { id } = useParams();
    const [formatsList, setFormatsList] = useState([]);
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [selectedFormatName, setSelectedFormatName] = useState('');
    const [selectedDay1, setSelectedDay1] = useState(format(subDays(now, 30), 'yyyy-MM-dd'));
    const [selectedDay2, setSelectedDay2] = useState(format(new Date(), 'yyyy-MM-dd')); 
    const [sD1Formatted, setSD1Formatted] = useState(format(subDays(now, 30), 'yyyy-MM-dd'));
    const [sD2Formatted, setSD2Formatted] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [dayPickerVisibility1, setDayPickerVisibility1] = useState(false);
    const [dayPickerVisibility2, setDayPickerVisibility2] = useState(false);

    useEffect(() => {
        setSiteID(id);
    }, [id]);

    useEffect(() => {
        if (selectedDay1) {
            setSD1Formatted(format(new Date(selectedDay1), 'yyyy-MM-dd'));
            setDayPickerVisibility1(false);
        }

        if (selectedDay2) {
            setSD2Formatted(format(new Date(selectedDay2), 'yyyy-MM-dd'));
            setDayPickerVisibility2(false);
        }
    }, [selectedDay1, selectedDay2]);

    const toggleDayPicker1 = () => setDayPickerVisibility1(prev => !prev);
    const toggleDayPicker2 = () => setDayPickerVisibility2(prev => !prev);

    const loadFormats = useCallback(async () => {
        if (!siteID) return;

        try {
            const { data } = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/formatsBySitePerf`, {
                params: {
                    site_id: siteID,
                    s_date: sD1Formatted,
                    e_date: sD2Formatted
                }
            });
            if (data.status && data.results) {
                setFormatsList(data.results);
                if (data.results.length > 0) {
                    setSelectedFormat(data.results[0].sp_ad_id);
                    setSelectedFormatName(data.results[0].ad_name);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [siteID, sD1Formatted, sD2Formatted]);

    useEffect(() => {
        loadFormats();
    }, [siteID, loadFormats]);

    const quickDateHandler = (type) => {
        let startDate, endDate;

        switch (type) {
            case "30_days":
                startDate = subDays(new Date(), 30);
                endDate = new Date();
                break;
            case "last_month":
                startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                endDate = new Date(now.getFullYear(), now.getMonth(), 0);
                break;
            case "1st_january":
                startDate = new Date(now.getFullYear(), 0, 1);
                endDate = new Date();
                break;
            default:
                return;
        }

        setSelectedDay1(format(startDate, 'yyyy-MM-dd'));
        setSelectedDay2(format(endDate, 'yyyy-MM-dd'));
        setSD1Formatted(format(startDate, 'yyyy-MM-dd'));
        setSD2Formatted(format(endDate, 'yyyy-MM-dd'));
    };

    const handleSelectChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        const selectedFormatObject = formatsList.find(format => format.sp_ad_id === selectedValue);
        setSelectedFormat(selectedValue);
        setSelectedFormatName(selectedFormatObject ? selectedFormatObject.ad_name : '');
    };

    return (
        <div className="ManageSites GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <LateralNavComponent pageTab={pageTab} />
            <HeadLine childClass="Headline GridCol1-13" title={`Statistics site n°${siteID}`} />
            <SiteHeader siteID={siteID} />
            <div className="FlexCol GridCol1-13">
                <div className="ShadowContainer BWhite P20 FlexRow JustifyBetween AlignCenter MB20">
                    <div className="FlexRow">
                        <div className="FlexRow">
                            <button onClick={() => quickDateHandler("30_days")} className="BBlue P10 ActionButton TWhite HeightFitContent">30 days</button>
                            <button onClick={() => quickDateHandler("last_month")} className="BBlue P10 ActionButton TWhite ML10 HeightFitContent">Last month</button>
                            <button onClick={() => quickDateHandler("1st_january")} className="BBlue P10 ActionButton TWhite ML10 HeightFitContent">From 1st January</button>
                        </div>
                        <div className="FlexCol">
                            <button className="ActionButton BRed ML10" onClick={toggleDayPicker1}><i className="fa-solid fa-calendar-days"></i> From {sD1Formatted}</button>
                            <DayPicker
                                format="M/D/YYYY"
                                className={`DayPickerObject ShadowContainer BSoftBlue ${dayPickerVisibility1 ? '' : 'Hide'}`}
                                mode="single"
                                selected={selectedDay1}
                                onSelect={setSelectedDay1}
                            />
                        </div>
                        <div className="FlexCol">
                            <button className="ActionButton BRed ML10" onClick={toggleDayPicker2}><i className="fa-solid fa-calendar-days"></i> To {sD2Formatted}</button>
                            <DayPicker
                                format="M/D/YYYY"
                                className={`DayPickerObject ShadowContainer BSoftBlue ${dayPickerVisibility2 ? '' : 'Hide'}`}
                                mode="single"
                                selected={selectedDay2}
                                onSelect={setSelectedDay2}
                            />
                        </div>
                    </div>
                    <div className="FlexCol">
                        <label htmlFor="format_select" className="FWBold">Format name</label>
                        <select onChange={handleSelectChange} id="format_select" className="ActionSelect P5">
                            {formatsList.length < 1 ? <option value="-1">No format available for this period.</option> : ''}
                            {formatsList.map((row, key) => (
                                <option key={key} value={row.sp_ad_id}>{row.ad_name} - {row.percent}%</option>
                            ))}
                        </select>
                    </div>
                    <SitePerfPeriod siteID={siteID} sDate={sD1Formatted} eDate={sD2Formatted}/>
                    <SiteGlobalPerf siteID={siteID} sDate={sD1Formatted} eDate={sD2Formatted}/>
                </div>
                <div className="FlexCol Gap20">
                    <div className="FlexRow Gap20">
                        <SitePerfChart sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} />
                        <SiteCPMChart sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} />
                    </div>
                    <div className="FlexRow Gap20">
                        <FormatPerfChart format={selectedFormat} sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} formatName={selectedFormatName} />
                        <FormatCPMChart format={selectedFormat} sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} formatName={selectedFormatName} />
                    </div>
                    <div className="FlexRow Gap20">
                        <FormatVisibilityChart format={selectedFormat} sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} formatName={selectedFormatName} />
                        <FormatFillRateChart format={selectedFormat} sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} formatName={selectedFormatName} />
                    </div>
                    <div className="FlexRow Gap20">
                        <FormatViewsChart format={selectedFormat} sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} formatName={selectedFormatName} />
                    </div>
                    <div className="FlexRow Gap20">
                        <PerfSsp sDate={sD1Formatted} eDate={sD2Formatted} siteID={siteID} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Statistics;
