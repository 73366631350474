// colorConfig.js
const countryColors = {
    "AFG": "#EFBDEB",
    "ALB": "#B68CB8",
    "DZA": "#6461A0",
    "AND": "#314CB6",
    "AGO": "#0A81D1",
    "ATG": "#FFD700",
    "ARG": "#FF7F50",
    "ARM": "#8A2BE2",
    "AUS": "#5F9EA0",
    "AUT": "#7FFF00",
    "AZE": "#FF1493",
    "BHS": "#32CD32",
    "BHR": "#FFD700",
    "BGD": "#20B2AA",
    "BRB": "#9370DB",
    "BLR": "#FF4500",
    "BEL": "#00CED1",
    "BLZ": "#FF6347",
    "BEN": "#9400D3",
    "BTN": "#00FF7F",
    "BOL": "#FF69B4",
    "BIH": "#4169E1",
    "BWA": "#FFA07A",
    "BRA": "#009739",
    "BRN": "#DA70D6",
    "BGR": "#8B0000",
    "BFA": "#3CB371",
    "BDI": "#FA8072",
    "CPV": "#2E8B57",
    "KHM": "#00BFFF",
    "CMR": "#D2691E",
    "CAN": "#7B68EE",
    "CAF": "#ADFF2F",
    "TCD": "#B0E0E6",
    "CHL": "#87CEFA",
    "CHN": "#B22222",
    "COL": "#FF00FF",
    "COM": "#66CDAA",
    "COG": "#8A2BE2",
    "COD": "#FF1493",
    "CRI": "#6495ED",
    "HRV": "#FFA500",
    "CUB": "#0000CD",
    "CYP": "#7FFF00",
    "CZE": "#DC143C",
    "DNK": "#00FFFF",
    "DJI": "#DDA0DD",
    "DMA": "#1E90FF",
    "DOM": "#FFDAB9",
    "TLS": "#EEE8AA",
    "ECU": "#CD5C5C",
    "EGY": "#F08080",
    "SLV": "#20B2AA",
    "GNQ": "#87CEEB",
    "ERI": "#FF6347",
    "EST": "#FF6347",
    "SWZ": "#4682B4",
    "ETH": "#D2691E",
    "FJI": "#DA70D6",
    "FIN": "#DDA0DD",
    "FRA": "#0072bb",
    "GAB": "#FFD700",
    "GMB": "#00CED1",
    "GEO": "#FF4500",
    "DEU": "#555555",
    "GHA": "#9370DB",
    "GRC": "#8B0000",
    "GRD": "#3CB371",
    "GTM": "#FA8072",
    "GIN": "#2E8B57",
    "GNB": "#00BFFF",
    "GUY": "#D2691E",
    "HTI": "#7B68EE",
    "HND": "#ADFF2F",
    "HUN": "#B0E0E6",
    "ISL": "#87CEFA",
    "IND": "#B22222",
    "IDN": "#FF00FF",
    "IRN": "#66CDAA",
    "IRQ": "#8A2BE2",
    "IRL": "#FF1493",
    "ISR": "#6495ED",
    "ITA": "#026633",
    "JAM": "#0000CD",
    "JPN": "#BC002D",
    "JOR": "#DC143C",
    "KAZ": "#00FFFF",
    "KEN": "#DDA0DD",
    "KIR": "#1E90FF",
    "PRK": "#FFDAB9",
    "KOR": "#EEE8AA",
    "KWT": "#CD5C5C",
    "KGZ": "#F08080",
    "LAO": "#20B2AA",
    "LVA": "#87CEEB",
    "LBN": "#FF6347",
    "LSO": "#FF6347",
    "LBR": "#4682B4",
    "LBY": "#D2691E",
    "LIE": "#DA70D6",
    "LTU": "#DDA0DD",
    "LUX": "#1E90FF",
    "MDG": "#FFD700",
    "MWI": "#00CED1",
    "MYS": "#FF4500",
    "MDV": "#9400D3",
    "MLI": "#9370DB",
    "MLT": "#8B0000",
    "MHL": "#3CB371",
    "MRT": "#FA8072",
    "MUS": "#2E8B57",
    "MEX": "#00BFFF",
    "FSM": "#D2691E",
    "MDA": "#7B68EE",
    "MCO": "#ADFF2F",
    "MNG": "#B0E0E6",
    "MNE": "#87CEFA",
    "MAR": "#B22222",
    "MOZ": "#FF00FF",
    "MMR": "#66CDAA",
    "NAM": "#8A2BE2",
    "NRU": "#FF1493",
    "NPL": "#6495ED",
    "NLD": "#FFA500",
    "NZL": "#0000CD",
    "NIC": "#7FFF00",
    "NER": "#DC143C",
    "NGA": "#00FFFF",
    "MKD": "#DDA0DD",
    "NOR": "#1E90FF",
    "OMN": "#FFDAB9",
    "PAK": "#EEE8AA",
    "PLW": "#CD5C5C",
    "PAN": "#F08080",
    "PNG": "#20B2AA",
    "PRY": "#87CEEB",
    "PER": "#FF6347",
    "PHL": "#FF6347",
    "POL": "#4682B4",
    "PRT": "#D2691E",
    "QAT": "#7B68EE",
    "ROU": "#ADFF2F",
    "RUS": "#B0E0E6",
    "RWA": "#87CEFA",
    "KNA": "#B22222",
    "LCA": "#FF00FF",
    "VCT": "#66CDAA",
    "WSM": "#8A2BE2",
    "SMR": "#FF1493",
    "STP": "#6495ED",
    "SAU": "#FFA500",
    "SEN": "#0000CD",
    "SRB": "#7FFF00",
    "SYC": "#DC143C",
    "SLE": "#00FFFF",
    "SGP": "#DDA0DD",
    "SVK": "#1E90FF",
    "SVN": "#FFDAB9",
    "SLB": "#EEE8AA",
    "SOM": "#CD5C5C",
    "ZAF": "#F08080",
    "SSD": "#20B2AA",
    "ESP": "#F1BF00",
    "LKA": "#FF6347",
    "SDN": "#FF6347",
    "SUR": "#4682B4",
    "SWE": "#D2691E",
    "CHE": "#7B68EE",
    "SYR": "#ADFF2F",
    "TJK": "#B0E0E6",
    "TZA": "#87CEFA",
    "THA": "#B22222",
    "TGO": "#FF00FF",
    "TON": "#66CDAA",
    "TTO": "#8A2BE2",
    "TUN": "#FF1493",
    "TUR": "#6495ED",
    "TKM": "#FFA500",
    "TUV": "#0000CD",
    "UGA": "#7FFF00",
    "UKR": "#DC143C",
    "ARE": "#00FFFF",
    "GBR": "#DDA0DD",
    "USA": "#1E90FF",
    "URY": "#FFDAB9",
    "UZB": "#EEE8AA",
    "VUT": "#CD5C5C",
    "VEN": "#F08080",
    "VNM": "#20B2AA",
    "YEM": "#87CEEB",
    "ZMB": "#FF6347",
    "ZWE": "#FF6347"
};

export default countryColors;
