import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./BusinessFinderList.css";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

function BusinessFinderList(props) {
    const [businessFinderList, setBusinessFinderList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_businessFinderList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setBusinessFinderList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const updateBusinessFinderStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateBusinessFinderStatus',
                {id: id, status: status});
            const obj = await resp.data;
            if (obj.status) {
                setBusinessFinderList(obj.results);
            }
        })();
    }

    const handleMoneyboxAccess = async (user_id) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxTokenUser`, {
                params: { user_id: user_id },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&user_id=" + user_id + "&tbm_hash=" + response.data.results.tbm_hash;

                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox ', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={"BusinessFinderList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="TRight MB20">
                <Link to={"/businessFinderSetup/0"} className="BGreen TWhite ActionButton ML5 NoUnderline">
                    + Ajouter un Business Finder
                </Link>
            </div>
            <table className='Width100'>
                <thead>
                <tr className="BRed TWhite">
                    <th className='P5'>User ID</th>
                    <th className='P5'>Lastname</th>
                    <th className='P5'>Firstname</th>
                    <th className='P5'>Email</th>
                    <th className='P5'>Currency</th>
                    <th className='P5'>Revshare</th>
                    <th className='P5'>Contract begin date</th>
                    <th className='P5'>Contract end date</th>
                    <th className='P5'>Contract Duration</th>
                    <th className='P5'>Status</th>
                    <th className='P5'></th>
                </tr>
                </thead>
                <tbody>
                {businessFinderList.map((row, key) => {
                    return <tr>
                        <td>{row.user_id}</td>
                        <td>{row.user_firstname}</td>
                        <td>{row.user_lastname}</td>
                        <td>{row.user_mail}</td>
                        <td>{row.user_currency}</td>
                        <td>{row.ib_revshare}</td>
                        <td>{row.bf_start_date}</td>
                        <td>{row.bf_end_date}</td>
                        <td>{row.bf_nb_month}</td>
                        <td>
                            <label className="switch">
                                <input type="checkbox"
                                       onClick={() => updateBusinessFinderStatus(row.bf_id, row.bf_status === 1 ? 0 : 1)}
                                       checked={row.bf_status === 1}
                                />
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>
                            <div className="FlexRow">
                                <Link to={"/businessFinderSetup/" + row.bf_id} className="BOrange TWhite ActionButton ML5">
                                    <i className="fa-solid fa-gear"></i>
                                </Link>
                                <button className="BGreen TWhite ActionButton ML5" onClick={() => handleMoneyboxAccess(row.user_id)}>
                                    <i className="fa-solid fa-piggy-bank"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    );
}

export default BusinessFinderList;