import "./HeadLine.css";

function HeadLine(props) {
  const username = localStorage.getItem('global_name');

  return (
    <div className={"HeadLine FlexRow AlignEnd JustifyBetween " + props.childClass}>
        <div className="FlexRow">
          <div>
            <h1 className="M0">{props.intro ? props.intro : "Hey " + (username ? username : "/")},</h1>
            <h5 className="M0 MT5">{props.emoji ? <i className={props.emoji}></i> : ''}{props.title}</h5>
          </div>
        </div>
    </div>
  );
}

export default HeadLine;
