import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import PassbackListRow from './PassbackListRow/PassbackListRow';

function PassbackList(props) {
    const [passbackList, setPassbackList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        setPassbackList(props.passbacks);
    }, [props.passbacks]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handleUpdate = (bid_id, newBidText) => {
        setPassbackList(passbackList.map(item => 
            item.bid_id === bid_id ? { ...item, bid_text: newBidText } : item
        ));
    };

    const handleDelete = (bid_id) => {
        setPassbackList(passbackList.filter(item => item.bid_id !== bid_id));
    };

    const filteredPassbackList = passbackList.filter(row =>
        row && row.bid_site_id && row.bid_site_id.toString().includes(searchTerm)
    );

    const pageCount = Math.ceil(filteredPassbackList.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;
    const currentPageData = filteredPassbackList.slice(offset, offset + itemsPerPage);

    return (
        <div className={"PassbackList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="FWBold MediumFont">Search</div>
            <div className="FlexRow MB10">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Enter site ID"
                    className="P10 MR10 ActionInput"
                />
            </div>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Site ID</th>
                        <th className='P5'>URL</th>
                        <th className='P5'>Format</th>
                        <th className='P5'>Passback code</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPageData.length > 0 ? (
                            currentPageData.map((row, key) => (
                                row && (
                                    <PassbackListRow key={key} row={row} onUpdate={handleUpdate} onDelete={handleDelete} />
                                )
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className='P15 TCenter'>No passbacks available</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <ReactPaginate
                previousLabel={"< previous"}
                nextLabel={"next >"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"Pagination"}
                activeClassName={"active"}
            />
        </div>
    );
}

export default PassbackList;
