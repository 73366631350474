import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import React from "react";
import { ToastContainer } from "react-toastify";
import MagicTagList from "../../Components/MagicTag/MagicTagList"
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import "./MagicTag.css"

function MagicTag() {
    const pageTab = "magic_tag_list";

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HeadLine childClass="Headline GridCol1-13" title="Magic Tag List" />
            <LateralNavComponent pageTab={pageTab} />
            <MagicTagList />
        </div>
    )
}

export default MagicTag;
