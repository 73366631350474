import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./FaqSetup.css";
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MDEditor from '@uiw/react-md-editor';

function FaqSetup(props) {
    const [category, setCategory] = useState([]);
    const [lang, setLang] = useState([]);
    const [faq, setFaq] = useState([]);
    const [faqDesc, setFaqDesc] = useState([]);
    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_faqCategoryList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setCategory(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getFaqDescByFaqId?id="+id);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results.lang);
                    setFaq(obj.results.faq);
                    setFaqDesc(obj.results.faqDesc);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    const updateFaq = async (event) => {
        event.preventDefault();

        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_updateFaq", {
                'faqId':id,
                'faq': faq,
                'faqDesc': faqDesc,
            });
            const obj = await resp.data;

            console.log(obj);

            if (obj.status) {
                navigate("/faqList");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateFaqCategory = function (content){
        let a = {...faq};
        a.categorie_id = content;
        setFaq(a);
    }

    const updateTitle = function (id, content){
        let a = {...faqDesc};
        a[id].title = content;
        setFaqDesc(a);
    }

    const updateTitleSEO = function (id, content){
        let a = {...faqDesc};
        a[id].title_seo = content;
        setFaqDesc(a);
    }

    const updateDescription = function (id, content){
        let a = {...faqDesc};
        a[id].description = content;
        setFaqDesc(a);
    }

    const updateStatus = function (id, content){
        let a = {...faqDesc};
        a[id].status = content;
        setFaqDesc(a);
    }

    useEffect(() => {
        console.log(faq);
    }, [faq]);

    useEffect(() => {
        console.log(faqDesc);
    }, [faqDesc]);

    return (
        <div className={"FaqSetup GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <form onSubmit={updateFaq}>
                <div>
                    <div className={"Underline MB10"}>Category :</div>
                    <select
                        name={"faqCategory"}
                        className={"ActionInput P5"}
                        onChange={(evt) => updateFaqCategory(parseInt(evt.target.value))}
                    >
                        {category.map((row) => {
                            return <option value={row.id} selected={faq.categorie_id === row.id}>{row.libelle}</option>
                        })}
                    </select>

                    <input type="hidden" name={"faqId"} id={"faqId"} value={faq.id}/>
                </div>
                <hr/>

                {lang.map((row) => {
                    return <div>
                        <div className={"FWBold Underline MB10"}>{row.lang_name}</div>
                        <div>
                            <div className={"MB10"}>
                                <div>Title :</div>
                                <input type="text" name={"faqTitle[" + row.lang_id + "]"}
                                       className={"faqTitle ActionInput P5"} value={faqDesc[row.lang_id].title}
                                       onChange={(evt) => updateTitle(row.lang_id, evt.target.value)}/>
                            </div>
                            {row.lang_id === 7 || row.lang_id === 11 ? (
                                <div className={"MB10"}>
                                    <div>Title SEO :</div>
                                    <input type="text" name={"faqTitleSEO[" + row.lang_id + "]"}
                                           className={"faqTitleSEO ActionInput P5"} value={faqDesc[row.lang_id].title_seo}
                                           onChange={(evt) => updateTitleSEO(row.lang_id, evt.target.value)}/>
                                </div>
                            ) : ""}
                            <div className={"MB10"}>
                                <div>Description :</div>
                                <MDEditor
                                    highlightEnable={false}
                                    value={faqDesc[row.lang_id].description}
                                    onChange={(evt) => updateDescription(row.lang_id, evt)}
                                />

                            </div>
                            <div className={"MB10"}>
                                <div>Status :</div>
                                <input type="radio" name={"faqStatus[" + row.lang_id + "]"}
                                       id={"faqStatusOnline" + row.lang_id}
                                       className={"ActionInput P5"}
                                       value={1}
                                       checked={faqDesc[row.lang_id].status === 1}
                                       onChange={(evt) => updateStatus(row.lang_id, parseInt(evt.target.value))}/>
                                <label htmlFor={"faqStatusOnline" + row.lang_id} className={"MR10"}>Online</label>
                                <input type="radio" name={"faqStatus[" + row.lang_id + "]"}
                                       id={"faqStatusOffline" + row.lang_id}
                                       className={"ActionInput P5"}
                                       value={0}
                                       checked={faqDesc[row.lang_id].status === 0}
                                       onChange={(evt) => updateStatus(row.lang_id, parseInt(evt.target.value))}/>
                                <label htmlFor={"faqStatusOffline" + row.lang_id} className={"MR10"}>Offline</label>
                            </div>
                        </div>
                        <hr/>
                    </div>
                })}

                <button id="edit_faq" type="submit" className="btn btn-md btn-success">
                    Validate
                </button>
            </form>
        </div>
    );
}

export default FaqSetup;