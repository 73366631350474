import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SiteBlocklist(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [blocklistValue, setBlocklistValue] = useState(); 

    useEffect(() => {
        setBlocklistValue(props.siteBlocklist);
    }, [props.siteBlocklist])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpateBlocklist(siteId, blocklistValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateAdvertiserBlocklist", { site_id: siteId, advertiser_blocklist: blocklistValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Advertiser blocklist updated to ' + blocklistValue, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="site_blocklist">Advertiser Blocklist</label>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={blocklistValue}
                    placeholder="Blocklist value"
                    id="site_blocklist"
                    onChange={(e) => setBlocklistValue(e.target.value)}
                />
                <button className="ActionButton BGreen" onClick={() => udpateBlocklist(props.siteId, blocklistValue)}>Save</button>
            </div>
        </div>
    );
}

export default SiteBlocklist;
