import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './EditWorkers.css';
import { useState, useEffect } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import SplashLoadComponent from "../../Components/SplashLoadComponent/SplashLoadComponent";
import WorkersList from "../../Components/WorkersList/WorkersList";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import AddAdmin from "../../Components/AddAdmin/AddAdmin";

function EditWorkers() {
  const pageTab = "publishers";

  const [splashLoad, setSplashLoad] = useState(true);

  // TODO: This has to be changed to a splash screen linked to the data load, not a timeout
  useEffect(() => {
    setSplashLoad(true);
    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, []);

  return (
    <div className="EditWorkers GridTemplate12">
      <AuthComponent />
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LateralNavComponent pageTab={pageTab} />
      <HeadLine childClass="Headline GridCol1-13" title="Edit workers settings" />
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard" /> : 
        <>
          <AddAdmin/>
          <WorkersList />
        </>
      }
    </div>
  );
}

export default EditWorkers;
