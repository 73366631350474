import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import React from "react";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import SiteAddPackSmart from "../../Components/SiteAddPackSmart/SiteAddPackSmart"
import SiteRemovePackSmart from "../../Components/SiteRemovePackSmart/SiteRemovePackSmart";
import SiteWaitingList from "../../Components/SiteWaitingList/SiteWaitingList"
import NewRevshare from "../../Components/NewRevshare/NewRevshare"
import UserAllowPaypal from "../../Components/UserAllowPaypal/UserAllowPaypal";
import SiteRollBackManual from "../../Components/SiteRollBackManual/SiteRollBackManual";

function Tools() {
    const pageTab = "tools";
    
    return (
        <div className="GridTemplate12">
            <AuthComponent />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HeadLine childClass="Headline GridCol1-13" title="Tools" />
            <LateralNavComponent pageTab={pageTab} />
            <SiteAddPackSmart />
            <SiteRemovePackSmart />
            <SiteWaitingList />
            <NewRevshare />
            <UserAllowPaypal/>
            <SiteRollBackManual/>
        </div>
    )
}

export default Tools;
