import axios from "axios";
import { useEffect, useState } from "react";
import { ROOT_DOMAIN_API } from "../../Constant";
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";

function SiteGlobalPerf(props) {
    const [loading, setLoading] = useState(true);
    const [ctr, setCtr] = useState(0);
    const [visibility, setVisibility] = useState(0);
    const [classCTR, setClassCTR] = useState("");
    const [classVisibility, setClassVisibility] = useState("");

    useEffect(() => {
        if (!props.siteID) {
            return;
        }

        setLoading(true);

        (async () => {
            try {
                let data = {
                    params: {
                        site_id: props.siteID,
                        date_from: props.sDate,
                        date_to: props.eDate
                    }
                }
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getAvgCTRAndVisibilityBySiteId", data);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    const ctrValue = obj.results.CTR;
                    const visibilityValue = obj.results.visibility;

                    setCtr(ctrValue);
                    setVisibility(visibilityValue);

                    if (ctrValue >= 3) {
                        setClassCTR("TRed");
                    } else if (ctrValue >= 1) {
                        setClassCTR("TYellow");
                    } else if (ctrValue >= 0.2) {
                        setClassCTR("TGreen");
                    } else {
                        setClassCTR("TYellow");
                    }

                    if (visibilityValue >= 90) {
                        setClassVisibility("TYellow");
                    } else if (visibilityValue >= 60) {
                        setClassVisibility("TGreen");
                    } else if (visibilityValue >= 30) {
                        setClassVisibility("TYellow");
                    } else {
                        setClassVisibility("TRed");
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.sDate, props.eDate, props.siteID]);

    return (
        <div className={"SiteGlobalPerf P15 " + props.childClass}>
            {loading ? (
                <SplashLoadComponent />
            ) : (
                <div className="FlexCol ">
                    <div className="FlexRow AlignCenter JustifyBetween">
                        <h3 className="MR10">CTR</h3>
                        <div className={`FWBold MediumFont ${classCTR}`}>{ctr}%</div>
                    </div>
                    <div className="FlexRow AlignCenter JustifyBetween">
                        <h3 className="MR10">Visibility</h3>
                        <div className={`FWBold MediumFont ${classVisibility}`}>{visibility}%</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SiteGlobalPerf;
