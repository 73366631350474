import './SiteFormats.css';
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import SiteFormatsRow from './SiteFormatsRow/SiteFormatsRow';
import { ROOT_DOMAIN_API } from '../../Constant';
import { toast } from 'react-toastify';

function SiteFormats(props) {
    const [siteFormatsList, setSiteFormatsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    const loadSiteFormats = useCallback(() => {
        (async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                }
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/siteFormats", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setSiteFormatsList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.siteID]);

    function refuseFormat(formId) {
        if (window.confirm("Are you sure you want to refuse this format?")) {
            setIsLoading(true);
            axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_refuseFormat", { form_id: formId })
                .then(response => {
                    if (!response.data.status) {
                        toast.error('Something went wrong, please try again.', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        throw new Error('Failed to refuse format');
                    }
                    loadSiteFormats();
                    toast.success('Format refused', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(error => {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }

    useEffect(() => {
        if (!props.siteID) {
            return;
        }
        loadSiteFormats();
    }, [props.siteID, loadSiteFormats]);

    return (
        <div className={"SiteFormats GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className="MT0">Site's formats</h2>
            <div className='Width100 SiteFormatsContainer'>
                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>Form ID</th>
                            <th className='P5'>Format</th>
                            <th className='P5'>Date</th>
                            <th className='P5'>Extra</th>
                            <th className='P5'>Visible refresh</th>
                            <th className='P5'>Invisible refresh</th>
                            <th className='P5'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {siteFormatsList.map((row, key) => {
                            return <SiteFormatsRow key={key}
                                formId={row.form_id}
                                formName={row.form_name}
                                formDate={row.form_date}
                                formRefreshVisible={row.form_refresh_visible}
                                formRefreshInvisible={row.form_refresh_invisible}
                                formCustomDisplay={row.form_custom_display}
                                formCustomPlacement={row.form_custom_placement}
                                formState={row.form_state}
                                siteIntextLazyLoaded={row.site_intext_lazyloaded}
                                onRefuseFormat={() => refuseFormat(row.form_id)} />
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SiteFormats;
