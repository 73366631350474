import './AdReportUser.css';
import { useState, useEffect } from "react";
import axios from "axios";
import AdReportUserRow from './AdReportUserRow/AdReportUserRow';
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from '../../Constant';
axios.defaults.withCredentials = true;

function AdReportUser(props) {
    const [adReportUser, setAdReportUser] = useState([]);
    const [userId, setUserId] = useState('');

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/adReportUser");
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setAdReportUser(obj.results);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const userExists = (id) => {
        return adReportUser.some(user => user.uar_user_id === parseInt(id));
    };

    const createUser = async () => {
        if (userExists(userId)) {
            toast.error('User already exists.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        try {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/createAdReportUser',
                { user_id: parseInt(userId) });
            const obj = await resp.data;
            if (obj.status) {
                toast.success('User successfully created', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setUserId('');
                loadUsers();
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong, please try again.');
        }
    }

    return (
        <div className={"AdReportUser GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <p className='MB5 FWBold'>Add New User</p>
            <div className='FlexRow'>
                <input type="text" className="ActionInput P10 MB10" placeholder='Enter User ID' value={userId} onChange={(e) => setUserId(e.target.value)} />
                <button className='ML5 MB10 BBlue TWhite ActionButton' onClick={createUser} >Generate Token</button>
            </div>

            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>User ID</th>
                        <th className='P5'>Firstname - Lastname</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>Token</th>
                        <th className='P5'>Creation Date</th>
                        <th className='P5'>Authorized</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {adReportUser.map((row) => {
                        return <AdReportUserRow key={row.uar_id}
                            uarId={row.uar_id}
                            name={row.user_firstname + " " + row.user_lastname}
                            userId={row.uar_user_id}
                            email={row.user_mail}
                            date={row.uar_date}
                            authorized={row.uar_authorized}
                            token={row.uar_token}
                        />
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AdReportUser;
