import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function TopSiteRevenuesByPlatform(props) {
    const [topPerf, setTopPerf] = useState([]);
    const [displayedPerf, setDisplayedPerf] = useState([]);
    const [activeSiteId, setActiveSiteId] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(20);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'revenue_private', direction: 'descending' });

    useEffect(() => {
        const filteredData = props.CAByPlateform.filter(site => site.user_plateform === props.platform);
        const sortedData = sortData(filteredData, sortConfig);

        setTopPerf(sortedData);
        setDisplayedPerf(sortedData.slice(0, itemsToShow));
        setLoading(false);
    }, [props.dateFrom, props.dateTo, props.CAByPlateform, itemsToShow, sortConfig, props.platform]);

    useEffect(() => {
        if (!activeSiteId) {
            setGraphData(null);
            return;
        }

        const fetchGraphData = async () => {
            try {
                setGraphData(null); // Reset graph data before fetching new data
                const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getRevenueAndCPMBySiteId`, {
                    params: {
                        site_id: activeSiteId
                    }
                });
                const obj = response.data;
                if (obj.status && obj.results) {
                    const labels = obj.results.map(row => row.sp_date);
                    const cpmData = obj.results.map(row => row.CPM);
                    const revenueData = obj.results.map(row => row.revenue);

                    setGraphData({
                        labels,
                        datasets: [
                            {
                                label: "CPM",
                                data: cpmData,
                                borderColor: "rgb(255, 127, 14)",
                                backgroundColor: "rgb(255, 127, 14)",
                                yAxisID: 'y1',
                                lineTension: 0.3,
                            },
                            {
                                label: "Revenues",
                                data: revenueData,
                                borderColor: "rgb(31, 119, 180)",
                                backgroundColor: "rgb(31, 119, 180)",
                                yAxisID: 'y2',
                                lineTension: 0.3,
                            },
                        ]
                    });
                }
            } catch (error) {
                console.error("Error fetching graph data:", error);
            }
        };

        fetchGraphData();
    }, [activeSiteId]);

    const toggleStatistics = (siteId) => {
        if (activeSiteId !== siteId) {
            setGraphData(null);
            setActiveSiteId(siteId);
        } else {
            setActiveSiteId(null);
        }
    };

    const downloadCSV = () => {
        const headers = ["Site ID", "Site URL", "Revenues", "CTR", "Visibility", "CPM"];
        const rows = topPerf.map(row => [
            row.site_id,
            row.site_url,
            row.revenue_private,
            row.CTR,
            row.visibility,
            row.CPM
        ]);

        let csvContent = "data:text/csv;charset=utf-8,"
            + [headers.join(","), ...rows.map(e => e.join(","))].join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `top_sites_revenues_${props.platform}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const showMoreItems = () => {
        setItemsToShow(prev => prev + 20);
    };

    const sortData = (data, config) => {
        const sortedData = [...data];
        if (config !== null) {
            sortedData.sort((a, b) => {
                const aValue = parseFloat(a[config.key]);
                const bValue = parseFloat(b[config.key]);
                if (aValue < bValue) {
                    return config.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return config.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? 'fa-sort-up' : 'fa-sort-down';
        }
        return 'fa-sort';
    };

    return (
        <div className={"TopPerformance ShadowContainer P25 BWhite " + props.childClass}>
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className="MT0">{props.flag} Top Sites Revenues {props.platform.toUpperCase()}</h2>
                <button className="ActionButton BRock" onClick={downloadCSV}>
                    <i className="fa-solid fa-download TWhite"></i>
                </button>
            </div>
            <table className='Width100 SmallFont'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'></th>
                        <th className='P5'>Site URL</th>
                        <th className='P5 CursorPointer' onClick={() => requestSort('revenue_private')}>
                            Revenues <i className={`fa ${getSortIcon('revenue_private')}`}></i>
                        </th>
                        <th className='P5 CursorPointer' onClick={() => requestSort('CTR')}>
                            CTR <i className={`fa ${getSortIcon('CTR')}`}></i>
                        </th>
                        <th className='P5 CursorPointer' onClick={() => requestSort('visibility')}>
                            Visibility <i className={`fa ${getSortIcon('visibility')}`}></i>
                        </th>
                        <th className='P5 CursorPointer' onClick={() => requestSort('CPM')}>
                            CPM <i className={`fa ${getSortIcon('CPM')}`}></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="6">
                                <SplashLoadComponent />
                            </td>
                        </tr>
                    ) : (
                        displayedPerf.map((row, key) => (
                            <React.Fragment key={key}>
                                <tr>
                                    <td className='P15 TCenter'>
                                        <button
                                            className="ActionButton ML5 BBlue Height30px MWidthMaxContent SmallFont"
                                            onClick={() => toggleStatistics(row.site_id)}
                                        >
                                            <i className={`fa-solid ${activeSiteId === row.site_id ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                            &nbsp;Statistics
                                        </button>
                                    </td>
                                    <td className='P15 FlexRow JustifyBetween AlignBaseline'>
                                        {row.site_url}
                                        <Link className="BOrange TWhite ActionButton" to={"/options/" + row.site_id}><i className="fa-solid fa-gear"></i></Link>
                                    </td>
                                    <td className='P15'>{row.revenue_private}</td>
                                    <td className='P15'>{row.CTR}</td>
                                    <td className='P15'>{row.visibility}</td>
                                    <td className='P15'>{row.CPM}</td>
                                </tr>
                                {activeSiteId === row.site_id && (
                                    <tr key={`${row.site_id}-stats`}>
                                        <td colSpan="6" className="P15">
                                            <div className="ChartPlaceholder">
                                                {graphData ? (
                                                    <Line
                                                        data={graphData}
                                                        height={300}
                                                        options={{
                                                            maintainAspectRatio: false,
                                                            responsive: true,
                                                            scales: {
                                                                x: {
                                                                    title: {
                                                                        display: true,
                                                                        text: 'Date'
                                                                    }
                                                                },
                                                                y1: {
                                                                    type: 'linear',
                                                                    display: true,
                                                                    position: 'right',
                                                                    title: {
                                                                        display: true,
                                                                        text: 'CPM'
                                                                    }
                                                                },
                                                                y2: {
                                                                    type: 'linear',
                                                                    display: true,
                                                                    position: 'left',
                                                                    title: {
                                                                        display: true,
                                                                        text: 'Revenues'
                                                                    },
                                                                    grid: {
                                                                        drawOnChartArea: false,
                                                                    },
                                                                },
                                                            }
                                                        }}
                                                    />
                                                ) : <SplashLoadComponent />}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))
                    )}
                </tbody>
            </table>
            {itemsToShow < topPerf.length && (
                <div className="FlexRow JustifyCenter MT20">
                    <button className="ActionButton BRock" onClick={showMoreItems}>Show More</button>
                </div>
            )}
        </div>
    );
}

export default TopSiteRevenuesByPlatform;
