import './RefusedSites.css';
import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { ROOT_DOMAIN_API } from '../../Constant';

function RefusedSites(props) {
    const [refusedSitesList, setRefusedSitesList] = useState([]);
    const [deletedUsersList, setDeletedUsersList] = useState([]);
    const [query, setQuery] = useState("");
    const [queryDisplay, setQueryDisplay] = useState("");
    const [pageCountSites, setPageCountSites] = useState(0);
    const [pageCountUsers, setPageCountUsers] = useState(0);
    const [currentPageSites, setCurrentPageSites] = useState(0);
    const [currentPageUsers, setCurrentPageUsers] = useState(0);
    const limit = 10;
    const debounceTimeout = useRef(null);

    const loadRefusedSites = useCallback(async () => {
        try {
            const payload = {
                params: {
                    q: query || "",
                    page: currentPageSites,
                    limit: limit,
                }
            };
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_refusedSites`, payload);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setRefusedSitesList(obj.results.payload);
                setPageCountSites(Math.ceil(obj.results.amount / limit));
            }
        } catch (error) {
            console.error(error);
        }
    }, [query, currentPageSites]);

    const loadDeletedUsers = useCallback(async () => {
        try {
            const payload = {
                params: {
                    q: query || "",
                    page: currentPageUsers,
                    limit: limit,
                }
            };
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_deletedUsers`, payload);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setDeletedUsersList(obj.results.payload);
                setPageCountUsers(Math.ceil(obj.results.amount / limit));
            }
        } catch (error) {
            console.error(error);
        }
    }, [query, currentPageUsers]);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (queryDisplay.length >= 3 || queryDisplay.length === 0) {
                setQuery(queryDisplay);
                setCurrentPageSites(0);
                setCurrentPageUsers(0);
            }
        }, 300);
    }, [queryDisplay]);

    useEffect(() => {
        loadRefusedSites();
        loadDeletedUsers();
    }, [query, currentPageSites, currentPageUsers, loadRefusedSites, loadDeletedUsers]);

    const handlePageClickSites = (e) => {
        setCurrentPageSites(e.selected);
    }

    const handlePageClickUsers = (e) => {
        setCurrentPageUsers(e.selected);
    }

    return (
        <div className={`RefusedSites GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder ${props.childClass}`}>
            <input
                type="text"
                className="ActionInput P10 Width35 MB10"
                onChange={(e) => setQueryDisplay(e.target.value)}
                placeholder='Search for a website or user...'
            />
            <h2>Refused Sites</h2>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Site ID</th>
                        <th className='P5'>Url</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>Refusal Reason</th>
                        <th className='P5'>Refused Date</th>
                        <th className='P5'>Refused Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {refusedSitesList.map((row, key) => (
                        <tr key={key}>
                            <td className='P5'>{row.site_id}</td>
                            <td className='P5'>{row.site_url}</td>
                            <td className='P5'>{row.user_mail}</td>
                            <td className='P5'>{row.raison_refus}</td>
                            <td className='P5'>{row.site_refused_date}</td>
                            <td className='P5'>{row.site_refused_admin}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                className='Pagination'
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClickSites}
                pageRangeDisplayed={5}
                pageCount={pageCountSites}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
            <h2>Deleted Users</h2>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>User ID</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>Name</th>
                        <th className='P5'>Deletion Reason</th>
                        <th className='P5'>Deletion other reason</th>
                        <th className='P5'>Deletion Date</th>
                    </tr>
                </thead>
                <tbody>
                    {deletedUsersList.map((row, key) => (
                        <tr key={key}>
                            <td className='P5'>{row.user_id}</td>
                            <td className='P5'>{row.user_mail}</td>
                            <td className='P5'>{row.user_firstname} {row.user_lastname}</td>
                            <td className='P5'>{row.libelle_refus_user}</td>
                            <td className='P5'>{row.user_raison_suppression_autre}</td>
                            <td className='P5'>{row.user_date_suppression}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                className='Pagination'
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClickUsers}
                pageRangeDisplayed={5}
                pageCount={pageCountUsers}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </div>
    );
}

export default RefusedSites;
