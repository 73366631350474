import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import "./SortableItem.css";

export function SortableItem({ id, data, onRemove, onUpdate }) {
  const [info, setInfo] = useState({});
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    const matchedData = data.find(item => item.top_geo_code_country === id);
    if (matchedData) {
      setInfo(matchedData);
      setPercentage(matchedData.top_geo_percentage || ""); 
    }
  }, [id, data]);

  const {
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handlePercentageChange = (e) => {
    const newPercentage = e.target.value;
    setPercentage(newPercentage);
    onUpdate(id, newPercentage); 
  };

  const handleRemove = (e) => {
    e.stopPropagation(); 
    onRemove(id);
  };

  const handleInputFocus = (e) => {
    e.stopPropagation(); 
  };

  return (
    <tr className="SortableItemTopGeo" ref={setNodeRef} style={style}>
      <td {...listeners}>{info.top_geo_code_country}</td>
      <td {...listeners}><img src={`https://flagsapi.com/${info.top_geo_code_country}/flat/64.png`} alt={`Country flag ${info.top_geo_code_country}`} /></td>
      <td>
        <input 
          className='ActionInput P10 WidthPx75' 
          type="number" 
          value={percentage} 
          onChange={handlePercentageChange} 
          onFocus={handleInputFocus}
          onMouseDown={handleInputFocus} 
          step="0.01" 
        /> %
      </td>
      <td>
        <button 
          className='ActionButton BRed' 
          onMouseDown={handleRemove}  
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      </td>
    </tr>
  );
}
