import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SitePremiumPublisher(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [premiumPublisherValue, setPremiumPublisherValue] = useState(); 

    useEffect(() => {
        setPremiumPublisherValue(props.sitePremiumPublisher);
    }, [props.sitePremiumPublisher])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpatePremiumPublisher(userId, premiumPublisherValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updatePremiumPublisher", { user_id: userId, premium_publisher_value: premiumPublisherValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Premium Publisher updated to ' + premiumPublisherValue, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false);
            });;
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="site_premium_publisher">Premium Publisher</label>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={premiumPublisherValue}
                    placeholder="Premium Publisher"
                    id="site_premium_publisher"
                    onChange={(e) => setPremiumPublisherValue(e.target.value)}
                />
                <button className="ActionButton BGreen" onClick={() => udpatePremiumPublisher(props.userId, premiumPublisherValue)}>Save</button>
            </div>
        </div>
    );
}

export default SitePremiumPublisher;
