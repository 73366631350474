import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../Constant";
import "react-toastify/dist/ReactToastify.css";

function ImportNoAdserver(props) {
    const [ids, setIds] = useState("");

    const handleParse = async () => {
        const idsArray = ids
            .split(/[\s,]+/) 
            .map(id => id.trim()) 
            .filter(id => id.length > 0); 

        const requestData = {
            ids: idsArray,
        };

        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_importNoAdserver`, requestData);

            if (response.data.status) {
                toast.success('Site updated in "No adserver" successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Failed to update Site IDs', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Failed to update Site IDs:", error);
            toast.error('Failed to update Site IDs', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={"ImportNoAdserver GridCol1-7 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className='MT0 MB5'>Import Site No Adserver</h2>
            <div className="FlexCol">
                <div className="FlexCol MB5">
                    <label>List of site IDs separated by comma</label>
                    <textarea
                        type="text"
                        className="ActionInput P5"
                        placeholder="Site IDs only"
                        name="ids"
                        value={ids}
                        rows="5"
                        onChange={(e) => setIds(e.target.value)}
                    ></textarea>
                </div>
            </div>
            <div className="FlexRow">
                <button className="ActionButton BGreen" onClick={handleParse}>Parse</button>
            </div>
        </div>
    );
}

export default ImportNoAdserver;
