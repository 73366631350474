import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from "../../Constant";
import { BarLoader } from "react-spinners";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function SubscribedSitesCodir() {
    const [graphData, setGraphData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadTriggered, setLoadTriggered] = useState(false);

    const colorsList = useMemo(() => ["#ec2121"], []);

    const loadData = function(){
        setLoadTriggered(true);
(async () => {
            setLoading(true);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getAllValidatedSitesHistory`);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    const datasets = [
                        {
                            label: 'Validated Sites',
                            lineTension: 0.3,
                            data: [],
                            borderColor: colorsList[0],
                            backgroundColor: colorsList[0],
                            yAxisID: 'y',
                        }
                    ];
                    const labels = [];

                    obj.results.forEach(row => {
                        const date = new Date(row.site_date_validation);
                        const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
                        labels.push(monthYear);
                        datasets[0].data.push(row.total); 
                    });

                    setGraphData({
                        labels,
                        datasets
                    });
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        })();
    }

    useEffect(() => {
        setLoadTriggered(false)
    }, [colorsList]);

    return (
        <div className="SubscribedSitesCodir GridCol7-13 ShadowContainer BWhite P15">
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className="M0 MT5 MB5">Validated Sites <span className="SmallFont">(no adcash)</span></h2>
                {
                    !loadTriggered ? (
                        <button onClick={() => {loadData();}} className="ActionButton BRed">Load data</button>
                    ) : (
                        null
                    )
                }
            </div>
            {loading ? (
                    <BarLoader height={8} width={230} color="#f41127" />
                ) : (
                <div className="InnerCAChart">
                    {graphData !== null ? (
                        <Line
                            data={graphData}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                responsive: true,
                                scales: {
                                    y: {
                                        min: 0,
                                        position: 'left',
                                        grid: {
                                            drawOnChartArea: true,
                                        },
                                    }
                                }
                            }}
                        />
                    ) : ''}
                </div>
            )}
        </div>
    );
}

export default SubscribedSitesCodir;
