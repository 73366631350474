import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./GuideSetup.css";
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";

function GuideSetup(props) {
    const [category, setCategory] = useState([]);
    const [lang, setLang] = useState([]);
    const [guide, setGuide] = useState([]);
    const [guideDesc, setGuideDesc] = useState([]);
    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_guideCategoryList");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setCategory(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getGuideDescByGuideId?id="+id);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results.lang);
                    setGuide(obj.results.guide);
                    setGuideDesc(obj.results.guideDesc);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    const updateGuide = async (event) => {
        event.preventDefault();

        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_updateGuide", {
                'guideId':id,
                'guide': guide,
                'guideDesc': guideDesc,
            });
            const obj = await resp.data;

            console.log(obj);

            if (obj.status) {
                navigate("/guideList");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateGuideCategory = function (content){
        let a = {...guide};
        a.category_id = content;
        setGuide(a);
    }

    const updateName = function (id, content){
        let a = {...guideDesc};
        a[id].name = content;
        setGuideDesc(a);
    }

    const updateDescription = function (id, content){
        let a = {...guideDesc};
        a[id].description = content;
        setGuideDesc(a);
    }

    const updateLink = function (id, content){
        let a = {...guideDesc};
        a[id].link = content;
        setGuideDesc(a);
    }

    const updateStatus = function (id, content){
        let a = {...guideDesc};
        a[id].status = content;
        setGuideDesc(a);
    }

    useEffect(() => {
        console.log(guideDesc);
    }, [guideDesc]);

    useEffect(() => {
        console.log(guide);
    }, [guide]);

    return (
        <div className={"GuideSetup GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <form onSubmit={updateGuide}>
                <div>
                    <div className={"Underline"}>Category :</div>
                    {category.map((row) => {
                        return <div>
                                <input type="radio" name={"guideCategory"} id={"guideCategory"+row.id}
                                      className={"ActionInput P5"}
                                      value={row.id}
                                      checked={guide.category_id === row.id}
                                      onChange={(evt) => updateGuideCategory(parseInt(evt.target.value))} />
                                <label htmlFor={"guideCategory"+row.id} className={"MR10"}>{row.name}</label>
                        </div>
                    })}

                    <input type="hidden" name={"guideId"} id={"guideId"} value={guide.id} />
                </div>
                <hr/>

                {lang.map((row) => {
                    return <div>
                        <div className={"FWBold Underline MB10"}>{row.lang_name}</div>
                        <div>
                            <div className={"MB10"}>
                                <div>Name :</div>
                                <input type="text" name={"guideName[" + row.lang_id + "]"}
                                       className={"guideName ActionInput P5"} value={guideDesc[row.lang_id].name}
                                       onChange={(evt) => updateName(row.lang_id, evt.target.value)} />
                            </div>
                            <div className={"MB10"}>
                                <div>Description :</div>
                                <input type="text" name={"guideDescription[" + row.lang_id + "]"}
                                       className={"guideDescription ActionInput P5"}
                                       value={guideDesc[row.lang_id].description}
                                       onChange={(evt) => updateDescription(row.lang_id, evt.target.value)} />
                            </div>
                            <div className={"MB10"}>
                                <div>Link :</div>
                                <input type="text" name={"guideLink[" + row.lang_id + "]"}
                                       className={"guideLink ActionInput P5"}
                                       value={guideDesc[row.lang_id].link}
                                       onChange={(evt) => updateLink(row.lang_id, evt.target.value)}/>
                            </div>
                            <div className={"MB10"}>
                                <div>Status :</div>
                                <input type="radio" name={"guideStatus[" + row.lang_id + "]"}
                                       id={"guideStatusOnline" + row.lang_id}
                                       className={"ActionInput P5"}
                                       value={1}
                                       checked={guideDesc[row.lang_id].status === 1}
                                       onChange={(evt) => updateStatus(row.lang_id, parseInt(evt.target.value))} />
                                <label htmlFor={"guideStatusOnline" + row.lang_id} className={"MR10"}>Online</label>
                                <input type="radio" name={"guideStatus[" + row.lang_id + "]"} id={"guideStatusOffline" + row.lang_id}
                                       className={"ActionInput P5"}
                                       value={0}
                                       checked={guideDesc[row.lang_id].status === 0}
                                       onChange={(evt) => updateStatus(row.lang_id, parseInt(evt.target.value))} />
                                <label htmlFor={"guideStatusOffline" + row.lang_id} className={"MR10"}>Offline</label>
                            </div>
                        </div>
                        <hr/>
                    </div>
                })}

                <button id="edit_guide" type="submit" className="btn btn-md btn-success">
                    Validate
                </button>
            </form>
        </div>
    );
}

export default GuideSetup;