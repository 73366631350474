import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../Constant";
import "react-toastify/dist/ReactToastify.css";

function SiteRemovePackSmart(props) {
    const [siteIds, setSiteIds] = useState("");
    const [packId, setPackId] = useState("");

    const handleUpdate = async () => {
        if (!siteIds || !packId) {
            toast.error('Please enter both Site IDs and Pack ID', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const siteIdsArray = siteIds.split(",").map(id => parseInt(id.trim(), 10)).filter(id => !isNaN(id));

        if (siteIdsArray.length === 0) {
            toast.error('Please enter valid Site IDs', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        try {
            const response = await axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_removeSiteFromPack`, {
                site_ids: siteIdsArray,
                pack_id: parseInt(packId, 10)
            });

            if (response.data.status) {
                if (response.data.results === "success") {
                    toast.success('Sites successfully removed from pack', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if(response.data.results === "invalid_site_in_list" || response.data.results === "unvalid_pack_id") {
                    toast.error('Invalid Pack or Site ID', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                
            } else {
                toast.error('Failed to remove sites from pack', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error removing sites from pack:", error);
            toast.error('An error occurred while removing sites from pack', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={"SiteRemovePackSmart GridCol7-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className='MT0 MB5'>Remove site from Pack</h2>
            <div className="FlexCol">
                <div className="FlexCol MB5">
                    <label>Site IDs</label>
                    <input 
                        type="text" 
                        className="ActionInput P5" 
                        name="site_id_pack" 
                        value={siteIds}
                        placeholder="Enter Site IDs separated by commas"
                        onChange={(e) => setSiteIds(e.target.value)}
                    />
                </div>
                <div className="FlexCol MB10">
                    <label>Pack ID</label>
                    <input 
                        type="text" 
                        className="ActionInput P5" 
                        name="pack_id" 
                        value={packId}
                        onChange={(e) => setPackId(e.target.value)}
                    />
                </div>
                <div className="FlexRow">
                    <button className="ActionButton BRed" onClick={handleUpdate}>Remove</button>
                </div>
            </div>
        </div>
    );
}

export default SiteRemovePackSmart;
