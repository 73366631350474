import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from '../../../Constant';
import axios from "axios";

function SitePassback(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [passbackValue, setPassbackValue] = useState();

    useEffect(() => {
        setPassbackValue(props.sitePassback);
    }, [props.sitePassback])

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    function udpatePassback(siteId, passbackValue) {
        setIsLoading(true);
        axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updateSitePassback", { site_id: siteId, passback_value: passbackValue })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Something went wrong, please try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to delete the tag');
                }

                toast.success('Passback updated to ' + passbackValue, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
            setIsLoading(false);
        });;
    }

    return (
        <div className="MB20">
            <label className="FWBold" htmlFor="site_passback">Passback Moneybox</label>
            <div className="FlexRow">
                <input
                    className="ActionInput P10 Width10 MR10"
                    type="text"
                    value={passbackValue}
                    placeholder="Passback"
                    id="site_passback"
                    onChange={(e) => setPassbackValue(e.target.value)}
                />
                <button className="ActionButton BGreen" onClick={() => udpatePassback(props.siteId, passbackValue)}>Save</button>
            </div>
        </div>
    );
}

export default SitePassback;