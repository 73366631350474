import "./SiteTopGeo.css";
import axios from "axios";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from '../SortableItem/SortableItem';
import { useState, useEffect, useCallback } from "react";
import { ROOT_DOMAIN_API } from "../../Constant";
import { toast } from "react-toastify";
import { BarLoader } from "react-spinners";

function SiteTopGeo(props) {
    const [items, setItems] = useState([]);
    const [data, setData] = useState([]);
    const [availableTopGeo, setAvailableTopGeo] = useState([]);
    const [loading, setLoading] = useState(true);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (!props.siteID) {
            return;
        }
        (async () => {
            try {
                const payload = {
                    params: {
                        q: props.siteID,
                    }
                }
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/siteTopGeo", payload);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setData(obj.results);
                    const itemIds = obj.results.map(row => row.top_geo_code_country);
                    setItems(itemIds);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.siteID]);

    const loadAvailableTopGeo = useCallback(() => {
        (async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getAvailableTopGeo");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setAvailableTopGeo(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        loadAvailableTopGeo();
    }, [loadAvailableTopGeo]);

    const addTopGeo = (iso2) => {
        if (items.length < 5) {
            const newItem = availableTopGeo.find(item => item.cac_iso2 === iso2);
            if (newItem) {
                const newId = newItem.cac_iso2;
                if (!items.includes(newId)) {
                    setItems(prevItems => [...prevItems, newId]);
                    setData(prevData => [...prevData, {
                        top_geo_code_country: newItem.cac_iso2,
                        top_geo_site_id: props.siteID,
                        top_geo_percentage: 0,
                        top_geo_rank: items.length + 1,
                    }]);
                }
            }
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const removeTopGeo = (iso2) => {
        setItems(prevItems => prevItems.filter(item => item !== iso2));
        setData(prevData => prevData.filter(item => item.top_geo_code_country !== iso2));
    };

    const handleUpdate = (id, newPercentage) => {
        setData(prevData =>
            prevData.map(item =>
                item.top_geo_code_country === id ? { ...item, top_geo_percentage: newPercentage } : item
            )
        );
    };

    const handleSave = async () => {
        try {
            const payload = items.map((iso2, index) => {
                const item = data.find(item => item.top_geo_code_country === iso2);
                return {
                    top_geo_rank: index + 1,
                    top_geo_site_id: parseInt(item.top_geo_site_id),
                    top_geo_code_country: item.top_geo_code_country,
                    top_geo_percentage: parseFloat(item.top_geo_percentage)
                };
            });
            await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/siteTopGeo", { items: payload });
            toast.success('Top geo successfully updated', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    return (
        <div className={"SiteTopGeo GridCol1-7 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className="MT0">Top geo</h2>
                <div className={`dropdown ${items.length >= 5 ? 'disabled' : ''}`}>
                    <button className={`ActionButton BRock ${items.length >= 5 ? 'disabled' : ''}`} disabled={items.length >= 5}>
                        Add top geo <i className="fa-solid fa-chevron-down TWhite"></i>
                    </button>
                    <div className="dropdown-content BorderRad6">
                        {availableTopGeo.map((row, key) => (
                            <button key={key} onClick={() => addTopGeo(row.cac_iso2)}>{row.cac_name}</button>
                        ))}
                    </div>
                </div>
            </div>

            <p className="TGrey MT0">Drag and drop to reorder the list</p>
            {loading ? (
                <BarLoader height={8}  color="#f41127"/>
            ) : (
                <table className="Width100">
                    <thead>
                        <tr className="BRed TWhite">
                            <th className="P5">Country</th>
                            <th className="P5">Flag</th>
                            <th className="P5">Percentage</th>
                            <th className="P5">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.length > 0 && (
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={items}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {items.map(id => (
                                        <SortableItem key={id} id={id} data={data} onRemove={removeTopGeo} onUpdate={handleUpdate} />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        )}
                    </tbody>
                </table>
            )}
            <div className="TCenter MT10">
                {!loading && <button
                    className='ActionButton BGreen P10'
                    onClick={handleSave}
                >
                    Save
                </button>}
            </div>
        </div>
    );
}

export default SiteTopGeo;
