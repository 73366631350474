import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from "axios";
import * as d3 from 'd3';
import { ROOT_DOMAIN_API } from "../../Constant";
import worldGeoJSON from "../../data/world-geojson.json";
import { BarLoader } from 'react-spinners';

const WorldMapCaCodir = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadTriggered, setLoadTriggered] = useState(false);
    const svgRef = useRef();
    const containerRef = useRef();

    const loadData = function(){
        setLoadTriggered(true);
        const fetchData = async () => {
            setLoading(true);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getCaByCountry`, {
                    params: {
                        date_from: props.sDate,
                        date_to: props.eDate,
                    }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setData(obj.results);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }

    useEffect(() => {
        setLoadTriggered(false);
    }, [props.sDate, props.eDate]);

    const drawMap = useCallback(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();

        const containerWidth = containerRef.current.offsetWidth;
        const width = containerWidth;
        const height = width / 1.6; // Maintain aspect ratio

        svg.attr("width", width).attr("height", height);

        const projection = d3.geoMercator()
            .scale((width - 40) / (2 * Math.PI))
            .translate([width / 2, height / 1.4]);

        const path = d3.geoPath().projection(projection);

        const colorScale = d3.scaleQuantize()
            .domain([0, Math.max(...data.map(d => parseFloat(d.revenue_private)))])
            .range([
                "#fde0dc",
                "#f9bdbb",
                "#f69988",
                "#f36c60",
                "#e53935",
                "#d32f2f",
                "#c62828",
                "#b71c1c",
                "#a31512",
                "#8b0d0e"
            ]);

        const tooltip = d3.select(containerRef.current)
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("visibility", "hidden")
            .style("background-color", "white")
            .style("border", "solid 1px #ccc")
            .style("padding", "5px")
            .style("border-radius", "5px")
            .style("font-size", "12px");

        svg.append("g")
            .selectAll("path")
            .data(worldGeoJSON.features)
            .enter().append("path")
            .attr("d", path)
            .attr("fill", d => {
                const countryData = data.find(c => c.site_country === d.properties.iso_a3_eh);
                return countryData ? colorScale(parseFloat(countryData.revenue_private)) : "#ccc";
            })
            .attr("stroke", "#fff")
            .attr("stroke-width", 0.5)
            .on("mouseover", function(event, d) {
                const countryData = data.find(c => c.site_country === d.properties.iso_a3_eh);
                const revenue = countryData ? parseFloat(countryData.revenue_private).toFixed(2) : "No data";
                tooltip.style("visibility", "visible")
                    .text(`${d.properties.name}: ${revenue} €`);
                d3.select(this).attr("fill", "#f00");
            })
            .on("mousemove", function(event) {
                tooltip.style("top", (event.pageY - 10) + "px")
                    .style("left", (event.pageX + 10) + "px");
            })
            .on("mouseout", function(d) {
                tooltip.style("visibility", "hidden");
                d3.select(this).attr("fill", d => {
                    const countryData = data.find(c => c.site_country === d.properties.iso_a3_eh);
                    return countryData ? colorScale(parseFloat(countryData.revenue_private)) : "#ccc";
                });
            });
    }, [data]);

    useEffect(() => {
        if (!loading && data.length > 0) {
            drawMap();
            window.addEventListener('resize', drawMap);
        }
        return () => window.removeEventListener('resize', drawMap);
    }, [loading, data, drawMap]);

    return (
        <div ref={containerRef} className="WorldMapCaCodir GridCol1-7 ShadowContainer BWhite P15">
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className="M0 MT5 MB5">CA By Country Map</h2>
                {
                    !loadTriggered ? (
                        <button onClick={() => {loadData();}} className="ActionButton BRed">Load data</button>
                    ) : (
                        null
                    )
                }
            </div>
            {loading ? (
                <div className="FlexRow AlignCenter JustifyCenter MT20">
                    <BarLoader width={250} height={8} color="#f41127" />
                </div>
            ) : (
                loadTriggered ? (
                    <svg ref={svgRef} style={{ width: '100%', height: 'auto' }}></svg>
                ) : null
            )}
        </div>
    );
};

export default WorldMapCaCodir;
