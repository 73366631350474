import axios from "axios";
import "./SitePerfChart.css";
import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, BarElement } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from "../../Constant";
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, BarElement);

function SitePerfChart(props) {
    const [graphData, setGraphData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chartType, setChartType] = useState("Bar");

    useEffect(() => {
        if (!props.siteID) {
            return;
        }

        setLoading(true);

        (async () => {
            try {
                let data = {
                    params: {
                        site_id: props.siteID,
                        s_date: props.sDate,
                        e_date: props.eDate
                    }
                }
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/revenuesBySiteDate", data);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    let datasets = [];
                    let labels = [];
                    let perfData = [];

                    obj.results.forEach((row) => {
                        labels.push(row.perf_date);
                        perfData.push(row.perf);
                    })

                    datasets.push({
                        label: "Revenues",
                        lineTension: 0.3,
                        data: perfData,
                        borderColor: "#27ae60",
                        backgroundColor: "#27ae60",
                        yAxisID: 'y',
                    });

                    setGraphData({
                        labels,
                        datasets: datasets
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.sDate, props.eDate, props.siteID]);

    const toggleChartType = () => {
        setChartType(prevType => (prevType === "Bar" ? "Line" : "Bar"));
    };

    return (
        <div className={"SitePerfChart Width50 ShadowContainer BWhite P15 " + props.childClass}>
            <div className="FlexRow JustifyBetween AlignCenter">
                <h2 className="M0 MT5 MB5">Revenues - All Formats</h2>
                <button onClick={toggleChartType} className="ActionButton BRock TWhite HeightFitContent">
                    {chartType === "Bar" ? <i className="fa-solid fa-chart-line"></i> : <i className="fa-solid fa-chart-column"></i>}
                </button>
            </div>
            <div className="InnerSitePerfChart">
                {loading ? (
                    <SplashLoadComponent />
                ) : (
                    graphData && (
                        chartType === "Bar" ? (
                            <Bar
                                data={graphData}
                                height={300}
                                options={{ maintainAspectRatio: false, responsive: true }}
                            />
                        ) : (
                            <Line
                                data={graphData}
                                height={300}
                                options={{ maintainAspectRatio: false, responsive: true }}
                            />
                        )
                    )
                )}
            </div>
        </div>
    );
}

export default SitePerfChart;
