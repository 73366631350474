import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { useEffect, useState, useCallback } from "react";
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
import "./Translations.css";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import TranslationsRow from "../../Components/TranslationsRow/TranslationsRow";
import ReactPaginate from "react-paginate";

function Translations() {
    const pageTab = "translations";

    const [translationsList, setTranslationsList] = useState([]);
    const [query, setQuery] = useState("");
    const [queryDisplay, setQueryDisplay] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const limit = 20;

    const offset = currentPage * limit;

    const loadTranslations = useCallback(async () => {
        try {
            const payload = {
                params: {
                    translation_source_query: query || "",
                    offset: offset,
                    limit: limit,
                }
            };
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/backoffice_fetchTranslations`, payload);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setTranslationsList(obj.results.translations);
                setPageCount(Math.ceil(obj.results.amount / limit));
            }
        } catch (error) {
            console.error(error);
        }
    }, [query, offset]);

    useEffect(() => {
        if (queryDisplay.length >= 3) {
            setQuery(queryDisplay);
            setCurrentPage(0);
        } else if (queryDisplay.length === 0) {
            setQuery("");
            setCurrentPage(0);
        }
    }, [queryDisplay]);

    useEffect(() => {
        loadTranslations();
    }, [query, currentPage, loadTranslations]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected);
    }

    return (
        <div className="GridTemplate12">
            <AuthComponent />
            <HeadLine childClass="Headline GridCol1-13" title="Translations" />

            <LateralNavComponent pageTab={pageTab} />
            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="Translation GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder ">
                <input
                    onChange={(e) => { setQueryDisplay(e.target.value); setCurrentPage(0); }}
                    type="text"
                    className="ActionInput P10 Width35 MB10"
                    placeholder="Search for a translation..."
                />
                <table className="Width100">
                    <thead>
                        <tr className="BRed TWhite">
                            <th className="P5">#</th>
                            <th className="P5">Source</th>
                            <th className="P5">Lang</th>
                            <th className="P5">Translation</th>
                            <th className="P5">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {translationsList.map((row, key) => (
                            <TranslationsRow
                                key={key}
                                translation_id={row.translation_id}
                                lang={row.lang_iso2}
                                source={row.translation_text}
                                translation={row.translation_text_translate}
                            />
                        ))}
                    </tbody>
                </table>
                <ReactPaginate
                    className='Pagination'
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
    )
}

export default Translations;
