import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, PieController } from 'chart.js';
import { ROOT_DOMAIN_API } from "../../Constant";
import { BarLoader } from "react-spinners";

ChartJS.register(ArcElement, Tooltip, Legend, PieController);

function PieChartCountryCodir({ sDate, eDate }) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadTriggered, setLoadTriggered] = useState(false);

    const loadData = function(){
        setLoadTriggered(true);
        const fetchData = async () => {
            setLoading(true);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getRevenuesPartner`, {
                    params: {
                        date_from: sDate,
                        date_to: eDate,
                    }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    const labels = obj.results.map(row => row.partner_name_display);
                    const data = obj.results.map(row => parseFloat(row.revenues));

                    const totalRevenue = data.reduce((acc, value) => acc + value, 0);

                    const chartData = {
                        labels: labels,
                        datasets: [{
                            data: data,
                            backgroundColor: [
                                "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF",
                                "#FF9F40", "#FFCD56", "#8A89A6", "#98FB98", "#FFD700",
                                "#7B68EE", "#DC143C", "#7FFFD4", "#FF69B4", "#FFDAB9",
                                "#48D1CC", "#DDA0DD", "#F0E68C", "#FF4500", "#ADFF2F",
                                "#6495ED", "#8B0000", "#2E8B57", "#A52A2A", "#5F9EA0",
                                "#DEB887", "#7FFF00", "#D2691E", "#FF7F50", "#6495ED",
                                "#DC143C", "#008B8B", "#B8860B", "#006400", "#BDB76B"
                            ],
                            hoverBackgroundColor: [
                                "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF",
                                "#FF9F40", "#FFCD56", "#8A89A6", "#98FB98", "#FFD700",
                                "#7B68EE", "#DC143C", "#7FFFD4", "#FF69B4", "#FFDAB9",
                                "#48D1CC", "#DDA0DD", "#F0E68C", "#FF4500", "#ADFF2F",
                                "#6495ED", "#8B0000", "#2E8B57", "#A52A2A", "#5F9EA0",
                                "#DEB887", "#7FFF00", "#D2691E", "#FF7F50", "#6495ED",
                                "#DC143C", "#008B8B", "#B8860B", "#006400", "#BDB76B"
                            ]
                        }]
                    };

                    if (chartInstanceRef.current) {
                        chartInstanceRef.current.destroy();
                    }

                    const interval = setInterval(() => {
                        if (chartRef.current) {
                            try {
                                chartInstanceRef.current = new ChartJS(chartRef.current, {
                                    type: 'pie',
                                    data: chartData,
                                    options: {
                                        maintainAspectRatio: true,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                labels: {
                                                    filter: (legendItem, data) => legendItem.index < 20,
                                                },
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: function (tooltipItem) {
                                                        const value = data[tooltipItem.dataIndex];
                                                        const percentage = ((value / totalRevenue) * 100).toFixed(2);
                                                        return `${percentage}% (${value.toLocaleString()} EUR)`;
                                                    }
                                                }
                                            },
                                        },
                                    },
                                });
                                clearInterval(interval);
                            } catch (chartError) {
                                console.error('Error creating chart:', chartError);
                                clearInterval(interval);
                            }
                        }
                    }, 100);
                } else {
                    console.error('API response status is false or results are empty');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }

    useEffect(() => {
        setLoadTriggered(false);
    }, [sDate, eDate]);

    return (
        <div className="PieChartCountryCodir GridCol1-7 ShadowContainer BWhite P15">
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className="M0 MT5 MB5">CA By Partner</h2>
                {
                    !loadTriggered ? (
                        <button onClick={() => {loadData();}} className="ActionButton BRed">Load data</button>
                    ) : (
                        null
                    )
                }
            </div>
            {loading ? (
                <BarLoader height={8} color="#f41127" />
            ) : (
                loadTriggered ? (
                <div style={{ position: 'relative', height: '50vh', display: 'flex', justifyContent: 'center' }}>
                    <canvas ref={chartRef}></canvas>
                </div>) : null
            )}
        </div>
    );
}

export default PieChartCountryCodir;
