import './SplashLoadComponent.css'

function SplashLoadComponent(props) {
  return (
    <div className={"SplashLoad " + props.childClass}>
        <div className="box"></div>
    </div>
  );
}

export default SplashLoadComponent;
