import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./NotificationSetup.css";
import { useNavigate } from "react-router-dom";

function NotificationSetup(props) {
    const [lang, setLang] = useState([]);
    const [langSelected, setLangSelected] = useState("");
    const [notificationMessageIdSelected, setNotificationMessageIdSelected] = useState("");
    const [notification, setNotification] = useState([]);
    const [notificationSousType, setNotificationSousType] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getLang");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getNotificationSousType?lang_id="+langSelected);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setNotificationSousType(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [langSelected]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getNotificationMessage?message_id="+notificationMessageIdSelected);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setNotificationMessage(obj.results);
                    updateNotificationMessageModified(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationMessageIdSelected]);

    const updateNotificationType = function (content){
        let a = {...notification};
        a.notification_type = content;

        if(content === "site"){
            document.getElementById("notificationUserId").disabled = false;
            document.getElementById("notificationUserId").value = "";
            document.getElementById("all_user_plateform").checked = false;

            a.notification_user_id = "";
        }else{
            document.getElementById("notificationSiteId").disabled = false;
            document.getElementById("notificationSiteId").value = "";
            document.getElementById("all_site_plateform").checked = false;

            a.notification_site_id = "";
        }

        setNotification(a);
    }

    const updateNotificationSiteId = function (content){
        let a = {...notification};
        a.notification_site_id = content;
        setNotification(a);
    }

    const updateNotificationUserId = function (content){
        let a = {...notification};
        a.notification_user_id = content;
        setNotification(a);
    }

    const updateNotificationLang = function (content){
        let a = {...notification};
        a.notification_lang_id = content;
        setNotification(a);

        setLangSelected(content);
    }

    const updateNotificationMessageId = function (content){
        let a = {...notification};
        a.notification_message_id = content;
        setNotification(a);

        setNotificationMessageIdSelected(content);
    }

    const updateNotificationMessageModified = function (content){
        let a = {...notification};
        a.notification_message_modified = content;
        setNotification(a);
        setNotificationMessage(content);
    }

    const checkAllSitePlaform = function (content){
        if(content){
            document.getElementById("notificationSiteId").disabled = true;
            document.getElementById("notificationSiteId").value = "";
        }else{
            document.getElementById("notificationSiteId").disabled = false;
        }

        updateNotificationSiteId("");
    }

    const checkAllUserPlaform = function (content){
        if(content){
            document.getElementById("notificationUserId").disabled = true;
            document.getElementById("notificationUserId").value = "";
        }else{
            document.getElementById("notificationUserId").disabled = false;
        }

        updateNotificationUserId("");
    }

    const insertNotification = async (event) => {
        event.preventDefault();

        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/bo_moderator/bo_insertNotification", {
                'notification': notification,
            });
            const obj = await resp.data;

            if (obj.status) {
                navigate("/notificationList");
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        console.log(notificationMessageIdSelected);
    }, [notificationMessageIdSelected]);

    useEffect(() => {
        console.log(notification);
    }, [notification]);

    return (
        <div className={"NotificationSetup GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <form onSubmit={insertNotification}>
                <div className={"MB10"}>
                    <span className={"Underline"}>Type de notification :</span>
                    <input type="radio" name={"notificationType"} id={"notificationTypeSite"}
                           className={"ActionInput P5"}
                           value={"site"}
                           required
                           onChange={(evt) => updateNotificationType(evt.target.value)}/>
                    <label htmlFor={"notificationTypeSite"} className={"MR10"}>Site</label>
                    <input type="radio" name={"notificationType"} id={"notificationTypeUser"}
                           className={"ActionInput P5"}
                           value={"user"}
                           onChange={(evt) => updateNotificationType(evt.target.value)}/>
                    <label htmlFor={"notificationTypeUser"} className={"MR10"}>User</label>
                </div>

                <div className={"MB10 " + (notification.notification_type !== "site" ? "Hide" : "")}>
                    <div>
                        <span className={"Underline"}>Site ID :</span>
                        <input type="text" name={"notificationSiteId"} id={"notificationSiteId"}
                               className={"ActionInput P5"}
                               placeholder={"Ex: 108,378,2374"}
                               onChange={(evt) => updateNotificationSiteId(evt.target.value)}/>
                    </div>
                    <div>
                        <span className={"Underline"}></span>
                        <input type="checkbox" id={"all_site_plateform"}
                               onChange={(evt) => checkAllSitePlaform(evt.target.checked)}/>
                        <label for={"all_site_plateform"}>Send to all sites from the selected language</label>
                    </div>
                </div>

                <div className={"MB10 " + (notification.notification_type !== "user" ? "Hide" : "")}>
                    <div>
                        <span className={"Underline"}>User ID :</span>
                        <input type="text" name={"notificationUserId"} id={"notificationUserId"}
                               className={"ActionInput P5"}
                               placeholder={"Ex: 108,378,2374"}
                               onChange={(evt) => updateNotificationUserId(evt.target.value)}/>
                    </div>
                    <div>
                        <span className={"Underline"}></span>
                        <input type="checkbox" id={"all_user_plateform"}
                               onChange={(evt) => checkAllUserPlaform(evt.target.checked)}/>
                        <label htmlFor={"all_user_plateform"}>Send to all users from the selected language</label>
                    </div>
                </div>

                <div className={"MB10"}>
                    <span className={"Underline"}>Langue :</span>
                    <select className={"ActionInput P5"} name="notification_lang_id" id="notification_lang_id"
                            required onChange={(evt) => updateNotificationLang(evt.target.value)}>
                        <option disabled selected value={""}>Choisir une langue</option>
                        {lang.map((row, key) => {
                            return <option value={row.lang_id}>{row.lang_name_header}</option>
                        })}
                    </select>
                </div>

                <div className={"MB10" + (!notification.notification_lang_id || notification.notification_lang_id === "" ? " Hide" : "")}>
                    <span className={"Underline"}>Type de message :</span>
                    <select className={"ActionInput P5"} name="notification_message_id" id="notification_message_id"
                            required onChange={(evt) => updateNotificationMessageId(evt.target.value)}>
                        <option disabled selected value={""}>Choisir un type de message</option>
                        {notificationSousType.map((row, key) => {
                            return <option
                                value={row.notification_message_id}
                                disabled={row.notification_moneybox > 0 ? false : true}
                            >
                                [{row.notification_type}] {row.notification_sous_type}
                            </option>
                        })}
                    </select>
                </div>

                <div className={"MB10" + (!notification.notification_message_id || notification.notification_message_id === "" ? " Hide" : "")}>
                    <span className={"Underline"}>Message :</span>
                    <textarea className={"ActionInput P5"}
                              id={"notification_message_area"}
                              name={"notification_message_area"}
                              value={notificationMessage}
                              rows="10"
                              onChange={(evt) => updateNotificationMessageModified(evt.target.value)}></textarea>
                </div>

                <button id="edit_notification" type="submit" className="btn btn-md btn-success">
                    Send Notification
                </button>
            </form>
        </div>
    );
}

export default NotificationSetup;