import './SiteFormatsRow.css';
import 'react-tooltip/dist/react-tooltip.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ROOT_DOMAIN_API } from "../../../Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from 'react-tooltip';

function SiteFormatsRow(props) {
    const [formCustomDisplay, setFormCustomDisplay] = useState(props.formCustomDisplay);
    const [formCustomPlacement, setFormCustomPlacement] = useState(props.formCustomPlacement);
    const [intextLazyLoaded, setIntextLazyLoaded] = useState(props.siteIntextLazyLoaded);
    const [formRefreshVisible, setFormRefreshVisible] = useState(props.formRefreshVisible || null);
    const [formRefreshInvisible, setFormRefreshInvisible] = useState(props.formRefreshInvisible || null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [isLoading]);

    const handleSave = () => {
        setIsLoading(true);

        const refreshVisible = formRefreshVisible === '' ? null : formRefreshVisible;
        const refreshInvisible = formRefreshInvisible === '' ? null : formRefreshInvisible;

        axios.post(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_updateFormat`, {
            form_id: props.formId,
            form_custom_display: formCustomDisplay,
            form_custom_placement: formCustomPlacement,
            form_refresh_visible: refreshVisible,
            form_refresh_invisible: refreshInvisible,
            form_intext_lazy_loaded: intextLazyLoaded
        })
            .then(response => {
                if (!response.data.status) {
                    toast.error('Failed to update format', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    throw new Error('Failed to update format');
                }

                toast.success('Format successfully updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                console.error(error);
                toast.error('Failed to update format', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <tr className={"SiteFormatsRow"}>
            <td className='P15'>
                {props.formId}
            </td>
            <td className='P15'>
                <div className='FlexRow Gap5 AlignCenter' >
                    <div>{props.formName}</div>
                    {props.formState !== 2 &&
                        <div className='TWhite BRed P5 SmallFont BorderRad3'>Refused</div>
                    }
                </div>
            </td>
            <td className='P15'>
                {props.formDate}
            </td>
            <td className='FlexCol P10 AlignCenter JustifyCenter'>
                {props.formName === "FOOTER ou SLIDE-IN" && (
                    <div className='FlexRow'>
                        <label htmlFor='site_form_custom_display' className='LightText'>No Slide-In</label>
                        <input
                            id="site_form_custom_display"
                            type='checkbox'
                            className='P10'
                            value=""
                            checked={formCustomDisplay === 1}
                            onChange={() => setFormCustomDisplay(formCustomDisplay === 1 ? 0 : 1)}
                        />
                    </div>
                )}
                {props.formName === "IN TEXT" && (
                    <div className='FlexCol AlignCenter'>
                        <label htmlFor='site_form_custom_placement' className='LightText'>Custom placement</label>
                        <input
                            id="site_form_custom_placement"
                            type='text'
                            className='ActionInput P5'
                            value={formCustomPlacement}
                            onChange={(e) => setFormCustomPlacement(e.target.value)}
                        />
                        <div className='FlexRow MT10'>
                            <label htmlFor='site_intext_lazyloaded' className='LightText'>Lazy Loading Intext</label>
                            <input
                                id="site_intext_lazyloaded"
                                type='checkbox'
                                className='ActionInput P5'
                                checked={intextLazyLoaded !== 0 && (!formCustomPlacement || formCustomPlacement.length === 0)}
                                value=""
                                onChange={(e) => setIntextLazyLoaded(e.target.checked ? 1 : 0)}
                            />
                        </div>
                    </div>
                )}
            </td>
            <td className='P15 TCenter'>
                <input
                    className='ActionInput P10'
                    type="number"
                    value={formRefreshVisible}
                    onChange={(e) => setFormRefreshVisible(e.target.value)}
                />
            </td>
            <td className='P15 TCenter'>
                <input
                    className='ActionInput P10'
                    type="number"
                    value={formRefreshInvisible}
                    onChange={(e) => setFormRefreshInvisible(e.target.value)}
                />
            </td>
            <td className='P15 TCenter'>
                {props.formState === 2 && (
                    <button
                        className='ActionButton BRock TWhite MR5'
                        onClick={props.onRefuseFormat}
                        data-tooltip-id="refuse_format"
                        data-tooltip-content="Refuse Format"
                    >
                        <Tooltip id="refuse_format" />
                        <i class="fa-solid fa-ban TWhite"></i>
                    </button>
                )}
                <button
                    className='ActionButton BGreen TWhite P10'
                    data-tip="Save"
                    onClick={handleSave}
                >
                    Save
                </button>
            </td>
        </tr>
    );
}

export default SiteFormatsRow;
