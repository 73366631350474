import {useState, useEffect, useRef} from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./FaqList.css";
import {Link} from "react-router-dom";

function FaqList(props) {
    const [faqList, setFaqList] = useState([]);
    const [lang, setLang] = useState([]);
    const [langSelected, setLangSelected] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_faqList?lang_id="+langSelected);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setFaqList(obj.results.faqList);
                    setLang(obj.results.lang);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [langSelected]);

    const updateFaqCategoryDescriptionStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateFaqCategoryDescriptionStatus',
                {id: id, status: status, lang_id: langSelected});
            const obj = await resp.data;
            if (obj.status) {
                setFaqList(obj.results);
            }
        })();
    }

    const updateFaqDescriptionStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateFaqDescriptionStatus',
                {id: id, status: status, lang_id: langSelected});
            const obj = await resp.data;
            if (obj.status) {
                setFaqList(obj.results);
            }
        })();
    }

    const dragFaqList = useRef(0);
    const draggedOverFaqList = useRef(0);

    function handleSort(key, key2){
        if(dragFaqList.current === draggedOverFaqList.current){
            return false;
        }

        const faqListClone = [...faqList];

        console.log(key);
        console.log(key2);
        console.log(dragFaqList.current);
        console.log(draggedOverFaqList.current);

        if(dragFaqList.current < draggedOverFaqList.current){
            const newCatFaqList = [];
            const newFaqList = [];
            faqList.forEach((faq, i) => {
                if(i !== key){
                    newCatFaqList.push(faq);
                }else{
                    newCatFaqList.push(faq);

                    faq['faq'].forEach((faq2, ii) => {
                        if(ii < dragFaqList.current){
                            faq2.faq_rang = ii;
                            newFaqList.push(faq2);
                        }else if(ii > dragFaqList.current && ii < draggedOverFaqList.current){
                            faq2.faq_rang = ii;
                            newFaqList.push(faq2);
                        }else if(ii === draggedOverFaqList.current){
                            faq2.faq_rang = ii;
                            newFaqList.push(faq2);
                            faqListClone[key]['faq'][dragFaqList.current].faq_rang = ii+1;
                            newFaqList.push(faqListClone[key]['faq'][dragFaqList.current]);
                        }else if(ii > draggedOverFaqList.current){
                            faq2.faq_rang = ii+1;
                            newFaqList.push(faq2);
                        }
                    });

                    newCatFaqList[key]['faq'] = newFaqList;
                }
            });

            setFaqList(newCatFaqList);
        }else{
            const newCatFaqList = [];
            const newFaqList = [];
            faqList.forEach((faq, i) => {
                if(i !== key){
                    newCatFaqList.push(faq);
                }else{
                    newCatFaqList.push(faq);

                    faq['faq'].forEach((faq2, ii) => {
                        if(ii < draggedOverFaqList.current){
                            faq2.faq_rang = ii;
                            newFaqList.push(faq2);
                        }else if(ii === draggedOverFaqList.current){
                            faqListClone[key]['faq'][dragFaqList.current].faq_rang = ii;
                            newFaqList.push(faqListClone[key]['faq'][dragFaqList.current]);
                            faq2.faq_rang = ii+1;
                            newFaqList.push(faq2);
                        }else if(ii > draggedOverFaqList.current && ii !== dragFaqList.current){
                            faq2.faq_rang = ii+1;
                            newFaqList.push(faq2);
                        }
                    });

                    newCatFaqList[key]['faq'] = newFaqList;
                }
            });

            setFaqList(newCatFaqList);
        }
    }

    return (
        <div className={"FaqList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="TRight MB20">
                <select className="ActionInput P5"
                        name="lang"
                        onChange={(e) => setLangSelected(e.target.value)}
                >
                    {lang.map((item) => (
                        <option value={item.lang_id} selected={item.lang_id === 1}>
                            {item.lang_name}
                        </option>
                    ))}
                </select>
                <Link to={"/faqCategorySetup/0"} className="BGreen TWhite ActionButton ML5 NoUnderline">
                    + Add Faq Category
                </Link>
                <Link to={"/faqSetup/0"} className="BGreen TWhite ActionButton ML5 NoUnderline">
                    + Add Faq
                </Link>
            </div>
            {faqList.map((row, key) => {
                return <div className={"MB30"}>
                    <div className={"MB10"}>
                        <i className="fa-solid fa-arrows-up-down-left-right MR10" style={{fontSize: '20px'}}></i>
                        <Link to={"/faqCategorySetup/" + row.categorie_id} className={"MR10"}>
                            {row.categorie_libelle}
                        </Link>
                        <label className="switch">
                            <input type="checkbox"
                                   onClick={() => updateFaqCategoryDescriptionStatus(row.categorie_description_id, row.categorie_status === 1 ? 0 : 1)}
                                   checked={row.categorie_status === 1}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {row.faq.map((row2, key2) => {
                        return <div className={"ML30 MB10"}
                                    draggable
                                    onDragStart={() => (dragFaqList.current = key2)}
                                    onDragEnter={() => (draggedOverFaqList.current = key2)}
                                    onDragEnd={() => handleSort(key, key2)}
                                    onDragOver={(e) => e.preventDefault()}>
                            <i className="fa-solid fa-arrows-up-down-left-right MR10" style={{fontSize: '20px'}}></i>
                            <Link to={"/faqSetup/" + row2.faq_id} className={"MR10"}>
                                {row2.faq_title}
                            </Link>
                            <label className="switch">
                                <input type="checkbox"
                                       onClick={() => updateFaqDescriptionStatus(row2.faq_description_id, row2.faq_status === 1 ? 0 : 1)}
                                       checked={row2.faq_status === 1}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    })}
                </div>
            })}
        </div>
    );
}

export default FaqList;