import { useState, useEffect } from "react";

function InformationsFormats(props) {
    const [informationsList, setInformationsList] = useState([]);

    useEffect(() => {
        setInformationsList(props.informationsList);
    }, [props.informationsList]);

    return (
        <div className={"InformationsFormats GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Ad ID</th>
                        <th className='P5'>Smart ID</th>
                        <th className='P5'>Name</th>
                        <th className='P5'>Description</th>
                        <th className='P5'>URL Image</th>
                        <th className="P5">Type</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        informationsList
                            .map((row, key) => {
                                return (
                                    <tr key={key}>
                                        <td className='P15'>{row.ad_id}</td>
                                        <td className='P15'>{row.ad_smart_id}</td>
                                        <td className='P15'>{row.ad_name}</td>
                                        <td className='P15'>{row.ad_text}</td>
                                        <td className='P15'>{row.ad_img_v2}</td>
                                        <td className="P15">
                                            {row.ad_type_id === 1 && 'classic'}
                                            {row.ad_type_id === 2 && 'impactful'}
                                            {row.ad_type_id === 3 && 'videos'}
                                            {row.ad_type_id === 4 && 'mobile'}
                                        </td>

                                    </tr>
                                );
                            })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default InformationsFormats;
