import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { BarLoader } from 'react-spinners';

function TopSiteCodir(props) {
    const [topPerf, setTopPerf] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadTriggered, setLoadTriggered] = useState(false);

    const loadData = function(){
        setLoadTriggered(true);
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getCAAllPlateformCodir`, {
                    params: {
                        date_from: props.sDate,
                        date_to: props.eDate
                    }
                });
                const data = response.data;
                if (data.status && data.results) {
                    setTopPerf(data.results);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data: ", error);
                setLoading(false);
            }
        };
        fetchData();
    }

    useEffect(() => {
        setLoadTriggered(false);
    }, [props.sDate, props.eDate]);

    return (
        <div className={"TopSiteByPlateformCodir ShadowContainer P15 BWhite " + props.childClass}>
            <div className='FlexRow JustifyBetween AlignBaseline'>
                <h2 className="M0 MT5 MB5">Top sites revenues All</h2>
                {
                    !loadTriggered ? (
                        <button onClick={() => {loadData();}} className="ActionButton BRed">Load data</button>
                    ) : (
                        null
                    )
                }
            </div>
            <div className='TopSiteByPlateformCodirContainer'>
                {
                        loadTriggered ? (
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Site URL</th>
                        <th className='P5'>Revenues</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="2">
                                <div className='FlexRow AlignCenter MT20 MB20 JustifyCenter Width100'>
                                    <BarLoader height={8} width={250} color="#f41127" />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        topPerf.map((row, key) => (
                            <tr key={key}>
                                <td className='P5'>{row.site_url}</td>
                                <td className='P5'>{row.revenue_private} €</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            ) : null}
            </div>
        </div>
    );
}

export default TopSiteCodir;
