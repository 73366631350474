import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import HeadLine from "../../Components/HeadLine/HeadLine";
import React, { useState, useEffect } from "react";
import PassbackWaiting from "../../Components/PassbackWaiting/PassbackWaiting";
import PassbackList from "../../Components/PassbackList/PassbackList";
import { ToastContainer } from "react-toastify";
import AuthComponent from "../../Components/AuthComponent/AuthComponent";
import "./Passback.css";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";

function Passback() {
    const pageTab = "passback";
    const [sButton, setSButton] = useState(1);
    const [passbacks, setPassbackList] = useState([]);

    const fetchPassbacks = async () => {
        try {
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getPassbackList`);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setPassbackList(obj.results);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchPassbacks();
    }, []);

    return (
        <div className="GridTemplate12">
            <AuthComponent />

            <ToastContainer
                theme="colored"
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HeadLine childClass="Headline GridCol1-13" title="Informations" />
            <LateralNavComponent pageTab={pageTab} />
            <div className="ShadowContainer BWhite GridCol1-13 P25">
                <div className="FlexRow Gap5">
                    <button onClick={() => setSButton(1)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 1 ? "SelectedButton" : "")}>
                        Waiting
                    </button>
                    <button onClick={() => setSButton(2)} className={"MediumFont BRed P10 LightButton TWhite Width100 " + (sButton === 2 ? "SelectedButton" : "")}>
                        Validated
                    </button>
                </div>
            </div>
            <PassbackWaiting childClass={sButton === 1 ? '' : 'Hide'} onPassbackAccepted={fetchPassbacks} />
            <PassbackList childClass={sButton === 2 ? '' : 'Hide'} passbacks={passbacks} />
        </div>
    );
}

export default Passback;
