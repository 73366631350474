import { useState } from "react";
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
import { toast } from 'react-toastify';

function SiteRollBackManual(props) {
    const [siteID, setSiteID] = useState("");

    const handleUpdate = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_user/bo_rollbackSiteMagicTag', {
                site_id: siteID
            });
            const obj = await resp.data;
            if (obj.status) {
                toast.success('Processed');
            }
        } catch (error) {
            toast.error('Error processing request');
            console.error(error);
        }
    }

    return (
        <div className={"UserPaypal GridCol7-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <h2 className='MT0 MB5'>Rollback site magic tag</h2>
            <div className="FlexCol">
                <div className="FlexCol MB5">
                    <label>Site ID</label>
                    <input
                        type="text"
                        className="ActionInput P5"
                        placeholder="Enter only one site ID"
                        name="user_id"
                        value={siteID}
                        onChange={(e) => setSiteID(e.target.value)}
                    />
                </div>
                <div className="FlexRow">
                    <button className="ActionButton BGreen" onClick={handleUpdate}>Rollback site to manual tags</button>
                </div>
            </div>
        </div>
    );
}

export default SiteRollBackManual;
