import { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { ROOT_DOMAIN_API } from "../../../Constant";

function PassbackListRow({ row, onUpdate, onDelete }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [bidText, setBidText] = useState(row.bid_text);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const toggleConfirmPopup = () => {
        setIsConfirmOpen(!isConfirmOpen);
    };

    const handleSave = async () => {
        try {
            const response = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_updatePassback", {
                bid_id: row.bid_id,
                bid_text: bidText,
                adm_id: localStorage.getItem('adm_id')
            });
            if (response.data.status) {
                onUpdate(row.bid_id, bidText);
                togglePopup();
                toast.success('Passback successfully updated', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_deletePassback", {
                bid_id: row.bid_id,
            });
            if (response.data.status) {
                onDelete(row.bid_id);
                toggleConfirmPopup();
                toast.success('Passback successfully deleted', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error('Something went wrong, please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    return (
        <tr>
            <td className='P15'>{row.bid_site_id}</td>
            <td className='P15'>{row.site_url}</td>
            <td className='P15'>{row.ad_name}</td>
            <td className='P15 SmallFont OverflowAuto OverflowWrapBreakWord WhiteSpacePreWrap MaxWidthPx600 FontCode FWBold'>{row.bid_text}</td>
            <td className='P15 TCenter'>
                <button className='ActionButton BOrange TWhite MR10' onClick={togglePopup}>
                    <i className="fa-solid fa-pencil"></i>
                </button>
                <button className='ActionButton BRed TWhite MR10' onClick={toggleConfirmPopup}>
                    <i className="fa-solid fa-trash"></i>
                </button>
            </td>
            {isPopupOpen && (
                <div className='overlay'>
                    <div className='popup'>
                        <textarea
                            className='FullWidth Height300px BoxSizeBorder P10 BorderRad10'
                            value={bidText}
                            onChange={(e) => setBidText(e.target.value)}
                        />
                        <button className='ActionButton BGreen TWhite MR10' onClick={handleSave}>
                            Save
                        </button>
                        <button className='ActionButton BBlack TWhite' onClick={togglePopup}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            {isConfirmOpen && (
                <div className='overlay'>
                    <div className='popup'>
                        <p>Are you sure you want to delete this passback?</p>
                        <button className='ActionButton BRed TWhite MR10' onClick={handleDelete}>
                            Delete
                        </button>
                        <button className='ActionButton BBlack TWhite' onClick={toggleConfirmPopup}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </tr>
    );
}

export default PassbackListRow;
