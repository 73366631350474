import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import { toast } from 'react-toastify';

function MagicTagSetup({ siteID, refreshFlag, childClass }) {
    const [magicTagSetupList, setMagicTagSetupList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [activeTag, setActiveTag] = useState(null);
    const [formData, setFormData] = useState({});

    const adm_id = localStorage.getItem('adm_id'); 

    useEffect(() => {
        const fetchMagicTags = async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getMagicTagBySiteId", {
                    params: { site_id: siteID }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setMagicTagSetupList(obj.results);
                }
            } catch (error) {
                console.error(error);
                toast.error('Failed to fetch magic tags', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        const fetchTags = async () => {
            try {
                const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getTags", {
                    params: { q: siteID }
                });
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setTagList(obj.results);
                }
            } catch (error) {
                console.error(error);
                toast.error('Failed to fetch tags', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        fetchMagicTags();
        fetchTags();
    }, [siteID, refreshFlag]);

    const getMergedTags = () => {
        const tagMap = new Map();

        tagList.forEach(tag => {
            tagMap.set(tag.tag_id, {
                tag_id: tag.tag_id,
                tag_name: tag.tag_name,
                tag_date: tag.tag_date,
                mt_id: null,
                mt_site_id: siteID,
                mt_tag_id: tag.tag_id,
                mt_ad_id: null,
                mt_class_id: '',
                mt_css: '',
                mt_status: 0,
                mt_desktop: 0,
                mt_last_update: null,
                mt_appendafter: 0,
            });
        });

        magicTagSetupList.forEach(tag => {
            tagMap.set(tag.mt_tag_id, {
                ...tagMap.get(tag.mt_tag_id),
                mt_id: tag.mt_id,
                mt_site_id: tag.mt_site_id,
                mt_tag_id: tag.mt_tag_id,
                mt_ad_id: tag.mt_ad_id,
                mt_class_id: tag.mt_class_id,
                mt_css: tag.mt_css,
                mt_status: tag.mt_status,
                mt_desktop: tag.mt_desktop,
                mt_last_update: tag.mt_last_update,
                mt_appendafter: tag.mt_appendafter,
            });
        });

        return Array.from(tagMap.values());
    };

    const handleTagClick = (tag_id) => {
        setActiveTag(activeTag === tag_id ? null : tag_id);
        const tag = mergedTags.find(t => t.mt_id === tag_id || t.tag_id === tag_id);
        setFormData({ ...tag });
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value
        }));
    };

    const handleSave = async () => {
        try {
            const dataToSave = { ...formData, site_id: siteID, adm_id };
            if (dataToSave.mt_id) {
                await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_updateMagicTag", dataToSave);
            } else {
                await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_createMagicTag", dataToSave);
            }
            toast.success('Magic tag saved successfully', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // Refresh data
            const fetchMagicTags = async () => {
                try {
                    const resp = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getMagicTagBySiteId", {
                        params: { site_id: siteID }
                    });
                    const obj = await resp.data;
                    if (obj.status && obj.results) {
                        setMagicTagSetupList(obj.results);
                    }
                } catch (error) {
                    console.error(error);
                    toast.error('Failed to refresh magic tags', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            };
            fetchMagicTags();
        } catch (error) {
            console.error("Failed to save magic tag", error);
            toast.error('Failed to save magic tag', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const mergedTags = getMergedTags().filter(tag => tag.tag_name !== "MAGIC TAG");

    const specialTags = [
        "FOOTER ou SLIDE-IN",
        "IN TEXT",
        "INTERSTITIAL",
        "CORNER VIDEO",
        "POP",
        "FOOTER LITE",
        "SLIDE-IN LITE",
        "INTERSTITIEL"
    ];

    return (
        <div className={"MagicTagSetup GridCol1-7 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + childClass}>
            <h2 className='MT0'>Magic Tag Setup</h2>
            {mergedTags.length === 0 ? (
                <h5 className="TCenter TGrey">No tags to display</h5>) : ""
            }
            {mergedTags.map((tag) => (
                <div key={tag.mt_id ? tag.mt_id : tag.tag_id} className="BorderRad3 ShadowContainer P15 BorderGrey MB10 ">
                    <div className="FlexRow JustifyBetween CursorPointer" onClick={() => handleTagClick(tag.mt_id ? tag.mt_id : tag.tag_id)}>
                        <div className="FlexRow AlignCenter">
                            {tag.mt_status === 1 ? <i className="fa-solid fa-check-circle TGreen"></i> : <i className="fa-solid fa-power-off TRed"></i>}
                            <span className="ML5 FWBold">{tag.tag_name}</span>
                        </div>
                        <div className="FlexRow AlignCenter">
                            <span className="SmallFont TGrey MR10">{tag.mt_last_update ? "Last Update: " + new Date(tag.mt_last_update).toLocaleString() : ""}</span>
                            <div>{activeTag === (tag.mt_id ? tag.mt_id : tag.tag_id) ? <i className="fa-solid fa-chevron-up TGrey"></i> : <i className="fa-solid fa-chevron-down TGrey"></i>}</div>
                        </div>
                    </div>
                    {activeTag === (tag.mt_id ? tag.mt_id : tag.tag_id) && (
                        <div className="FlexCol MT20">
                            <label className="MB5">
                                <input type="checkbox" name="mt_status" checked={formData.mt_status === 1} onChange={handleInputChange} /> Online
                            </label>
                            {!specialTags.includes(tag.tag_name) && (
                                <>
                                    <label className="MB5">
                                        <input type="checkbox" name="mt_desktop" checked={formData.mt_desktop === 1} onChange={handleInputChange} /> Desktop only
                                    </label>
                                    <label className="MB5">
                                        <input type="checkbox" name="mt_appendafter" checked={formData.mt_appendafter === 1} onChange={handleInputChange} /> AppendChild
                                    </label>
                                    <div className="FlexCol MB5">
                                        <label>Class/ID</label>
                                        <input type="text" className="ActionInput P5" name="mt_class_id" value={formData.mt_class_id} onChange={handleInputChange} />
                                    </div>
                                    <div className="FlexCol MB10">
                                        <label>Additional CSS</label>
                                        <textarea className="ActionInput P5" name="mt_css" value={formData.mt_css} rows={5} onChange={handleInputChange}></textarea>
                                    </div>
                                </>
                            )}
                            <div className="FlexRow">
                                <button className="ActionButton BGreen" onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default MagicTagSetup;
